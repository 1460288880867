import { render, staticRenderFns } from "./Seal.vue?vue&type=template&id=0a5995ca&scoped=true&"
import script from "./Seal.vue?vue&type=script&lang=js&"
export * from "./Seal.vue?vue&type=script&lang=js&"
import style0 from "./Seal.vue?vue&type=style&index=0&id=0a5995ca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a5995ca",
  null
  
)

export default component.exports