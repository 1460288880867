<template>
  <div class="py-5">
    <b-container>
      <b-row>
        <b-col cols="12" md="12" xl="12" class="my-auto">
          <b-row>
            <b-col cols="12" xl="6" class="text-center justify-content-center" v-for="(item, key) in data" :key="'trajectory' + key">
              <b-row class="justify-content-center">
                <b-col cols="12" xl="6" class="px-0 text-left">
                  <div class="border-bottom-green m-3" :class="{'mt-5' : key > 1}">
                    <h3 class="trajectory-item-title">{{item.value}}</h3>
                    <p class="text-secondary trajectory-item-description" v-html="item.description"></p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Trajectory',
  props: {
    subtitle: String,
    data: Array
  },
}
</script>

<style scoped lang="scss">
.trajectory-title{
  font-family: 'Nunito sans';
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 44px;
  text-align: left;
  /* identical to box height, or 142% */

  letter-spacing: 0.2px;

  /* Light / Black */

  color: #373F41;
}
.trajectory-subtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */

  letter-spacing: 0.3px;
}
.border-bottom-green{
  border-bottom: 5px solid #3FAD29;
}
.trajectory-item-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  /* identical to box height, or 110% */

  letter-spacing: 0.2px;

  /* Light / Black */

  color: #373F41;
}
.trajectory-item-description{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 115% */

  letter-spacing: 0.3px;
  text-transform: uppercase;

  /* Light / Gray dark */

  color: #737B7D;
}
@media(max-width:992px){
  .trajectory-item-description{
    font-size: 20px;
    line-height: 24px;
  }
  .trajectory-item-title{
    font-size: 30px;
    line-height: 34px;
  }
}
</style>
