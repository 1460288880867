<template>
  <div class="pb-5">
    <ViewTitle title="Beneficios" class="py-5 text-center" />
    <b-container>
      <b-row v-if="campaigns" class="text-left">
        <b-col cols="12" md="6" xl="6" v-for="(campaign, key) in campaigns" :key="'campaign' + key" class="mt-5 mt-md-0 mt-xl-0" :class="key > 2 ? 'mt-md-5 mt-xl-5' : 'mt-md-3 mt-xl-3'">
          <div class="project-main-image" :style="{ backgroundImage: 'url(' + (window.width > 992 ? campaign.item_image_url : campaign.mobile_image_url) + ')' }">
          <b-img style="position:absolute;left:-1px;top:0;width:25px" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>
          </div>
          <b-row class="mt-3">
            <!-- <b-col cols="12" md="6" xl="8" class="w-100">
              <p class="project-attribute"><b-img class="mr-2" :src="icons.map"></b-img><span>{{campaign.item_commune}}</span></p>
            </b-col> -->
            <b-col cols="12" md="12" xl="8" class="w-100" :style="{minHeight: titleHeight + 'px'}" ref="titleCol">
              <p class="campaign-attribute"><span>{{campaign.item_title}}</span></p>
            </b-col>
            <b-col cols="12" md="12" xl="8" class="w-100" :style="{minHeight: subtitleHeight + 'px'}" ref="subtitleCol">
              <p class="project-attribute"><span>{{campaign.item_subtitle}}</span></p>
            </b-col>
            <b-col cols="12" md="12" xl="12" class="w-100">
              <router-link v-if="!campaign.slug.includes('https://')" :to="{ name: 'Campaign', params: { campaign_id: campaign.slug }}"><b-button block variant="grey" class="py-2">Participar</b-button></router-link>
              <a v-else :href="campaign.slug" target="_blank"><b-button block variant="grey" class="py-2">Conocer</b-button></a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <p class="not-found bolder text-center">No hay ninguna oportunidad disponible.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ViewTitle from '@/components/ViewTitle'
export default {
  name: 'CampaignCards',
  props: {
    campaigns: {
      type: Array
    },
  },
  components: {
    ViewTitle
  },
  data() {
    return {
      icons:{
        map: require('@/assets/images/invest/project-list/map.svg'),
        mts: require('@/assets/images/invest/project-list/mts-green.svg'),
        actual_state: require('@/assets/images/invest/project-list/deliver-green.svg'),
        bedrooms: require('@/assets/images/invest/project-list/bedrooms-green.svg'),
        bathrooms: require('@/assets/images/invest/project-list/bathrooms-green.svg')
      },
      selectedOpportunity: null,
      selectedProject: null,
      titleHeight: 0,
      subtitleHeight: 0,
      window: {
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    setProject(apartment){
      return this.$store.getters.getProjectByMoby(apartment.proyecto)
    },
    openQuote(opportunity){
      this.selectedOpportunity = opportunity;
      this.selectedProject = this.setProject(opportunity.grupoBienes.find( asset => asset.tipoBien == 'DEPARTAMENTO'));
      this.$emit('openQuote', this.selectedOpportunity, this.selectedProject);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getTitleHeight(){
      const heights = [];
      this.$refs.titleCol.forEach((col) => {
        heights.push(col.clientHeight);
      })
      this.titleHeight = Math.max(...heights);
    },
    getSubtitleHeight(){
      const heights = [];
      this.$refs.subtitleCol.forEach((col) => {
        heights.push(col.clientHeight);
      })
      this.subtitleHeight = Math.max(...heights);
      console.log(this.subtitleHeight);
    },
    handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
  },
  mounted(){
    window.addEventListener('scroll', this.updateScroll);
    this.getTitleHeight();
    this.getSubtitleHeight();
  },
  created() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('scroll', {}, false);
	},
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.campaign-attribute{
  text-transform: uppercase;
}
.project-commune{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-main-image{
  height:265px;
  background-color:none!important;
  background-size:100% 100%;
  background-repeat: no-repeat;
  background-position:center;
  position:relative
}
.not-found{
  font-size:40px;
  padding:50px 0px 0px 0px;
  line-height:100%
}
.project-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-attribute{
  color:$grey
}
.last-units{
  position:absolute;
  right:0;
  padding:10px;
  background-color:$grey
}
.last-units-text{
  font-family: 'Gill Sans'!important;
  font-weight: 600;
}
.filter{
  font-family: 'Gill Sans'!important;
  transition:0.1s ease-in-out;
}
.active-filter{
  font-weight:700;
  transition:0.1s ease-in-out;
}
@media(max-width:992px){
  .filter{
    text-align:center
  }
  .project-main-image{
    height:20vh;
  }
}
@media (min-width:993px) and (max-width:1600px){
  .project-main-image{
    height:14rem;
  }
}
</style>
