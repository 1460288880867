<template>
  <div>
    <div class="position-relative">
      <b-img class="w-100 h-100" :src="image"></b-img>
      <div class="position-absolute w-100 h-100" style="top:0">
        <b-container class="h-100">
          <b-row class="header-image-container h-100 main-row" align-v="center">
            <b-col cols="12" xl="12" class="text-left h-100" :style="`color:${text_color}`">
              <b-row class="w-100 h-100 position-absolute d-flex align-items-center" style="top:0">
                <b-col cols="12" md="8" lg="8" xl="8" class="my-auto">
                  <h2 v-if="title" class="campaign-title">{{title}}</h2>
                  <p v-if="subtitle" class="campaign-subtitle my-5" v-html="subtitle"></p>
                  <a v-if="buttonText && buttonLink" :href="buttonLink" target="_blank"><b-button variant="green">{{buttonText}}</b-button></a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignHeader',
  props: {
    image: {
      type: String,
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    buttonText: {
      type: String
    },
    buttonLink: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
  .campaign-header{
    width:100%;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    object-position: 0px 15%;
  }
  .campaign-title{
    font-weight: 700;
    color:white;
  }
  .campaign-subtitle{
    color:white;
    text-transform: uppercase;
    line-height: 150%;
    font-weight: 500;
  }
  .btn-green{
    background-color: #43A739;
    color:white;
    border-radius:0px;
    padding: 10px 50px;
    text-transform: uppercase;
    font-weight: 900;
  }

</style>
