import { render, staticRenderFns } from "./ProjectCards.vue?vue&type=template&id=32d2bab0&scoped=true&"
import script from "./ProjectCards.vue?vue&type=script&lang=js&"
export * from "./ProjectCards.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCards.vue?vue&type=style&index=0&id=32d2bab0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d2bab0",
  null
  
)

export default component.exports