<template>
  <div class="py-5">
    <b-container class="text-center">
      <h2>{{title}}</h2>
      <b-row class="pt-5 justify-content-center">
        <b-col v-for="(project, key) in projectItems" :key="'project' + key" cols="auto" class="mt-4">
          <a :href="project.link" target="_blank"><b-img class="campaign-project-image" :src="project.image_url"></b-img></a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CampaignProjects',
  props: {
    title: {
      type: String
    },
    projectItems: {
      type: Array
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-project-image{
  width:150px
}
</style>
