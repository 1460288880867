<template>
  <div>
    <Navbar v-if="news"/>
    <Header type="Header" v-if="header" :title="header.title" :italicTitle="header.italic_title" :subtitle="header.subtitle" color="#657963" :text_color="header.text_color" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" :image="header.image_url" :imageMobile="header.mobile_image_url"/>
    <ArticleSearch v-if="news" :news="news" @newsQuery="queryAction" @goTo="goToAction" />
    <NewsCards v-if="news" :news="news" :query="query" ref="cards" />
    <Footer v-if="news"/>
    <ContactModal v-if="news && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/Header.vue'
import ArticleSearch from '@/components/Blog/ArticleSearch.vue'
import NewsCards from '@/components/Blog/NewsCards.vue'
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'Blog',
  components: {
    Header,
    ArticleSearch,
    NewsCards,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      query: null,
      header: null
    }
  },
  methods: {
    queryAction(query) {
      this.query = query;
    },
    goToAction(){
      var element = this.$refs['cards'].$refs['cards'];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'Blog');
    if(this.$store.state.news == null){
      this.$store.commit('setNews');
    }
    window.scrollTo(0,0);
  },
  computed: {
    news() {
      return this.$store.getters.getNews;
    },
    projects() {
      return this.$store.getters.getProjects;
    },
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  },
}
</script>

<style>

</style>
