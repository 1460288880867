<template>
  <div class="light-green-background">
    <b-container class="steps-container">
      <b-row>
        <b-col xl="1" class="my-auto d-none d-md-none d-xl-block">
          <b-img class="w-100" :src="logo"></b-img>
        </b-col>
        <b-col xl="1" class="my-auto">
          <h4 class="campaing-step-title">{{title}}</h4>
        </b-col>
        <b-col xl="3" :offset-xl="key == 0 ? '1' : '0'" v-for="(item, key) in items" :key="'item' + key" class="pt-3 pt-md-3 pt-xl-0" style="position:relative">
          <h4 class="mb-0" v-html="key + 1 + '. ' + item.text"></h4>
          <b-img class="mx-1 pt-3 pt-md-3 pt-xl-0" style="width:15px;position:absolute;top:8px;left:-18px" :src="greenSquare"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CampaignSteps',
  props: {
    title: {
      type: String
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      logo: require('@/assets/images/header/logo.svg'),
      greenSquare: require('@/assets/images/projects/features/green-square.svg')
    }
  },
}
</script>

<style scoped lang="scss">
.light-green-background{
  background-color:#E3F2E2
}
.campaing-step-title{
  font-weight: 800;
}
.steps-container{
    padding-top: 50px;
    padding-bottom: 50px;
  }
@media(min-width:992px){
  .steps-container{
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
</style>
