<template>
  <div class="loading text-center my-auto">
    <b-container class="h-100">
      <b-row class="justify-content-center h-100">
        <b-col cols="12" class="my-auto">
          <b-icon class="mb-3" icon="stopwatch" variant="black" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" class="text-black h5">{{text}}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    text: {
      type: String,
      default: 'Cargando...'
    },
  },
}
</script>

<style scoped lang="scss">
.loading{
  width:100%;
  height:500px
}
</style>
