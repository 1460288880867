<template>
  <div>
    <Navbar/>
    <CampaignHeader v-if="campaign" :image="windowSize > 992 ? campaign.header_image_url : campaign.header_mobile_image_url" :title="campaign.header_title" :subtitle="campaign.header_subtitle" :buttonText="campaign.button_text" :buttonLink="campaign.button_link"/>
    <CampaignSteps v-if="campaign" :title="campaign.campaign_step.title" :items="campaign.campaign_step.campaign_step_items"/>
    <CampaignProjects v-if="campaign" :title="campaign.campaign_project.title" :projectItems="campaign.campaign_project.campaign_project_items"/>
    <CampaignBases v-if="campaign" :bases="campaign.campaign_bases"/>
    <!-- <Header type="Header" v-if="article" :title="article.title" :subtitle="article.subtitle" color="#657963" text_color="white" :categories="article.categories" :logo="true"/>
    <Body v-if="article" :article="article" />-->
    <Footer v-if="campaign"/>
    <ContactModal v-if="campaign && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import CampaignHeader from "@/components/Campaigns/CampaignHeader"
import CampaignSteps from "@/components/Campaigns/CampaignSteps"
import CampaignBases from "@/components/Campaigns/CampaignBases"
import CampaignProjects from "@/components/Campaigns/CampaignProjects"
import Footer from "@/components/Footer"
import ContactModal from '@/components/ContactModal.vue'
// import Header from '@/components/Header';
// import Body from '@/components/Blog/Body';
export default {
  name: 'Campaign',
  components: {
    // Header,
    // Body,
    Navbar,
    CampaignHeader,
    CampaignSteps,
    CampaignBases,
    CampaignProjects,
    Footer,
    ContactModal
  },
  props: {
    campaign_id: {default: null},
  },
  mounted () {
    if(this.$store.state.campaigns == null){
      this.$store.commit('setCampaigns');
    }
    window.scrollTo(0,0);
  },
  computed: {
    campaign() {
      return this.$store.getters.getCampaignBySlug(this.campaign_id);
    },
    windowSize(){
      return window.innerWidth;
    },
    projects() {
      return this.$store.getters.getProjects;
    },
  },
}
</script>

<style>

</style>
