<template>
  <div class="mt-4 light-grey-background" ref="typesFilter">
    <b-container class="pt-5">
      <p class="types-filter-title">Tipos de propiedad</p>
      <b-row class="justify-content-center px-2 px-md-2 px-xl-0">
        <b-col cols="12" xl="12">
          <b-row class="justify-content-center bedroom-filters py-3">
            <b-col cols="12" md="auto" xl="4" v-for="(option, key) in typesFilterOptions" :key="'filter' + key" class="my-auto">
              <p class="mouse-click filter mb-0 filter-attribute" @click="changeType(option.value, option.project_id)" :class="{'active-filter': typesFilterOption===option.value}">{{option.text}}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typesFilterOption: 'apartments',
      typesFilterOptions: [
        {
          text: 'Departamentos',
          value: 'apartments',
          project_id: 8
        },
        {
          text: 'Casas',
          value: 'houses',
          project_id: 11
        },
        {
          text: 'Sitios',
          value: 'grounds',
          project_id: 11
        }
      ]
    }
  },
  methods: {
    changeType: function(value, project_id) {
      this.typesFilterOption = value;
      this.$emit('changeType', value, project_id);
      this.goToAction('typesFilter')
    },
    goToAction(destiny){
      var element = this.$refs[destiny];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
  },
}
</script>

<style scoped lang="scss">
.types-filter-title{
  font-size: 2rem;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: 300;
  color: #000;
}

.filter{
  font-family: 'Gill Sans'!important;
  transition:0.1s ease-in-out;
}
.green-square{
  width:30px
}
.bedroom-filters{
  border:1px solid #54565A
}
.filter-attribute{
  font-family: 'Montserrat'!important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 25px;
}
.active-filter{
  padding: 25px;
  background-color: #8BCB77;
  transition:0.1s ease-in-out;
}
</style>
