<template>
  <div class="grey-background py-5">
    <b-container>
      <p class="text-center search-title pb-3">TE AYUDAMOS A ENCONTRAR LO QUE NECESITAS. ESCRIBE ACÁ LO QUE QUIERES BUSCAR.</p>
      <b-row class="justify-content-center">
        <b-col xl="8">
          <vue-bootstrap-typeahead
            v-model="query"
            :data="newsTitles"
            class="search-input"
            @hit="goTo"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
export default {
  name: 'ArticleSearch',
  components: {
    VueBootstrapTypeahead,
  },
  props: {
    news: {
      type: Array,
    },
  },
  data() {
    return {
      query: null,
    }
  },
  methods: {
    newsQuery() {
      this.$emit('newsQuery', this.query);
    },
    goTo(){
      this.$emit('goTo')
    }
  },
  computed: {
    newsTitles() {
      let filtered = [];
      this.$store.getters.getNews.forEach(element => {
        filtered.push(element.title);
      });
      return filtered;
    }
  },
  watch: {
    query(newValue) {
      if(newValue){
        this.$emit('newsQuery', this.query);
      }else{
        this.$emit('newsQuery', null);
      }
    }
  },
}
</script>

<style scoped lang="scss">
.search-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  /* identical to box height, or 124% */

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.form-control{
  border:0px!important
}
</style>
