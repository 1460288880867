<template>
  <div>
    <Navbar />
    <Header :life="true" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" subtitle="En Fortaleza construimos pensando en el futuro, además de mejorar tu calidad de vida incorporando nuevas tecnologías y facilities." :image="header" />
    <Benefits />
    <Seal />
    <Footer />
    <ContactModal v-if="projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/Header.vue'
import Benefits from '@/components/Life/Benefits.vue'
import Seal from '@/components/Life/Seal.vue'
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'Life',
  components: {
    Header,
    Benefits,
    Seal,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      header: require('@/assets/images/life/background.jpg'),
      logo:require('@/assets/images/life/life.svg')
    }
  },
  mounted () {
    window.scrollTo(0,0);
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
  },
}
</script>

<style>

</style>
