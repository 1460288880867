<template>
  <div>
    <Navbar v-if="header" />
    <Header v-if="header" :us="true" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" :title="header.title" :subtitle="header.subtitle" :italicTitle="header.italic_title" :text_color="header.text_color" color="#434041" :image="header.image_url" :imageMobile="header.mobile_image_url"/>
    <ViewTitle v-if="header" class="text-center py-5" title="Información de contacto" subtitle="No dudes en escribirnos, llamarnos o visitarnos." />
    <CentralOffice v-if="header" :image="centralOfficeImage" />
    <SalesRoom v-if="projects" class="text-center" title="Salas de venta" subtitle="Conoce la información de contacto y dirección del proyecto que te interesa." :projects="projects" />
    <Contact v-if="header" />
    <Footer v-if="header" />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/Header.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import CentralOffice from '@/components/CustomerService/CentralOffice.vue'
import SalesRoom from '@/components/CustomerService/SalesRoom.vue'
import Contact from '@/components/Contact.vue'
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'CustomerService',
  components: {
    Header,
    ViewTitle,
    CentralOffice,
    SalesRoom,
    Contact,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      header: null,
      centralOfficeImage: require('@/assets/images/projects/main-image.jpg')
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'CustomerService')
    window.scrollTo(0,0);
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    }
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  },
}
</script>

<style>

</style>
