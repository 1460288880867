<template>
  <div class="py-5">
    <b-container>
      <ViewTitle title="CONSTRUYENDO SUSTENTABILIDAD" subtitle="En Fortaleza construimos pensando en el futuro, por eso te presentamos Life. Un sello que te muestra que nuestros proyectos incorporan nuevas tecnologías y facilities, además de mejorar tu calidad de vida, te ayudan a ahorrar en el día a día y son buenos con el planeta." class="text-center" />
      <b-row class="justify-content-center mt-5">
        <b-col cols="12" md="12" xl="9">
          <b-container>
            <b-row>
              <b-col cols="12" md="4" xl="4" v-for="(item, key) in lifeBenefits" :key="'item' + key" class="pb-3 pb-md-2 pb-xl-2 col-button" :class="{'pl-md-0 pl-xl-0': key == 0, 'pr-md-0 pr-xl-0': Object.keys(key).length - 1}">
                <b-button @click="select(key), goToAction()" class="w-100 py-3 btn" variant="grey-border" :class="key == selectedItem ? 'btn-grey' : 'btn-grey-border'"><b-img :src="item.icon" class="my-auto pb-1 pr-2" :class="key == selectedItem ? 'white-icon' : 'grey-icon'"></b-img>{{item.title}}</b-button>
              </b-col>
            </b-row>
            <b-row class="justify-content-center py-5" ref="cards" :style="{ backgroundImage: 'url(' + background + ')' }" v-for="(attribute, key) in lifeBenefits[selectedItem].attributes" :key="'attribute' + key" :class="key == lifeBenefits[selectedItem].attributes.length - 1 ? 'benefits-background' : 'item-separator'">
              <b-col cols="12" md="2" xl="2" class="text-center">
                <b-img class="pb-4 pb-md-0 pb-xl-0" :src="attribute.icon"></b-img>
              </b-col>
              <b-col cols="12" md="6" xl="6" class="text-left my-auto text-white">
                <p class="attribute-description pb-0">{{attribute.description}}</p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue'
export default {
  name: 'LifeBenefits',
  components: {
    ViewTitle,
  },
  data() {
    return {
      background: require('@/assets/images/life/attributes/background.jpg'),
      selectedItem: 0,
    }
  },
  methods: {
    select(number) {
      this.selectedItem = number;
    },
    goToAction(){
      var element = this.$refs['cards'][0];
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  computed: {
    lifeBenefits() {
      return this.$store.getters.getLifeBenefits;
    }
  },
}
</script>

<style scoped lang="scss">
.benefits-background{
  border-radius: 0px 0px 30px 30px!important
}
.item-separator{
  position:relative;
}
.item-separator:before {
  content : "";
  position: absolute;
  left    : 18%;
  bottom  : 0;
  height  : 1px;
  width   : 65%;
  border-bottom:1px dashed white;
}
.attribute-description{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
}
.grey-icon{
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(50%) contrast(104%);
}
.btn:hover .grey-icon{
  filter: invert(0%) sepia(6%) saturate(0%) hue-rotate(357deg) brightness(100%) contrast(100%);
}
.white-icon{
  filter: invert(0%) sepia(6%) saturate(0%) hue-rotate(357deg) brightness(100%) contrast(100%);
}
@media (max-width:992px){
  .col-button{
    padding-left:0px;
    padding-right:0px;
  }
  .item-separator:before {
    content : "";
    position: absolute;
    left    : 0%;
    bottom  : 0;
    height  : 1px;
    width   : 100%;
    border-bottom:1px dashed white;
    }
}
</style>
