<template>
  <div>
    <Navbar v-if="article"/>

    <Header type="Header" v-if="article" :title="article.title" :subtitle="article.subtitle" color="#657963" text_color="white" :categories="article.categories" :logo="true"/>
    <Body v-if="article" :article="article" />
    <Footer v-if="article"/>
    <ContactModal v-if="article && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/ProjectHeader';
import Body from '@/components/Blog/Body';
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'Article',
  components: {
    Header,
    Body,
    Navbar,
    Footer,
    ContactModal
  },
  props: {
    article_id: {default: null},
  },
  mounted () {
    if(this.$store.state.news == null){
      this.$store.commit('setNews');
    }
    window.scrollTo(0,0);
  },
  computed: {
    article() {
      return this.$store.getters.getArticleBySlug(this.article_id);
    },
    projects() {
      return this.$store.getters.getProjects;
    },
  },
}
</script>

<style>

</style>
