<template>
  <div class="menu mt-4 px-2 px-xl-0">
    <b-container class="border-box">
      <b-row class="py-3">
        <b-col :cols="projectId != 7 ? '6' : '12'" md="10" xl class="my-auto">
          <b-row>
            <template>
              <b-col
                cols="12"
                md="auto"
                :xl="projectId != 7 ? 'auto' : '4'"
                v-for="(item, key) in menu.filter((item) => item.show)"
                :key="'item' + key"
                class="d-none d-md-block d-xl-block my-auto"
              >
                <div v-if="state == 'Realizado'">
                  <p
                    @click="goTo(item.link)"
                    v-if="item.showIfDone"
                    class="menu-item mb-0 mx-0 mouse-click"
                  >
                    {{ item.text }}
                  </p>
                </div>
                <div v-else-if="state != 'Realizado'">
                  <p
                    v-if="item.text != 'Brochure digital'"
                    @click="
                      item.text != 'Contáctanos'
                        ? goTo(item.link)
                        : $bvModal.show('modal-contact')
                    "
                    class="menu-item mb-0 mx-3 mouse-click"
                  >
                    {{ item.text }}
                  </p>
                  <a
                    v-else-if="
                      item.text == 'Brochure digital' &&
                      digitalBrochureUrl !=
                        '/digital_brochures/original/missing.png'
                    "
                    :href="digitalBrochureUrl"
                    target="_blank"
                    download
                    ><p class="menu-item mb-0 mx-3 mouse-click">
                      {{ item.text }}
                    </p></a
                  >
                </div>
              </b-col>
              <b-col
                cols="12"
                md="12"
                xl="12"
                class="w-100 d-block d-md-none d-xl-none my-auto"
                :class="{ 'pb-4': projectId == 7 }"
              >
                <b-container class="px-0" fluid>
                  <b-form-select
                    class="w-100"
                    v-model="menuSelected"
                    :options="menu"
                  ></b-form-select>
                </b-container>
              </b-col>
            </template>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          :md="projectId != 7 ? '12' : '6'"
          xl="2"
          v-if="projectId == 7"
          :class="{
            'mt-3': projectId == 7,
            'my-auto mt-md-3 mt-lg-3 my-xl-auto': true,
          }"
        >
          <a
            class="button-arrendar"
            href="https://www.assetplan.cl/arriendo/departamento/huechuraba/los-almendros/1851"
            target="_blank"
            ><b-button class="w-100 py-3" variant="light-green"
              >Arrendar</b-button
            ></a
          >
        </b-col>
        <b-col
          cols="6"
          :md="projectId != 7 ? '12' : '6'"
          xl="2"
          :class="{
            'mt-3': projectId == 7,
            'my-auto mt-md-3 mt-lg-3 my-xl-auto': true,
          }"
        >
          <b-button
            class="w-100 py-3"
            variant="grey"
            @click="$bvModal.show('modal-quote')"
            >Cotizar</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    state: {
      type: String,
    },
    digitalBrochureUrl: {
      type: String,
    },
    masterplan: {
      type: Boolean,
    },
    enviroments: {
      type: Boolean,
    },
    tour360: {
      type: Boolean,
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      menuSelected: "mainFeatures",
      menu: [
        {
          text: "Características",
          link: "mainFeatures",
          value: "mainFeatures",
          showIfDone: true,
          show: true,
        },
        {
          text: "Entorno",
          link: "enviroment",
          value: "enviroment",
          showIfDone: false,
          show: this.enviroments,
        },
        {
          text: "Plantas",
          link: "quotes",
          value: "quotes",
          showIfDone: false,
          show: true,
        },
        {
          text: "Galería",
          link: "pilots",
          value: "pilots",
          showIfDone: true,
          show: true,
        },
        {
          text: "Brochure digital",
          link: "payYourPromise",
          value: "digitalBrochure",
          showIfDone: false,
          show: true,
        },
        {
          text: "Vista 360°",
          link: "tour360",
          value: "tour360",
          showIfDone: true,
          show: this.tour360,
        },
        {
          text: "Masterplan",
          link: "masterplan",
          value: "masterplan",
          showIfDone: true,
          show: this.masterplan,
        },
        {
          text: "Ubicación",
          link: "salesRoom",
          value: "salesRoom",
          showIfDone: true,
          show: true,
        },
        {
          text: "Contáctanos",
          link: "contact",
          value: "contact",
          showIfDone: true,
          show: true,
        },
      ],
    };
  },
  methods: {
    goTo(refName) {
      this.$emit("goTo", refName);
    },
  },
  watch: {
    menuSelected(newValue) {
      newValue
        ? this.menuSelected == "contact"
          ? this.$bvModal.show("modal-contact")
          : this.$emit("goTo", this.menuSelected)
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.border-box {
  border: 0.5px solid #54565a;
}
.menu-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* identical to box height, or 112% */
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.menu-item:hover {
  color: #6ebf58;
}
</style>
