<template>
  <div class="light-grey-background py-5">
    <b-container>
      <h2 class="life-quality-title">Espacios contemporeaneos</h2>
      <h2 class="life-quality-title-italic">Es calidad de vida.</h2>
      <b-row class="pt-5">
        <b-col cols="12" md="6" xl="6" class="my-auto">
          <p class="life-quality-text">{{description}}</p>
          <ul class="life-quality-attributes">
            <li v-for="(attribute, key) in attributes" :key="'attribute' + key">{{attribute.title}}</li>
          </ul>
        </b-col>
        <b-col cols="12" md="6" xl="6" class="my-auto pt-4">
          <b-row>
            <b-col cols="6" md="6" xl="6" v-for="(image, key) in images" :key="'image' + key">
              <b-img class="w-100" :class="{'mt-4' : key > 1}" :src="image.image_url"></b-img>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'LifeQuality',
  props: {
    description: {
      type: String
    },
    images: {
      type: Array
    },
    attributes: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
  .light-grey-background{
    background-color: rgba(99, 173, 88, 0.15);
  }
  .life-quality-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 40px;
    /* or 129% */

    letter-spacing: 0.1px;
    text-transform: uppercase;

    /* Light / Black */

    color: #373F41;
  }
  .life-quality-title-italic{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 40px;
    /* or 129% */

    letter-spacing: 0.1px;
    text-transform: uppercase;

    /* Light / Black */

    color: #373F41;
    font-style: italic;
    font-weight: 700;
  }
  .life-quality-text{
    font-family: 'Neutra Text TF';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */


    /* I Fortaleza - Pantone Cool Gray 11C */

    color: #575756;
  }
  .life-quality-attributes li{
    margin-top: 10px;
    list-style-type: circle;
  }
</style>
