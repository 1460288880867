<template>
  <div v-if="apartments">
    <ViewTitle
      title="Oportunidades"
      subtitle="Promociones para que hagas realidad tu nuevo hogar."
      class="py-5 text-center"
    />
    <b-container v-if="apartments">
      <b-row class="text-left mb-5" v-if="apartments.length > 0">
        <template v-for="(opportunity, key) in apartments">
          <b-col
            v-if="checkIfProjectExists(opportunity.project_id)"
            :key="'apartment' + key"
            class="mt-5 mt-md-0 mt-xl-0"
            cols="12"
            md="4"
            xl="4"
            :class="key > 2 ? 'mt-md-5 mt-xl-5' : 'mt-md-3 mt-xl-3'"
          >
            <div
              class="project-main-image"
              @click="
                showSingle(
                  opportunity.grupoBienes[0]
                    .urlImagenDelBienAComercializarUno == ''
                    ? setProject(opportunity.project_id).project_images[0]
                        .image_url
                    : opportunity.grupoBienes[0].urlImagenUno
                )
              "
              :style="{
                backgroundImage:
                  'url(' +
                  (opportunity.grupoBienes[0]
                    .urlImagenDelBienAComercializarUno == ''
                    ? setProject(opportunity.project_id).project_images[0]
                        .image_url
                    : opportunity.grupoBienes[0].urlImagenUno) +
                  ')',
              }"
            >
              <div
                class="text-center text-white"
                v-if="setOpportunityNumber(opportunity) | (numberFormat > 0)"
                style="
                  position: absolute;
                  left: -1px;
                  top: 0;
                  width: 100px;
                  height: 100px;
                  background-color: rgb(117, 170, 98);
                "
              >
                <div
                  class="my-auto w-100 h-100 my-auto d-flex justify-content-center align-items-center p-1"
                >
                  <p class="discount-number mb-0">
                    -{{ setOpportunityNumber(opportunity) | numberFormat }}
                    {{ setSymbol(opportunity.tipoMonto) }}
                  </p>
                </div>
              </div>
            </div>
            <b-row class="mt-3">
              <b-col cols="12">
                <p
                  class="project-commune mb-2"
                  v-if="setProject(opportunity.project_id).commune"
                >
                  <b-img class="mr-2" :src="icons.map"></b-img
                  >{{ setProject(opportunity.project_id).commune }}
                </p>
              </b-col>
              <b-col cols="12">
                <h4 class="project-title">
                  {{ setProject(opportunity.project_id).name }}
                </h4>
              </b-col>
              <b-col cols="12" class="my-2">
                <router-link
                  :to="{
                    name: 'ProjectDetail',
                    params: {
                      project_id: setProject(
                        opportunity.project_id === 11
                          ? 8
                          : opportunity.project_id
                      ).link,
                    },
                  }"
                  ><b-button variant="grey-border" class="w-100 py-3 btn-sm"
                    >Conocer proyecto</b-button
                  ></router-link
                >
              </b-col>
              <b-col cols="12">
                <p class="project-attribute">
                  <b-img class="mr-2" :src="icons.actual_state"></b-img
                  >{{ capitalizeFirstLetter(opportunity.descripcion) }}
                </p>
              </b-col>
              <b-col cols="12">
                <p class="project-attribute">
                  <b-img class="mr-2" :src="icons.mts"></b-img
                  ><span
                    >Desde
                    {{
                      opportunity.grupoBienes[0].superficieTotal | numberFormat
                    }}m2</span
                  >
                </p>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <p class="project-attribute">
                      <b-img class="mr-2" :src="icons.bedrooms"></b-img
                      ><span
                        class="bolder"
                        v-if="opportunity.grupoBienes[0].numeroDeDormitorios"
                        >{{
                          opportunity.grupoBienes[0].numeroDeDormitorios
                            ? opportunity.grupoBienes[0].numeroDeDormitorios
                            : "Sin dormitorios"
                        }}</span
                      >
                      <span class="text-secondary">{{
                        opportunity.grupoBienes[0].numeroDeDormitorios
                          ? " Dormitorios"
                          : " Sin dormitorios"
                      }}</span>
                    </p>
                  </b-col>
                  <b-col cols="6">
                    <p class="project-attribute">
                      <b-img class="mr-2" :src="icons.bathrooms"></b-img
                      ><span
                        class="bolder"
                        v-if="opportunity.grupoBienes[0].numeroDeBanos"
                        >{{ opportunity.grupoBienes[0].numeroDeBanos }}</span
                      >
                      <span class="text-secondary">{{
                        opportunity.grupoBienes[0].numeroDeBanos
                          ? " Baños"
                          : " Sin baños"
                      }}</span>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="4" xl="4" class="w-100 mt-3">
                <b-button
                  variant="grey-border"
                  v-b-modal.modal-quotation
                  class="w-100 py-3"
                  @click="openQuote(opportunity)"
                  >Cotizar</b-button
                >
              </b-col>
              <b-col cols="12" md="8" xl="8" class="w-100 mt-3">
                <b-button
                  variant="grey"
                  v-b-modal.modal-quotation
                  class="w-100 py-3"
                  @click="openReservation(opportunity)"
                  >Reservar</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </template>
      </b-row>
      <b-row v-else>
        <b-col cols="12" class="pb-5">
          <p class="not-found bolder text-center">
            No hay ninguna oportunidad disponible. <br />
            Vuelve en otro momento
          </p>
        </b-col>
      </b-row>
    </b-container>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import ViewTitle from "@/components/ViewTitle";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "ApartmentsCards",
  props: {
    apartments: {
      type: Array,
    },
  },
  components: {
    ViewTitle,
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      icons: {
        map: require("@/assets/images/invest/project-list/map.svg"),
        mts: require("@/assets/images/invest/project-list/mts-green.svg"),
        actual_state: require("@/assets/images/opportunities/discount.svg"),
        bedrooms: require("@/assets/images/invest/project-list/bedrooms-green.svg"),
        bathrooms: require("@/assets/images/invest/project-list/bathrooms-green.svg"),
      },
      selectedOpportunity: null,
      selectedProject: null,
      assetsList: ["DEPARTAMENTO", "CASA", "OFICINA", "LOCAL", "LOTE"],
    };
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setProject(project_id) {
      return this.$store.getters.getProjectByMoby(project_id);
    },
    checkIfProjectExists(project_id) {
      return this.$store.getters.getProjectByMoby(project_id) != undefined
        ? true
        : false;
    },
    openQuote(opportunity) {
      this.selectedOpportunity = opportunity;
      this.selectedProject = this.setProject(opportunity.project_id);
      this.$emit("openQuote", this.selectedOpportunity, this.selectedProject);
    },
    openReservation(opportunity) {
      this.selectedOpportunity = opportunity;
      this.selectedProject = this.setProject(opportunity.project_id);
      this.$emit(
        "openReservation",
        this.selectedOpportunity,
        this.selectedProject
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setOpportunityNumber(opportunity) {
      if (opportunity.tipoMonto == "PORCENTAJE") {
        let elem = opportunity.montoDescuento * 100;
        return parseInt(elem) > 9 ? Math.round(elem * 10) / 10 : elem;
      } else {
        return opportunity.montoDescuento;
      }
    },
    setSymbol(discountType) {
      if (discountType == "PORCENTAJE") {
        return "%";
      } else if (discountType == "CONSTANTE") {
        return "UF";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.project-commune {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-main-image {
  height: 310px;
  background-color: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative !important;
  cursor: pointer;
}
.not-found {
  font-size: 40px;
  line-height: 130%;
}
.project-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-attribute {
  color: $grey;
}
.last-units {
  position: absolute;
  right: 0;
  padding: 10px;
  background-color: $grey;
}
.last-units-text {
  font-family: "Gill Sans" !important;
  font-weight: 600;
}
.filter {
  font-family: "Gill Sans" !important;
  transition: 0.1s ease-in-out;
}
.active-filter {
  font-weight: 700;
  transition: 0.1s ease-in-out;
}
@media (max-width: 992px) {
  .filter {
    text-align: center;
  }
  .not-found {
    font-size: 1.5rem;
    line-height: 130%;
  }
}
.discount-number {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
