<template>
  <div>
    <Navbar />
    <Header type="Header" v-if="header" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" :title="header.title" :italicTitle="header.italic_title" :subtitle="header.subtitle" color="#434041" :text_color="header.text_color" :image="header.image_url" :imageMobile="header.mobile_image_url" />
    <ViewTitle class="pt-5 text-center" title="Proyectos realizados en diferentes comunas de la región" subtitle="Proyectos que nos llenan de orgullo en la Región Metropolitana y V Región."/>
    <ProjectCards :accomplishedProjects="true" :useBedroomsFilter="false" :useCommuneFilter="true" :showProjectDetails="false" />
    <Footer />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import ViewTitle from "@/components/ViewTitle";
import ProjectCards from "@/components/ProjectCards";
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'AccomplishedProjects',
  components: {
    Header,
    ProjectCards,
    ViewTitle,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      header: require('@/assets/images/projects/main-image.jpg'),
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'AccomplishedProjects');
    window.scrollTo(0,0);
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
  },
}
</script>

<style>

</style>
