<template>
  <div>
    <Navbar v-if="header"/>
    <Header v-if="header" :title="header.title" :subtitle="header.subtitle" :text_color="header.text_color" :image="header.image_url" :imageMobile="header.mobile_image_url" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype"/>
    <ViewTitle v-if="header" title="Tenemos estos proyectos a tu disposición" subtitle="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." class="pt-5" />
    <ProjectCards v-if="header" :useBedroomsFilter="false"/>
    <Benefits v-if="header" :benefits="benefits.benefits" :slider="benefits.slider"/>
    <Contact />
    <Footer v-if="header" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/Header.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import ProjectCards from '@/components/ProjectCards.vue'
import Benefits from '@/components/Invest/Benefits.vue'
import Contact from '@/components/Contact.vue'
export default {
  name: 'Invest',
  components: {
    Header,
    ViewTitle,
    ProjectCards,
    Benefits,
    Navbar,
    Contact,
    Footer
  },
  data() {
    return {
      header: null
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'Invest');
    window.scrollTo(0,0);
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
    benefits() {
      return this.$store.getters.getInvestBenefits;
    }
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  }
}
</script>

<style>

</style>
