<template>
  <div class="py-4" ref="enviroment">
    <b-container class="py-5" v-if="pilots">
      <h3 class="pilots-title text-left my-3">Entorno</h3>
      <b-row>
        <b-col cols="12" md="12" xl="8" class="my-4 my-md-4 my-xl-0">
          <b-carousel
            v-if="pilots"
            id="carouselPilots"
            ref="carouselPilots"
            v-model="slide"
            background="#ababab"
            :no-hover-pause="true"
            :no-touch="true"
            :interval="0"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              v-for="(pilot, key) in pilots"
              :key="'pilot' + key"
            >
              <template v-if="pilot.iframe" #img>
                <iframe
                  class="w-100 pilot-img"
                  style="display: none"
                  onload="this.style.display='block';"
                  scrolling="no"
                  :src="pilot.iframe"
                ></iframe>
              </template>
              <template v-else #img>
                <div
                  @click="showSingle(checkImagesToShow(pilots, pilot))"
                  class="w-100 pilot-img cursor-pointer"
                  :style="{
                    backgroundImage: 'url(' + pilot.image_url + ')',
                    backgroundPositionX: pilot.image_position_x,
                    backgroundPositionY: pilot.image_position_y,
                  }"
                ></div>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <b-row class="justify-content-center mt-4" v-if="pilots.length > 1">
            <b-col cols="2" xl="2">
              <div @click="$refs.carouselPilots.prev()">
                <b-img
                  class="pilots-arrows"
                  :src="
                    require('@/assets/images/projects/details/angle-left-solid.svg')
                  "
                ></b-img>
              </div>
            </b-col>
            <b-col cols="2" xl="2">
              <div @click="$refs.carouselPilots.next()">
                <b-img
                  class="pilots-arrows"
                  :src="
                    require('@/assets/images/projects/details/angle-right-solid.svg')
                  "
                ></b-img>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="12" xl="4" class="my-auto">
          <div class="pilots-description-box">
            <p class="pilots-subtitle">
              {{ pilots[slide].description }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "Pilots",
  components: {
    VueEasyLightbox,
  },
  props: {
    pilots: {
      type: Array,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      index: 0, // default: 0
      visible: false,
      imgs: "", // Img Url , string or Array of string
    };
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show();
    },
    checkImagesToShow(imagesToShow, image) {
      const images = [];
      imagesToShow.forEach((image) => {
        if (!image.iframe) {
          images.push(image.image_url);
        }
      });
      this.index = images.indexOf(image.image_url);
      return images;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.pilots-arrows {
  width: 50px;
  height: 50px;
  color: #e6e7e8;
  cursor: pointer;
}
.pilots-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.pilots-description-box {
  padding: 50px;
  background-color: rgba(99, 173, 88, 0.15);
}
.pilots-description {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
#carouselPilots a span.carousel-control-prev {
  left: -100px !important;
}
.carousel-control-next {
  right: -100px !important;
}

.pilot-img {
  height: 500px;
  background-size: cover !important;
}

@media (max-width: 992px) {
  .pilot-img {
    height: 500px;
    background-size: cover;
  }
}
</style>
