<template>
  <div class="pb-5 py-md-5 py-xl-5 grey-background">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" xl="6" class="my-auto text-center px-0 px-md-3 px-xl-3">
          <b-img class="mb-5 mb-md-0 mb-xl-0 w-100" :src="require('@/assets/images/about/nosotros.png')"></b-img>
        </b-col>
        <b-col cols="12" md="6" xl="6" class="my-auto text-left">
          <h3 class="propose-item-title">{{title}}</h3>
          <p class="text-secondary mt-5">{{description}}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Propose',
  props: {
    title: String,
    description: String,
    subtitle: String,
    data: Array
  },
}
</script>

<style scoped lang="scss">
.propose-title{
  font-family: Gill Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 44px;
  /* identical to box height, or 142% */

  letter-spacing: 0.2px;

  /* Light / Black */

  color: #373F41;
}
.propose-subtitle{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* or 122% */

  letter-spacing: 0.3px;
}
.propose-item-title{
  font-family: Gill Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  /* identical to box height, or 110% */

  letter-spacing: 0.2px;

  /* Light / Black */

  color: #373F41;
}
</style>
