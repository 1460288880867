<template>
  <div class="py-5">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" xl="6">
          <div class="background-image sales-room-image">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.602363441244!2d-70.57130418480182!3d-33.407536780786174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cede91055555%3A0x5ad736ec8d4440fe!2sAlonso%20de%20C%C3%B3rdova%205900%2C%20oficina%201201%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1651094125395!5m2!1ses!2scl" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="6" class="my-auto">
          <h3 class="central-office-title pb-5 mt-5 mt-md-5 mt-xl-0">Oficina Central</h3>
          <b-row v-for="(item, key) in info" :key="'info' + key">
            <b-col cols="1">
              <b-img :src="item.icon"></b-img>
            </b-col>
            <b-col cols="11">
              <a :href="item.link"><p class="central-office-description">{{item.text}}</p></a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CentralOffice',
  props: {
    image: String
  },
  data() {
    return {
      info: [
        { icon: require('@/assets/images/customer-service/central-office/email.svg'), text: ' contacto@ifortaleza.cl', link: 'mailto: contacto@ifortaleza.cl' },
        { icon: require('@/assets/images/customer-service/central-office/phone.svg'), text: '+56 9 7849 7360', link: 'tel:+56978497360' },
        { icon: require('@/assets/images/customer-service/central-office/phone.svg'), text: '2 2862 8701', link: 'tel:228628701' },
        { icon: require('@/assets/images/customer-service/central-office/map.svg'), text: 'Alonso de Córdova 5900, oficina 1201 - Las Condes, Santiago de Chile', link: null },
        { icon: require('@/assets/images/customer-service/central-office/time.svg'), text: 'Lunes a Jueves de 8:30 a 19:00, Viernes de 8:30 a 15:30', link: null }
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.central-office-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.central-office-description{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 34px;
  /* or 212% */


  color: #575756;
}
.central-office-background-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:450px;
  width:100%
}
@media (max-width:992px){
  .central-office-background-img{
    height:250px;
  }
}
</style>
