<template>
  <div>
    <div id="feedback">
      <a v-b-modal.modal-contact>Contáctanos</a>
    </div>
    <b-modal id="modal-contact" size="lg" centered >
      <Contact :showImage="false" :modal="true"/>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Contact from "@/components/Contact"
export default {
  name: 'ContactModal',
  components: {
    Contact,
  },
}
</script>

<style>

</style>
