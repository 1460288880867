<template>
  <div class="py-5">
    <b-container style="position:relative">
      <!-- Primer body principal -->
      <b-row v-if="article.text_body_1">
        <b-col cols="12" md="6" xl="6" order="1" order-xl="1" class="my-auto">
          <b-img v-if="article.image_body_1_url != '/image_body_1s/original/missing.png'" :src="article.image_body_1_url" class="w-100 article-image"></b-img>
        </b-col>
        <b-col cols="12" md="6" :xl="article.image_body_1_url != '/image_body_3s/original/missing.png' ? '6' : '12'" order="2" order-xl="2" class="my-auto">
          <h3 class="pb-3 pt-3 pt-xl-0" v-if="article.subtitle_body_1" v-html="article.subtitle_body_1"></h3>
          <hr v-if="article.subtitle_body_1" style="border-bottom: 4px solid rgba(63, 173, 41, 1); width:25%;margin-left:0" />
          <p class="py-3 mb-0" v-if="article.text_body_1" v-html="article.text_body_1"></p>
        </b-col>
      </b-row>
      <b-row v-if="article.text_body_2" class="my-5">
        <b-col cols="12" md="6" xl="6" order="1" order-xl="2" class="my-auto">
          <b-img v-if="article.image_body_2_url != '/image_body_2s/original/missing.png'" :src="article.image_body_2_url" class="w-100 article-image"></b-img>
        </b-col>
        <b-col cols="12" md="6" :xl="article.image_body_2_url != '/image_body_2s/original/missing.png' ? '6' : '12'" order="2" order-xl="1" class="my-auto">
          <h3 class="pb-3 pt-3 pt-xl-0" v-if="article.subtitle_body_2" v-html="article.subtitle_body_2"></h3>
          <hr v-if="article.subtitle_body_2" style="border-bottom: 4px solid rgba(63, 173, 41, 1); width:25%;margin-left:0" />
          <p class="py-3 mb-0" v-if="article.text_body_2" v-html="article.text_body_2"></p>
        </b-col>
      </b-row>
      <b-row v-if="article.text_body_3" class="mt-5">
        <b-col cols="12" md="6" xl="6" order="1" order-xl="1" class="my-auto">
          <b-img v-if="article.image_body_3_url != '/image_body_3s/original/missing.png'" :src="article.image_body_3_url" class="w-100 article-image"></b-img>
        </b-col>
        <b-col cols="12" md="6" :xl="article.image_body_3_url != '/image_body_3s/original/missing.png' ? '6' : '12'" order="2" order-xl="2" class="my-auto">
          <h3 class="pb-3 pt-3 pt-xl-0" v-if="article.subtitle_body_3" v-html="article.subtitle_body_3"></h3>
          <hr v-if="article.subtitle_body_3" style="border-bottom: 4px solid rgba(63, 173, 41, 1); width:25%;margin-left:0" />
          <p class="py-3 mb-0" v-if="article.text_body_3" v-html="article.text_body_3"></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ArticleBody',
  props: {
    article: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="scss">
.main-text-box{
  border:1px solid rgba(87, 87, 86, 1)
}
.article-main-text {
  color: rgba(87, 87, 86, 1);
}

.article-main-image{
  min-height: 300px;
  object-fit:cover;
}
.article-image{
  min-height: 200px;
  object-fit:cover;
}

@media(min-width:993px){
  .article-main-image{
    position:absolute;
    top:-8rem
  }
}
</style>
