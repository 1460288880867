<template>
  <div ref="quotes" class="light-grey-background">
    <ViewTitle
      class="text-center pt-5"
      v-if="useCards"
      title="Tipos de plantas"
      position="center"
    />
    <div v-if="apartments">
      <b-container v-if="useCards" class="pb-5">
        <div>
          <b-container>
            <b-row class="justify-content-center">
              <b-col cols="12" xl="5">
                <b-row class="justify-content-center bedroom-filters py-4">
                  <b-col
                    cols="auto"
                    md="auto"
                    xl="auto"
                    v-for="(option, key) in bedroomFilterOptions"
                    :key="'filter' + key"
                    class="my-auto"
                  >
                    <p
                      class="mouse-click filter mb-0 filter-attribute"
                      :class="{
                        'active-filter': bedroomFilterOption === option.value,
                      }"
                      @click="filterBedroom(option.value)"
                    >
                      {{ option.text }}
                      {{ pluralOrSingularFilter(option.text, "Dorm", "Dorms") }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" xl="5" offset-xl="1" class="mt-5 mt-xl-0">
                <b-row class="justify-content-center bedroom-filters py-4">
                  <b-col
                    cols="auto"
                    md="auto"
                    xl="auto"
                    v-for="(option, key) in bathroomFilterOptions"
                    :key="'filter' + key"
                    class="my-auto"
                  >
                    <p
                      class="mouse-click filter mb-0 filter-attribute"
                      :class="{
                        'active-filter': bathroomFilterOption === option.value,
                      }"
                      @click="filterBathroom(option.value)"
                    >
                      {{ option.text }}
                      {{ pluralOrSingularFilter(option.text, "Baño", "Baños") }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="apartmentsFiltered.length > 0" class="mt-5 text-left">
              <b-col
                cols="12"
                md="12"
                xl="4"
                v-for="(apartment, key) in apartmentsFiltered"
                :key="'apartment' + key"
                :class="
                  key > 2 ? 'mt-5 mt-md-5 mt-xl-5' : 'mt-5 mt-md-5 mt-xl-5'
                "
              >
                <div
                  @click="showSingle(checkImagesToShow(apartment))"
                  class="project-main-image"
                  :style="[
                    apartment.urlImagenUno &&
                    apartment.urlImagenUno !=
                      'https://s3.amazonaws.com/gestion.mobysuite/'
                      ? {
                          backgroundImage:
                            'url(' + apartment.urlImagenUno + ')',
                        }
                      : {
                          backgroundImage:
                            'url(' +
                            require('@/assets/images/project/no-image.jpg') +
                            ')',
                        },
                  ]"
                >
                  <b-img
                    class="green-square"
                    :src="
                      require('@/assets/images/projects/features/green-square.svg')
                    "
                  ></b-img>
                </div>
                <b-row class="mt-3 surfaces-container">
                  <!-- Tipo tiene dormitorios -->
                  <b-col cols="12" v-if="apartment.numeroDeDormitorios">
                    <h4 class="project-title mb-1">
                      {{ apartment.tipoBien }} {{ apartment.tipo }}
                    </h4>
                  </b-col>
                  <!-- Tipo No tiene dormitorios -->
                  <b-col cols="12" v-if="!apartment.numeroDeDormitorios">
                    <h4 class="project-title mb-0">{{ apartment.tipoBien }}</h4>
                  </b-col>
                  <b-col
                    cols="12"
                    style="margin-bottom: 38px"
                    v-if="!apartment.numeroDeDormitorios"
                  >
                    <h4 class="project-title">{{ apartment.tipo }}</h4>
                  </b-col>
                  <b-col cols="12" v-if="apartment.numeroDeDormitorios">
                    <p class="project-title">
                      {{ apartment.numeroDeDormitorios }}
                      {{
                        apartment.numeroDeDormitorios != 1 ? "DORMS" : "DORM"
                      }}
                      + {{ apartment.numeroDeBanos }}
                      {{ apartment.numeroDeBanos != 1 ? "BAÑOS" : "BAÑO" }}
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <p class="project-attribute mb-0">Superficies</p>
                  </b-col>
                  <b-col cols="12">
                    <p class="project-attribute mb-0">
                      {{
                        apartment.tipoBien.slice(0, 1).toUpperCase() +
                        apartment.tipoBien.slice(1).toLowerCase()
                      }}: {{ apartment.superficieUtil }} m²
                    </p>
                  </b-col>
                  <b-col cols="12" v-if="apartment.superficieTerraza">
                    <p class="project-attribute mb-0">
                      Terrazas: {{ apartment.superficieTerraza }} m²
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <p class="project-attribute mb-0">
                      Total: {{ apartment.superficieTotal | numberFormat }} m²
                    </p>
                  </b-col>
                  <b-col cols="17" md="7" xl="7" class="w-100 mt-3">
                    <b-button
                      @click="showSingle(checkImagesToShow(apartment))"
                      block
                      size="lg"
                      variant="grey-border"
                      class="text-uppercase d-block quote-buttons"
                      >Ver planta</b-button
                    >
                  </b-col>
                  <b-col cols="12" md="5" xl="5" class="w-100 mt-3">
                    <b-button
                      v-b-modal.modal-quote
                      @click="
                        setSelectedApartment(
                          getApartmentFromType(apartment.tipo)
                        ),
                          filterByType(apartment.tipo)
                      "
                      block
                      size="lg"
                      variant="grey"
                      class="text-uppercase d-block quote-buttons"
                      >Cotizar</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else-if="apartments.length === 0">
              <b-col cols="12 py-5">
                <p class="not-found bolder text-center">
                  No hay ningún bien disponible
                </p>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12 py-5">
                <p class="not-found bolder text-center">
                  No se encontró ningún bien con esos filtros
                </p>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-container>
      <!-- Modal cotizar -->
      <b-modal
        v-if="apartments"
        :hide-footer="true"
        :hide-header="true"
        :id="id"
        size="xl"
        centered
        title="Solicitud de Cotización"
        header-bg-variant="light"
        header-text-variant="dark"
        :no-enforce-focus="true"
        @hidden="cleanModal()"
      >
        <b-overlay :show="loadingQuote" rounded="lg">
          <b-container fluid>
            <b-row>
              <b-col class="pr-0">
                <button
                  type="button"
                  aria-label="Close"
                  class="close"
                  @click="$bvModal.hide(id)"
                >
                  ×
                </button>
              </b-col>
            </b-row>
          </b-container>
          <div v-if="showResponse">
            <b-container fluid>
              <b-row>
                <b-col cols="12" md="12">
                  <div class="h1 text-uppercase">
                    <b>{{ quote.name }}</b>
                  </div>
                  <div class="text-grey text-uppercase">
                    <span class="h3"><b>Gracias por cotizar</b></span
                    ><br />
                    <span class="h5">Un ejecutivo te contactará en breve</span>
                  </div>
                  <div class="h4 text-uppercase mt-4">
                    <b
                      >Resumen de cotización Nº
                      {{ quoteResponse.id | numberFormat }}</b
                    >
                  </div>
                  <b-table-simple borderless class="table-quote mt-4">
                    <b-tr
                      v-for="(asset, key) in quote.assets"
                      v-bind:key="'quote-asset-' + key"
                    >
                      <b-td>
                        {{ getAsset(asset).text }}
                      </b-td>
                      <b-td class="text-right">
                        UF: {{ getAsset(asset).price | numberFormat }}
                      </b-td>
                    </b-tr>
                    <b-tr class="white-background">
                      <b-td colspan="2"><p></p></b-td>
                    </b-tr>
                    <b-tr class="text-black text-uppercase h5">
                      <b-th> Total Cotización </b-th>
                      <b-th class="text-right text-nowrap">
                        UF: {{ sumPriceAssets(quote.assets) | numberFormat }}
                      </b-th>
                    </b-tr>
                  </b-table-simple>
                  <b-table-simple borderless>
                    <b-tr>
                      <b-td class="pl-0 w-50 text-white"
                        ><b-button
                          squared
                          block
                          variant="dark"
                          :href="quoteResponse.document"
                          target="_blank"
                          >Descargar cotización</b-button
                        ></b-td
                      >
                      <b-td class="pr-0 text-white"
                        ><b-button
                          squared
                          block
                          variant="dark"
                          @click="cleanModal"
                          target="_blank"
                          >Nueva cotización</b-button
                        ></b-td
                      >
                    </b-tr>
                    <!-- <b-tr v-if="project.project_id != 4">
                      <b-td colspan="2" class="px-0">
                        <div style="border: 1px solid grey;">
                          <b-button squared block variant="cotizar" :href="'https://reserva.mobysuite.com/fortaleza/proyecto/'+project.project_name+'?code='+quoteResponse.code" target="_blank" class="text-uppercase" style="border: 4px solid #FFF" >Reserva online</b-button>
                        </div>
                      </b-td>
                    </b-tr> -->
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div v-else>
            <b-container class="form-sm" fluid v-if="selectedApartment">
              <b-row>
                <b-col order="1" order-xl="0">
                  <b-table-simple borderless class="m-0" small>
                    <b-tr>
                      <b-td class="h2 px-0"> COTIZACIÓN </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
                <b-col
                  order="0"
                  order-xl="1"
                  class="quote-logo my-3 my-xl-0 text-right"
                >
                  <h3>{{ project.name }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="5" class="my-auto">
                  <b-row>
                    <b-col>
                      <b-form-group label="Seleccionar Planta">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="selectedType"
                          :options="apartmentTypes"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          :custom-label="setTypeLabel"
                          deselect-label="Eliminar selección"
                          @input="filterByType(selectedType)"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                      <b-table-simple
                        borderless
                        small
                        condensed
                        class="my-2 text-center table-quote"
                      >
                        <b-tr>
                          <b-td
                            v-if="
                              selectedApartment &&
                              selectedApartment.numeroDeDormitorios
                            "
                          >
                            <b-img :src="icons.bedrooms"></b-img>
                            {{ selectedApartment.numeroDeDormitorios }} dorm.
                          </b-td>
                          <b-td
                            v-if="
                              selectedApartment &&
                              selectedApartment.numeroDeBanos
                            "
                          >
                            <b-img :src="icons.bathrooms"></b-img>
                            {{ selectedApartment.numeroDeBanos }} baño{{
                              selectedApartment.numeroDeBanos > 1 ? "s" : ""
                            }}
                          </b-td>
                          <b-td
                            v-if="
                              selectedApartment &&
                              selectedApartment.superficieTotal
                            "
                          >
                            <b-img :src="icons.mts"></b-img>
                            {{
                              selectedApartment.superficieTotal | numberFormat
                            }}
                            m<sup>2</sup>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Departamento">
                        <multiselect
                          :allow-empty="false"
                          class="multiselect-sm"
                          v-model="selectedApartment"
                          :options="filteredApartments"
                          :close-on-select="true"
                          :show-labels="false"
                          placeholder="Seleccionar"
                          :custom-label="setApartmentLabel"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="!excludedTypes.includes(selectedApartment.tipo)"
                    >
                      <b-form-group label="Bodega">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="selectedCellar"
                          :options="cellars"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          track-by="id"
                          :custom-label="setAssetLabel"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!excludedTypes.includes(selectedApartment.tipo)"
                    >
                      <b-form-group label="Estacionamiento">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="selectedParking"
                          :options="parkings"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          track-by="id"
                          :custom-label="setAssetLabel"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Rut">
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.rut.$model"
                          @keyup="formatRut"
                          :state="
                            $v.quote.rut.$dirty ? !$v.quote.rut.$error : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Rut Inválido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Nombre">
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.name.$model"
                          :state="
                            $v.quote.name.$dirty ? !$v.quote.name.$error : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          El nombre debe contener al menos
                          {{ $v.quote.name.$params.minLength.min }} caracteres.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Apellido">
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.lastname.$model"
                          :state="
                            $v.quote.lastname.$dirty
                              ? !$v.quote.lastname.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          El apellido debe contener al menos
                          {{ $v.quote.lastname.$params.minLength.min }}
                          caracteres.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Email">
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.email.$model"
                          :state="
                            $v.quote.email.$dirty
                              ? !$v.quote.email.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Email inválido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="Teléfono">
                        <vue-tel-input
                          size="sm"
                          @input="checkPhone"
                          wrapperClasses="form-control cellphone-input form-control-sm"
                        ></vue-tel-input>
                        <b-form-input
                          class="d-none"
                          v-model="$v.quote.cellphone.$model"
                          :state="
                            $v.quote.cellphone.$dirty
                              ? !$v.quote.cellphone.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Teléfono invalido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Rango etario">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="quote.age_range"
                          :options="ageRangeList"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Comuna de residencia">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="quote.commune"
                          :options="communeList"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Busca para">
                        <multiselect
                          :allow-empty="true"
                          class="multiselect-sm"
                          v-model="quote.destiny"
                          :options="destinyList"
                          :close-on-select="true"
                          :show-labels="true"
                          placeholder="Seleccionar"
                          deselect-label="Eliminar selección"
                          select-label=""
                          selected-label="Seleccionado"
                        >
                          <template slot="noOptions"
                            >No hay elementos disponibles.</template
                          >
                        </multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="my-auto align-items-center">
                  <b-row>
                    <b-col cols="12">
                      <b-carousel
                        v-if="
                          selectedApartment.urlImagenDelBienAComercializarDos ||
                          selectedApartment.urlImagenDelBienAComercializarTres
                        "
                        :id="'carousel-project' + key"
                        :interval="0"
                        controls
                        indicators
                      >
                        <template class="d-none d-md-mone d-xl-block">
                          <div
                            v-if="
                              selectedApartment.urlImagenDelBienAComercializarUno
                            "
                            class="carousel-item project-main-image-carousel-quote"
                          >
                            <b-img
                              class="w-100 h-100 project-main-image-fit"
                              :src="selectedApartment.urlImagenUno"
                            ></b-img>
                          </div>
                          <div
                            v-if="
                              selectedApartment.urlImagenDelBienAComercializarDos
                            "
                            class="carousel-item project-main-image-carousel-quote"
                          >
                            <b-img
                              class="w-100 h-100 project-main-image-fit"
                              :src="selectedApartment.urlImagenDos"
                            ></b-img>
                          </div>
                          <div
                            v-if="
                              selectedApartment.urlImagenDelBienAComercializarTres
                            "
                            class="carousel-item project-main-image-carousel-quote"
                          >
                            <b-img
                              class="w-100 h-100 project-main-image-fit"
                              :src="selectedApartment.urlImagenTres"
                            ></b-img>
                          </div>
                        </template>
                      </b-carousel>
                      <div
                        v-else
                        class="project-main-image"
                        :style="[
                          selectedApartment.urlImagenUno &&
                          selectedApartment.urlImagenUno !=
                            'https://s3.amazonaws.com/gestion.mobysuite/'
                            ? {
                                backgroundImage:
                                  'url(' + selectedApartment.urlImagenUno + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(' +
                                  require('@/assets/images/project/no-image.jpg') +
                                  ')',
                              },
                        ]"
                      >
                        <b-img
                          class="green-square"
                          :src="
                            require('@/assets/images/projects/features/green-square.svg')
                          "
                        ></b-img>
                      </div>
                    </b-col>
                    <b-col cols="12" class="mt-xl-5 mt-0">
                      <b-button
                        :id="
                          'cotizar-' +
                          project.name.toLowerCase().replace(/\s+/g, '-')
                        "
                        block
                        variant="grey"
                        size="lg"
                        @click="send"
                        >Cotizar</b-button
                      >
                      <b-alert
                        class="mt-2"
                        v-model="quoteError"
                        variant="danger"
                        dismissible
                      >
                        Ha ocurrido un error al cotizar, intenta nuevamente en
                        unos minutos, si el error persiste por favor contáctanos
                        al
                        <a
                          :href="`https://api.whatsapp.com/send?phone=${project.project_detail.sales_room.whatsapp}`"
                          target="_blank"
                          >+{{ project.project_detail.sales_room.whatsapp }}</a
                        >.
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-else>
              <b-row>
                <b-col cols="12 py-5">
                  <p class="not-found bolder text-center">
                    Lo sentimos. <br />
                    No hay ningún bien disponible
                  </p>
                  <router-link
                    v-if="$route.name === 'ProjectDetail'"
                    :to="{ name: 'ProjectList' }"
                    ><b-button variant="grey" class="w-100 py-3 mt-4 mx-auto"
                      >Conocer otros proyectos</b-button
                    ></router-link
                  >
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-overlay>
      </b-modal>
    </div>
    <div v-else-if="!apartments && useCards">
      <Loading text="Cargando plantas..." />
    </div>
    <!-- all props & events -->
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { required, minLength, email } from "vuelidate/lib/validators";
import ViewTitle from "@/components/ViewTitle";
import Loading from "@/components/Loading";
export default {
  name: "quote",
  props: {
    project: { default: null },
    showView: { default: true },
    id: { default: "modal-quote" },
    isInvest: { default: false },
    color: { default: "#8DC63F" },
    useCards: { default: true },
  },
  components: {
    ViewTitle,
    Loading,
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      excludedTypes: null,
      selectedApartment: null,
      selectedRoom: null,
      selectedBathroom: null,
      roms: null,
      bathrooms: null,
      apartments: null,
      filteredApartments: null,
      selectedCellar: null,
      selectedParking: null,
      selectedComplementary: null,
      complementaries: null,
      apartmentTypes: null,
      selectedType: null,
      loading: false,
      chilean: true,
      isLoading: false,
      loadingQuote: false,
      complementaryList: [],
      parkings: [],
      cellars: [],
      ageRangeList: [],
      communeList: [],
      destinyList: [],
      showResponse: false,
      quoteError: false,
      bedroomFilterOption: "all",
      bedroomFilterOptions: [{ text: "Todos", value: "all" }],
      bathroomFilterOption: "all",
      bathroomFilterOptions: [{ text: "Todos", value: "all" }],
      quote: {
        customer_name: "fortaleza",
        project_name: null,
        project_id: null,
        name: null,
        lastname: null,
        email: null,
        cellphone: null,
        rut: null,
        age_range: null,
        commune: null,
        destiny: null,
        tracker: null,
        source: null,
        assets: [],
      },
      quoteResponse: {
        code: null,
        document: null,
        id: null,
      },
      settings: {
        dots: false,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        rows: 1,
        slidesToShow: 9,
        slidesToScroll: 1,
        touchThreshold: 5,
      },
      settingsMobile: {
        dots: false,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        rows: 1,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
      },
      icons: {
        map: require("@/assets/images/invest/project-list/map.svg"),
        mts: require("@/assets/images/invest/project-list/mts.svg"),
        actual_state: require("@/assets/images/invest/project-list/deliver.svg"),
        bedrooms: require("@/assets/images/invest/project-list/bedrooms.svg"),
        bathrooms: require("@/assets/images/invest/project-list/bathrooms.svg"),
      },
    };
  },
  validations() {
    if (this.chilean) {
      return {
        quote: {
          rut: { required, RutValid: isRutValid },
          name: { required, minLength: minLength(2) },
          lastname: { required, minLength: minLength(2) },
          email: { required, email },
          cellphone: { required, minLength: minLength(9) },
        },
      };
    } else {
      return {
        quote: {
          rut: { required: false },
          name: { required, minLength: minLength(2) },
          lastname: { required, minLength: minLength(2) },
          email: { required, email },
          cellphone: { required, minLength: minLength(9) },
        },
      };
    }
  },
  beforeMount() {
    this.ageRangeList = [
      "1-25",
      "26-35",
      "36-45",
      "46-55",
      "56-65",
      "66 o más",
    ];
    this.communeList = [
      "Arica",
      "Camarones",
      "Putre",
      "General Lagos",
      "Iquique",
      "Alto Hospicio",
      "Pozo Almonte",
      "Camiña",
      "Colchane",
      "Huara",
      "Pica",
      "Antofagasta",
      "Mejillones",
      "Sierra Gorda",
      "Taltal",
      "Calama",
      "Ollagüe",
      "San Pedro de Atacama",
      "Tocopilla",
      "María Elena",
      "Copiapó",
      "Caldera",
      "Tierra Amarilla",
      "Chañaral",
      "Diego de Almagro",
      "Vallenar",
      "Alto del Carmen",
      "Freirina",
      "Huasco",
      "La Serena",
      "Coquimbo",
      "Andacollo",
      "La Higuera",
      "Paiguano",
      "Vicuña",
      "Illapel",
      "Canela",
      "Los Vilos",
      "Salamanca",
      "Ovalle",
      "Combarbalá",
      "Monte Patria",
      "Punitaqui",
      "Río Hurtado",
      "V Región",
      "Casablanca",
      "Concón",
      "Juan Fernández",
      "Puchuncaví",
      "Quintero",
      "Viña del Mar",
      "Isla de Pascua",
      "Los Andes",
      "Calle Larga",
      "Rinconada",
      "San Esteban",
      "La Ligua",
      "Cabildo",
      "Papudo",
      "Petorca",
      "Zapallar",
      "Quillota",
      "Calera",
      "Hijuelas",
      "La Cruz",
      "Nogales",
      "San Antonio",
      "Algarrobo",
      "Cartagena",
      "El Quisco",
      "El Tabo",
      "Santo Domingo",
      "San Felipe",
      "Catemu",
      "Llaillay",
      "Panquehue",
      "Putaendo",
      "Santa María",
      "Quilpué",
      "Limache",
      "Olmué",
      "Villa Alemana",
      "Rancagua",
      "Codegua",
      "Coinco",
      "Coltauco",
      "Doñihue",
      "Graneros",
      "Las Cabras",
      "Machalí",
      "Malloa",
      "Mostazal",
      "Olivar",
      "Peumo",
      "Pichidegua",
      "Quinta de Tilcoco",
      "Rengo",
      "Requínoa",
      "San Vicente",
      "Pichilemu",
      "La Estrella",
      "Litueche",
      "Marchihue",
      "Navidad",
      "Paredones",
      "San Fernando",
      "Chépica",
      "Chimbarongo",
      "Lolol",
      "Nancagua",
      "Palmilla",
      "Peralillo",
      "Placilla",
      "Pumanque",
      "Santa Cruz",
      "Talca",
      "Constitución",
      "Curepto",
      "Empedrado",
      "Maule",
      "Pelarco",
      "Pencahue",
      "Río Claro",
      "San Clemente",
      "San Rafael",
      "Cauquenes",
      "Chanco",
      "Pelluhue",
      "Curicó",
      "Hualañé",
      "Licantén",
      "Molina",
      "Rauco",
      "Romeral",
      "Sagrada Familia",
      "Teno",
      "Vichuquén",
      "Linares",
      "Colbún",
      "Longaví",
      "Parral",
      "Retiro",
      "San Javier",
      "Villa Alegre",
      "Yerbas Buenas",
      "Cobquecura",
      "Coelemu",
      "Ninhue",
      "Portezuelo",
      "Quirihue",
      "Ránquil",
      "Treguaco",
      "Bulnes",
      "Chillán Viejo",
      "Chillán",
      "El Carmen",
      "Pemuco",
      "Pinto",
      "Quillón",
      "San Ignacio",
      "Yungay",
      "Coihueco",
      "Ñiquén",
      "San Carlos",
      "San Fabián",
      "San Nicolás",
      "Concepción",
      "Coronel",
      "Chiguayante",
      "Florida",
      "Hualqui",
      "Lota",
      "Penco",
      "San Pedro de la Paz",
      "Santa Juana",
      "Talcahuano",
      "Tomé",
      "Hualpén",
      "Lebu",
      "Arauco",
      "Cañete",
      "Contulmo",
      "Curanilahue",
      "Los Álamos",
      "Tirúa",
      "Los Ángeles",
      "Antuco",
      "Cabrero",
      "Laja",
      "Mulchén",
      "Nacimiento",
      "Negrete",
      "Quilaco",
      "Quilleco",
      "San Rosendo",
      "Santa Bárbara",
      "Tucapel",
      "Yumbel",
      "Alto Biobío",
      "Temuco",
      "Carahue",
      "Cunco",
      "Curarrehue",
      "Freire",
      "Galvarino",
      "Gorbea",
      "Lautaro",
      "Loncoche",
      "Melipeuco",
      "Nueva Imperial",
      "Padre las Casas",
      "Perquenco",
      "Pitrufquén",
      "Pucón",
      "Saavedra",
      "Teodoro Schmidt",
      "Toltén",
      "Vilcún",
      "Villarrica",
      "Cholchol",
      "Angol",
      "Collipulli",
      "Curacautín",
      "Ercilla",
      "Lonquimay",
      "Los Sauces",
      "Lumaco",
      "Purén",
      "Renaico",
      "Traiguén",
      "Victoria",
      "Valdivia",
      "Corral",
      "Lanco",
      "Los Lagos",
      "Máfil",
      "Mariquina",
      "Paillaco",
      "Panguipulli",
      "La Unión",
      "Futrono",
      "Lago Ranco",
      "Río Bueno",
      "Puerto Montt",
      "Calbuco",
      "Cochamó",
      "Fresia",
      "Frutillar",
      "Los Muermos",
      "Llanquihue",
      "Maullín",
      "Puerto Varas",
      "Castro",
      "Ancud",
      "Chonchi",
      "Curaco de Vélez",
      "Dalcahue",
      "Puqueldón",
      "Queilén",
      "Quellón",
      "Quemchi",
      "Quinchao",
      "Osorno",
      "Puerto Octay",
      "Purranque",
      "Puyehue",
      "Río Negro",
      "San Juan de la Costa",
      "San Pablo",
      "Chaitén",
      "Futaleufú",
      "Hualaihué",
      "Palena",
      "Coihaique",
      "Lago Verde",
      "Aisén",
      "Cisnes",
      "Guaitecas",
      "Cochrane",
      "O’Higgins",
      "Tortel",
      "Chile Chico",
      "Río Ibáñez",
      "Punta Arenas",
      "Laguna Blanca",
      "Río Verde",
      "San Gregorio",
      "Cabo de Hornos (Ex Navarino)",
      "Antártica",
      "Porvenir",
      "Primavera",
      "Timaukel",
      "Natales",
      "Torres del Paine",
      "Cerrillos",
      "Cerro Navia",
      "Conchalí",
      "El Bosque",
      "Estación Central",
      "Huechuraba",
      "Independencia",
      "La Cisterna",
      "La Florida",
      "La Granja",
      "La Pintana",
      "La Reina",
      "Las Condes",
      "Lo Barnechea",
      "Lo Espejo",
      "Lo Prado",
      "Macul",
      "Maipú",
      "Ñuñoa",
      "Pedro Aguirre Cerda",
      "Peñalolén",
      "Providencia",
      "Pudahuel",
      "Quilicura",
      "Quinta Normal",
      "Recoleta",
      "Renca",
      "Santiago",
      "San Joaquín",
      "San Miguel",
      "San Ramón",
      "Vitacura",
      "Puente Alto",
      "Pirque",
      "San José de Maipo",
      "Colina",
      "Lampa",
      "Tiltil",
      "San Bernardo",
      "Buin",
      "Calera de Tango",
      "Paine",
      "Melipilla",
      "Alhué",
      "Curacaví",
      "María Pinto",
      "San Pedro",
      "Talagante",
      "El Monte",
      "Isla de Maipo",
      "Padre Hurtado",
      "Peñaflor",
    ];
    this.destinyList = ["Primera vivienda", "Segunda vivienda", "Inversión"];
    this.setApartments();
    if (this.isInvest) {
      this.quote.source = "INVEST";
    } else {
      this.quote.source = "WEB INMOBILIARIA";
    }
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show();
    },
    checkImagesToShow(apartment) {
      const images = [];
      if (apartment.urlImagenDelBienAComercializarUno) {
        images.push(apartment.urlImagenUno);
      }
      return images;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    filterBedroom: function (key) {
      this.bedroomFilterOption = key;
      this.goToAction("quotes");
    },
    filterBathroom: function (key) {
      this.bathroomFilterOption = key;
      this.goToAction("quotes");
    },
    goToAction(destiny) {
      var element = this.$refs[destiny];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    setTypeLabel(type) {
      return `Tipo ${type}`;
    },
    setApartmentLabel(apartment) {
      return `${apartment.tipoBien} ${apartment.numeroDeBien}`;
    },
    setAssetLabel(asset) {
      return `Nº ${asset.number}`;
    },
    getApartmentFromType(type) {
      return this.apartments.find((apartment) => apartment.tipo == type);
    },
    getMinPriceByType(type) {
      return Math.min.apply(
        Math,
        this.apartments
          .filter((apartment) => apartment.tipo == type)
          .map((obj) => obj.price)
      );
    },
    getApartment(apartmentId) {
      return this.apartments.find((apartment) => apartment.id == apartmentId);
    },
    getAsset(assetId) {
      let asset = this.apartments.find((asset) => asset.id == assetId);
      if (asset == null) {
        asset = this.cellars.find((asset) => asset.id == assetId);
      }
      if (asset == null) {
        asset = this.parkings.find((asset) => asset.id == assetId);
      }
      return asset;
    },
    changeSelectedSvg() {
      let self = this;
      document.querySelectorAll(".mapa-deptos").forEach(function (svgDoc) {
        let elements = svgDoc.querySelectorAll(".clickable-elements path");
        elements.forEach(function (type) {
          type.style.cursor = "pointer";
          if (
            type
              .getAttribute("data-type")
              .split(",")
              .includes(self.selectedApartment.tipo)
          ) {
            type.style.fill = "#8DC63F";
          } else {
            type.style.fill = "rgb(205,205,205)";
          }
        });
      });
    },
    setSvg() {
      let self = this;
      document.querySelectorAll(".mapa-deptos").forEach(function (svgDoc) {
        let elements = svgDoc.querySelectorAll(".clickable-elements path");
        elements.forEach(function (type) {
          type.style.cursor = "pointer";
          if (
            type
              .getAttribute("data-type")
              .split(",")
              .includes(self.selectedApartment.tipo)
          ) {
            type.style.fill = "#8DC63F";
          }
          type.addEventListener("click", function () {
            this.parentNode
              .querySelectorAll("path")
              .forEach(function (innerType) {
                innerType.style.fill = "rgb(205,205,205)";
              });
            let typeArray = type.getAttribute("data-type").split(",");
            self.setSelectedApartment(self.getApartmentFromType(typeArray[0]));
          });
        });
      });
    },
    sumPriceAssets(assets) {
      let sum = 0;
      assets.forEach((asset) => (sum += this.getAsset(asset).price));
      return sum;
    },
    filterByType(type) {
      if (type) {
        this.filteredApartments = this.apartments.filter(
          (apartment) => apartment.tipo == type
        );
        return this.setSelectedApartment(this.getApartmentFromType(type));
      } else {
        this.filteredApartments = this.apartments;
        this.selectedApartment = this.filteredApartments[0];
      }
    },
    filterData(apartment) {
      if (apartment) {
        if (
          (apartment.numeroDeBanos == this.selectedBathroom ||
            this.selectedBathroom == null) &&
          (apartment.numeroDeDormitorios == this.selectedRoom ||
            this.selectedRoom == null)
        ) {
          return true;
        } else {
          if (this.selectedApartment.id == apartment.id) {
            this.filteredApartments = this.apartments;
            if (this.selectedBathroom) {
              this.filteredApartments = this.filteredApartments.filter(
                (apartment) => apartment.numeroDeBanos == this.selectedBathroom
              );
            }
            if (this.selectedRoom) {
              this.filteredApartments = this.filteredApartments.filter(
                (apartment) =>
                  apartment.numeroDeDormitorios == this.selectedRoom
              );
            }
            this.setSelectedApartment(this.filteredApartments[0]);
          }
          return false;
        }
      } else {
        return false;
      }
    },
    setSelectedApartment(apartment) {
      if (this.filterData(apartment)) {
        this.selectedApartment = apartment;
        this.selectedType = apartment.tipo;
      }
      this.changeSelectedSvg();
    },
    digito(rut, dv) {
      let suma = 0;
      let multiplicador = 2;
      while (rut !== 0) {
        if (multiplicador === 8) {
          multiplicador = 2;
        }
        suma += (rut % 10) * multiplicador;
        rut = Math.trunc(rut / 10);
        multiplicador++;
      }
      suma = 11 - (suma % 11);
      switch (suma) {
        case 11:
          return "0" === dv;
        case 10:
          return "K" === dv.toUpperCase();
        default:
          return suma.toString() === dv;
      }
    },
    formatRut() {
      if (this.chilean) {
        if (this.quote.rut.length > 0) {
          this.quote.rut = this.quote.rut.replace(/[^\dKk]/g, "");
          let dato = this.quote.rut;
          let largo = dato.length;
          if (largo === 1) {
            this.dv = dato;
            this.quote.rut = "";
            this.digito(this.quote.rut, this.dv);
            this.formato();
          } else if (largo > 1) {
            this.dv = dato.substring(largo - 1);
            this.quote.rut = dato.substring(largo - 1, 0);
            this.digito(this.quote.rut, this.dv);
            this.formato();
          }
        }
      } else {
        return true;
      }
    },
    pluralOrSingularFilter(value, singular, plural) {
      return !isNaN(value) ? (value != 1 ? plural : singular) : "";
    },
    loadBedroomOptions() {
      this.apartments.forEach((apartment) => {
        if (
          !this.bedroomFilterOptions.some(
            (filter) => filter.value == apartment.numeroDeDormitorios
          )
        ) {
          apartment.numeroDeDormitorios != 0
            ? this.bedroomFilterOptions.push({
                text: apartment.numeroDeDormitorios,
                value: apartment.numeroDeDormitorios,
              })
            : null;
        }
        if (
          !this.bedroomFilterOptions.some(
            (filter) => filter.value == apartment.numeroDeDormitorios
          )
        ) {
          apartment.numeroDeDormitorios == 0
            ? this.bedroomFilterOptions.push({ text: "Taller", value: 0 })
            : null;
        }
      });
      this.bedroomFilterOptions.sort((a, b) => a.value - b.value);
    },
    loadBathroomOptions() {
      this.apartments.forEach((apartment) => {
        if (
          !this.bathroomFilterOptions.some(
            (filter) => filter.value == apartment.numeroDeBanos
          )
        ) {
          apartment.numeroDeBanos != 0
            ? this.bathroomFilterOptions.push({
                text: apartment.numeroDeBanos,
                value: apartment.numeroDeBanos,
              })
            : null;
        }
      });
      this.bathroomFilterOptions.sort((a, b) => a.value - b.value);
    },
    formato() {
      let rut = this.quote.rut
        ? new Intl.NumberFormat("es-CL").format(parseInt(this.quote.rut)) + "-"
        : "";
      this.quote.rut = rut + this.dv;
    },
    send() {
      let self = this;
      this.quote.assets = [this.selectedApartment.id];
      if (!this.excludedTypes.includes(this.selectedApartment.tipo)) {
        if (this.selectedParking) {
          this.quote.assets.push(this.selectedParking.id);
        }
        if (this.selectedCellar) {
          this.quote.assets.push(this.selectedCellar.id);
        }
      }
      this.quote.tracker = this.$cookies.get("mb-tracker");
      this.$v.quote.$touch();
      if (!this.$v.quote.$invalid) {
        this.loadingQuote = true;
        this.axios
          .post("quotes", {
            customer_id: "fortaleza",
            id: this.project.project_name,
            quote: this.quote,
          })
          .then((result) => {
            self.quoteResponse = result.data;
            self.loadingQuote = false;
            if (self.quoteResponse.id) {
              self.showResponse = true;
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "cotización",
                formLocation: self.project.name,
              });
            } else {
              self.quoteError = true;
            }
          })
          .catch(() => {
            self.loadingQuote = false;
          });
      }
    },
    checkPhone(number, data) {
      if (number.length > 0) {
        if (data.isValid) {
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.remove("is-invalid");
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.add("is-valid");
          this.quote.cellphone = data.number.e164;
        } else {
          this.quote.cellphone = null;
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.remove("is-valid");
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.add("is-invalid");
        }
      } else {
        this.quote.cellphone = null;
        document
          .getElementsByClassName("cellphone-input")[0]
          .classList.remove("is-valid");
        document
          .getElementsByClassName("cellphone-input")[0]
          .classList.remove("is-invalid");
      }
    },
    cleanModal() {
      this.showResponse = false;
      this.quote.assets = [this.selectedApartment];
      this.$v.quote.$reset();
    },
    calc(price) {
      let interest = 2.2 / 100;
      let months = 12;
      let totalPeriod = 30 * months;
      let vtim = Math.pow(1 + interest, 1 / months) - 1;
      let x = Math.pow(1 + vtim, totalPeriod);
      let calc = price * ((x * vtim) / (x - 1));
      return Math.round((calc * this.uf * 100) / 100);
    },
    setApartments() {
      this.excludedTypes = [];
      this.quote.project_name = this.project.project_name;
      this.quote.project_id = this.project.project_id;
      let self = this;
      this.$store.state.loading = true;
      if (this.project.actual_state != "Realizado")
        this.axios
          .get("/quotes/" + this.project.project_id + "/get_assets")
          .then((response) => {
            self.apartments = response.data.assets;
            self.complementaries = response.data.complementary;
            self.cellars = self.complementaries.storage;
            self.parkings = self.complementaries.parking;
            self.apartmentTypes = self.apartments
              .map((obj) => obj.tipo)
              .filter((elem, pos, arr) => arr.indexOf(elem) == pos)
              .sort();
            self.selectedType = self.apartmentTypes[0];
            self.selectedApartment = self.getApartmentFromType(
              this.selectedType
            );
            self.filteredApartments = self.apartments.filter(
              (apartment) => apartment.tipo == this.selectedType
            );
            let rooms = self.apartments
              .map((obj) => obj.numeroDeDormitorios)
              .filter((elem, pos, arr) => arr.indexOf(elem) == pos)
              .sort();
            let bathrooms = self.apartments
              .map((obj) => obj.numeroDeBanos)
              .filter((elem, pos, arr) => arr.indexOf(elem) == pos)
              .sort();
            self.rooms = rooms.map(function (obj) {
              return {
                value: obj,
                text: obj + " DORMITORIO" + (obj > 1 ? "S" : ""),
              };
            });
            self.bathrooms = bathrooms.map(function (obj) {
              return { value: obj, text: obj + " BAÑO" + (obj > 1 ? "S" : "") };
            });
            self.$store.state.loading = false;
          });
    },
  },
  computed: {
    dividend() {
      // 30 años y tasa de interés de 2,2
      return this.calc(this.selectedApartment.price);
    },
    uf() {
      return this.$store.getters.getUf;
    },
    windowSize() {
      return window.innerWidth;
    },
    apartmentsFiltered() {
      let filtered = [];
      this.apartments.filter((apartment) => {
        // if(apartment.tipoBien == "DEPARTAMENTO"){
        if (
          this.bedroomFilterOption == "all" &&
          this.bathroomFilterOption == "all"
        ) {
          filtered.push(apartment);
          return filtered;
        } else if (
          this.bedroomFilterOption == apartment.numeroDeDormitorios &&
          this.bathroomFilterOption == apartment.numeroDeBanos
        ) {
          filtered.push(apartment);
          return filtered;
        } else if (
          this.bedroomFilterOption == apartment.numeroDeDormitorios &&
          this.bathroomFilterOption == "all"
        ) {
          filtered.push(apartment);
          return filtered;
        } else if (
          this.bedroomFilterOption == "all" &&
          this.bathroomFilterOption == apartment.numeroDeBanos
        ) {
          filtered.push(apartment);
          return filtered;
        }
        // }
      });
      let types = filtered
        .map((obj) => obj.tipo)
        .filter((elem, pos, arr) => arr.indexOf(elem) == pos)
        .sort();
      return types.map((type) =>
        filtered.find((apartment) => apartment.tipo == type)
      );
    },
  },
  watch: {
    project: function () {
      this.setApartments();
      this.quote.project_name = this.project.project_name;
      this.quote.project_id = this.project.project_id;
    },
    apartments(newValue) {
      if (newValue) {
        this.loadBedroomOptions();
        this.loadBathroomOptions();
      }
    },
  },
};
export function isRutValid(value) {
  if (value == null) return false;
  if (value.length === 0) return false;
  var input = value.replace(/[^\dKk]/g, "");
  let dato = input;
  let largo = dato.length;
  if (largo === 1) {
    let dv = dato;
    let rut = "";
    return digito(rut, dv);
  } else if (largo > 1) {
    let dv = dato.substring(largo - 1);
    let rut = dato.substring(largo - 1, 0);
    return digito(rut, dv);
  } else {
    return false;
  }

  function digito(rut, dv) {
    let suma = 0;
    let multiplicador = 2;
    while (rut !== 0) {
      if (multiplicador === 8) {
        multiplicador = 2;
      }
      suma += (rut % 10) * multiplicador;
      rut = Math.trunc(rut / 10);
      multiplicador++;
    }
    suma = 11 - (suma % 11);
    switch (suma) {
      case 11:
        return "0" === dv;
      case 10:
        return "K" === dv.toUpperCase();
      default:
        return suma.toString() === dv;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.clickable-elements path:hover {
  fill: #8ec63fcb !important;
}
.quote-buttons {
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card-quote {
  background-color: #f8f9fa !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 19px 0px;
}
.project-main-image {
  cursor: pointer;
  height: 310px;
  background-color: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: white;
}
.project-main-image-carousel {
  cursor: pointer;
  height: 310px;
  background-color: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: white;
}
.project-main-image-carousel-quote {
  height: 350px;
  background-color: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: white;
}
.project-main-image-carousel img {
  object-fit: contain;
}
.not-found {
  font-size: 40px;
  padding: 50px 0px 0px 0px;
  line-height: 100%;
}
.project-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  /* or 133% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.project-attribute {
  font-family: "Neutra Text TF";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* or 143% */
  letter-spacing: 0.2px;
}
.last-units {
  position: absolute;
  right: 0;
  padding: 10px;
  background-color: $grey;
}
.last-units-text {
  font-family: "Gill Sans" !important;
  font-weight: 600;
}
.filter {
  font-family: "Gill Sans" !important;
  transition: 0.1s ease-in-out;
}
.green-square {
  width: 30px;
}
.bedroom-filters {
  border: 1px solid #54565a;
}
.filter-attribute {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 25px;
}
.active-filter {
  padding: 25px;
  background-color: #8bcb77;
  transition: 0.1s ease-in-out;
}
@media (max-width: 992px) {
  .filter {
    text-align: center;
  }
  .surfaces-container {
    height: 325px !important;
  }
}
.surfaces-container {
  height: 250px;
}
</style>
