<template>
  <div>
    <Navbar v-if="header" />
    <CampaignsHeader type="Header" v-if="header" :title="header.title" :italicTitle="header.italic_title" :subtitle="header.subtitle" color="#434041" :text_color="header.text_color" :logo="true"/>
    <div v-if="header && campaigns">
      <CampaignCards :campaigns="campaigns" />
    </div>
    <div v-else-if="!campaigns && header">
      <Loading text="Cargando oportunidades..." />
    </div>
    <Quotation v-if="selectedOpportunity" :opportunity="selectedOpportunity" :project="selectedProject" />
    <Footer v-if="header" />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import CampaignsHeader from "@/components/Campaigns/CampaignsHeader"
import CampaignCards from "@/components/Campaigns/CampaignCards"
import Loading from "@/components/Loading"
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'Opportunities',
  components: {
    CampaignsHeader,
    CampaignCards,
    Loading,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      header: null,
      campaigns: null
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'Opportunities');
    this.$store.commit('setCampaigns');
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    },
    '$store.state.campaigns': {
      handler: function()  {
        this.campaigns = this.$store.getters.getCampaigns;
        this.loading = false;
      }
    }
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
  },
}
</script>

<style scoped lang="scss">
</style>
