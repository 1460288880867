var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pilots",staticClass:"py-4"},[(_vm.pilots)?_c('b-container',{staticClass:"py-5"},[_c('h3',{staticClass:"pilots-title text-left mt-3"},[_vm._v("Galería")]),_c('b-row',[_c('b-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"12","xl":"6"}},[_c('div',{staticClass:"pilots-description-box"},[(_vm.description)?_c('p',{staticClass:"pilots-subtitle"},[_vm._v(_vm._s(_vm.description))]):_vm._e()])]),_c('b-col',{staticClass:"mt-4 mt-md-4 mt-xl-0",attrs:{"cols":"12","md":"12","xl":"6"}},[(_vm.pilots)?_c('b-carousel',{ref:"carouselPilots",attrs:{"id":"carouselPilots","background":"#ababab","no-hover-pause":true,"no-touch":true,"interval":0},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.pilots),function(pilot,key){return _c('b-carousel-slide',{key:'pilot' + key,scopedSlots:_vm._u([(pilot.iframe.includes('amazonaws'))?{key:"img",fn:function(){return [_c('div',{staticClass:"w-100 pilot-img cursor-pointer",style:({
                  backgroundImage:
                    'url(' +
                    (pilot.image_url != '/images/original/missing.png'
                      ? pilot.image_url
                      : pilot.iframe) +
                    ')',
                  backgroundPositionX: pilot.iframe_position_x,
                  backgroundPositionY: pilot.iframe_position_y,
                }),on:{"click":function($event){_vm.showSingle(_vm.checkImagesToShow(_vm.pilots, pilot))}}})]},proxy:true}:{key:"img",fn:function(){return [_c('iframe',{staticClass:"w-100 pilot-img",staticStyle:{"display":"none"},attrs:{"onload":"this.style.display='block';","scrolling":"no","src":pilot.iframe}})]},proxy:true}],null,true)})}),1):_vm._e(),(_vm.pilots.length > 1)?_c('b-row',{staticClass:"justify-content-center mt-4"},[_c('b-col',{attrs:{"cols":"2","xl":"2"}},[_c('div',{on:{"click":function($event){return _vm.$refs.carouselPilots.prev()}}},[_c('b-img',{staticClass:"pilots-arrows",attrs:{"src":require('@/assets/images/projects/details/angle-left-solid.svg')}})],1)]),_c('b-col',{attrs:{"cols":"2","xl":"2"}},[_c('div',{on:{"click":function($event){return _vm.$refs.carouselPilots.next()}}},[_c('b-img',{staticClass:"pilots-arrows",attrs:{"src":require('@/assets/images/projects/details/angle-right-solid.svg')}})],1)])],1):_vm._e()],1)],1)],1):_vm._e(),_c('vue-easy-lightbox',{attrs:{"escDisabled":"","moveDisabled":"","visible":_vm.visible,"imgs":_vm.imgs,"index":_vm.index},on:{"hide":_vm.handleHide}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }