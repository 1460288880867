<template>
  <div class="py-5">
    <b-container>
      <div class="seal-background dark-background p-4">
        <b-row class="justify-content-center">
          <b-col cols="12" md="2" xl="2" class="seal-logo text-center">
            <b-img class="pb-4 pt-md-0 pt-xl-0" :src="logo"></b-img>
          </b-col>
          <b-col cols="12" md="7" xl="7" class="my-auto px-3 px-md-5 px-xl-5">
            <p class="seal-description pt-4 pt-md-0 pt-xl-0 mb-0">Cada vez que veas el sello de <b>Life</b>, sabrás que es mejor para ti y el medioambiente</p>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require('@/assets/images/life/life.svg')
    }
  },
}
</script>

<style scoped lang="scss">
@media (min-width:992px) {
  .seal-logo{
    border-right: 1px dashed white;
  }
}
.seal-description{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1px;
  color: white
}
.seal-background{
  border-radius: 30px;
}
</style>
