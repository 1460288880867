<template>
  <div class="py-5 light-grey-background">
    <b-container class="text-center">
      <h2 class="referral-title">¿Tú amigo no quiere pagar más arriendo? <br> ¿Tus padres quieren un espacio más pequeño? <br> ¿Tu amiga necesita más espacio?</h2>
      <h4 class="referral-subtitle mt-5 bolder">Recomienda entre tus conocidos uno de nuestros proyectos y <br> GANA UNA GIFT CARD DE $1.000.000</h4>
      <div ref="referralForm">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="!submitted">
          <b-row class="text-left justify-content-center mt-5">
            <b-col cols="12" xl="8">
              <div>
                <p class="bolder">Si eres propietario, ingresa tus datos aquí:</p>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.client_names.$model" :state="$v.referral.client_names.$dirty ? !$v.referral.client_names.$error : null" placeholder="Nombre y Apellido"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.client_names.$error">* Nombres y Apellido requeridos</p>
                </div>
                <div class="text-left mt-3" v-if="projects">
                  <b-form-select v-model="$v.referral.client_project.$model" :options="projectsOptions" :state="$v.referral.client_project.$dirty ? !$v.referral.client_project.$error : null"></b-form-select>
                  <p class="mt-3 text-danger" v-if="$v.referral.client_project.$error">* Ingrese el proyecto donde compró</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.client_email.$model" :state="$v.referral.client_email.$dirty ? !$v.referral.client_email.$error : null" placeholder="Email"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.client_email.$error">* Ingrese su email correctamente</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model="$v.referral.client_phone.$model" :state="$v.referral.client_phone.$dirty ? !$v.referral.client_phone.$error : null" placeholder="Teléfono"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.client_phone.$error">* Ingrese su teléfono correctamente</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.client_rut.$model" @keyup="formatRut()" :state="$v.referral.client_rut.$dirty ? !$v.referral.client_rut.$error : null" placeholder="Rut"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.client_rut.$error">* Rut requerido</p>
                </div>
              </div>
              <div class="mt-5">
                <p class="bolder">¿A quién recomendarás? Ingresa sus datos aquí:</p>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.referral_names.$model" :state="$v.referral.referral_names.$dirty ? !$v.referral.referral_names.$error : null" placeholder="Nombre y Apellido"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.referral_names.$error">* Nombres y Apellido requeridos</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.referral_email.$model" :state="$v.referral.referral_email.$dirty ? !$v.referral.referral_email.$error : null" placeholder="Email"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.referral_email.$error">* Ingrese su email correctamente</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model="$v.referral.referral_phone.$model" :state="$v.referral.referral_phone.$dirty ? !$v.referral.referral_phone.$error : null" placeholder="Teléfono"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.referral_phone.$error">* Ingrese el teléfono correctamente</p>
                </div>
                <div class="text-left mt-3">
                  <b-form-input v-model.trim="$v.referral.referral_rut.$model" @keyup="formatRutReferral()" :state="$v.referral.referral_rut.$dirty ? !$v.referral.referral_rut.$error : null" placeholder="Rut"></b-form-input>
                  <p class="mt-3 text-danger" v-if="$v.referral.referral_rut.$error">* Rut requerido</p>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="12" xl="8">
              <b-button class="mt-5" variant="referral" @click="sendReferral()" :disabled="$v.$invalid">Enviar Datos</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="submitted && !formError">
          <b-row class="justify-content-center py-5">
            <b-col cols="12" xl="8">
              <h2 class="bolder">¡Gracias por referir a tu amigo! <br> si quieres puedes seguir refiriendo.</h2>
              <b-button class="mt-5" variant="referral" @click="resetReferral()">Referir otro amigo</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row class="justify-content-center py-5">
            <b-col cols="12" xl="8">
              <h2 class="bolder">Ha ocurrido un error al enviar el formulario <br> si quieres puedes intentarlo de nuevo.</h2>
              <b-button class="mt-5" variant="referral" @click="resetReferral()">Intentar de nuevo</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </div>
    </b-container>
  </div>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      dv: '',
      dvReferral: '',
      submitted: false,
      formError: false,
      loading: false,
      referral: {
        client_names: '',
        client_email: '',
        client_phone: '',
        client_rut: '',
        client_project: null,
        referral_names: '',
        referral_email: '',
        referral_phone: '',
        referral_rut: '',
      },
      projectsOptions: [
        { value: null, text: 'Proyecto donde compraste' },
      ],
    }
  },
  validations: {
    referral: {
      client_names: { required },
      client_email: { required, email },
      client_phone: { required, minLength: minLength(9) },
      client_rut: { required, RutValid: isRutValid, minLength: minLength(8) },
      client_project: { required },
      referral_names: { required },
      referral_email: { required, email },
      referral_phone: { required, minLength: minLength(9) },
      referral_rut: { required, RutValid: isRutValid, minLength: minLength(8) },
    },
  },
  mounted () {
    if(this.projects != null){
      this.projects.map(project => {
        project.actual_state != 'Realizado' ? this.projectsOptions.push({value: project.project_name, text: project.name}) : null;
      });
    }
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    }
  },
  watch: {
    projects(newValue) {
      if(newValue){
        this.projects.map(project => {
          project.actual_state != 'Realizado' ? this.projectsOptions.push({value: project.project_name, text: project.name}) : null;
        });
      }
    }
  },
  methods: {
    sendReferral() {
      this.$v.referral.$touch()
      if(!this.$v.referral.$invalid){
        this.loading = true;
        this.axios.post("referrals", {referral: this.referral}).then(result => {
          if(result.data.status === 'SUCCESS'){
            this.loading = false
            this.submitted = true;
            this.goTo("referralForm");
          } else {
            this.loading = false;
            this.formError = true;
            this.goTo("referralForm");
          }
        }).catch((e) => {
          console.log(e);
          self.loading = false;
        });
      }
    },
    resetReferral(){
      this.submitted = false;
      this.formError = false;
      this.referral.referral_names = '';
      this.referral.referral_email = '';
      this.referral.referral_phone = '';
      this.referral.referral_rut = '';
      this.$v.referral.referral_names.$reset();
      this.$v.referral.referral_email.$reset();
      this.$v.referral.referral_phone.$reset();
      this.$v.referral.referral_rut.$reset();
      this.goTo("referralForm");
    },
    goTo(destiny) {
      var element = this.$refs[destiny];
      console.log(element);
      var headerOffset = 250;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    digito(rut, dv) {
      let suma = 0;
      let multiplicador = 2;
      while (rut !== 0) {
        if (multiplicador === 8) {
          multiplicador = 2;
        }
        suma += (rut % 10) * multiplicador;
        rut = Math.trunc(rut / 10);
        multiplicador++;
      }
      suma = 11 - (suma % 11);
      switch (suma) {
        case 11:
          return ("0" === dv);
        case 10:
          return ("K" === dv.toUpperCase());
        default:
          return (suma.toString() === dv);
      }
    },
    formatRut(){
      if(this.referral.client_rut.length > 0){
        this.referral.client_rut = this.referral.client_rut.replace(/[^\dKk]/g, '');
        let dato = this.referral.client_rut;
        let largo = dato.length;
        if (largo === 1) {
          this.dv = dato;
          this.referral.client_rut = '';
          this.digito(this.referral.client_rut, this.dv);
          this.formato()
        } else if (largo > 1) {
          this.dv = dato.substring(largo - 1);
          this.referral.client_rut = dato.substring(largo - 1, 0);
          this.digito(this.referral.client_rut, this.dv);
          this.formato()
        }
      }
    },
    formato() {
      let rut = (this.referral.client_rut) ? (new Intl.NumberFormat("es-CL").format(parseInt(this.referral.client_rut))) + '-' : '';
      this.referral.client_rut = rut + this.dv;
    },
    formatRutReferral(){
      if(this.referral.referral_rut.length > 0){
        this.referral.referral_rut = this.referral.referral_rut.replace(/[^\dKk]/g, '');
        let dato = this.referral.referral_rut;
        let largo = dato.length;
        if (largo === 1) {
          this.dvReferral = dato;
          this.referral.referral_rut = '';
          this.digito(this.referral.referral_rut, this.dvReferral);
          this.formatoReferral()
        } else if (largo > 1) {
          this.dvReferral = dato.substring(largo - 1);
          this.referral.referral_rut = dato.substring(largo - 1, 0);
          this.digito(this.referral.referral_rut, this.dvReferral);
          this.formatoReferral()
        }
      }
    },
    formatoReferral() {
      let rut = (this.referral.referral_rut) ? (new Intl.NumberFormat("es-CL").format(parseInt(this.referral.referral_rut))) + '-' : '';
      this.referral.referral_rut = rut + this.dvReferral;
    },
  },
}
export function isRutValid(value) {
  if (value == null) return false;
  if (value.length === 0) return false;
  var input = value.replace(/[^\dKk]/g, '');
  let dato = input;
  let largo = dato.length;
  if (largo === 1) {
    let dv = dato;
    let rut = '';
    return digito(rut, dv);
  } else if (largo > 1) {
    let dv = dato.substring(largo - 1);
    let rut = dato.substring(largo - 1, 0);
    return digito(rut, dv);
  } else {
    return false;
  }

  function digito(rut, dv) {
    let suma = 0;
    let multiplicador = 2;
    while (rut !== 0) {
      if (multiplicador === 8) {
        multiplicador = 2;
      }
      suma += (rut % 10) * multiplicador;
      rut = Math.trunc(rut / 10);
      multiplicador++;
    }
    suma = 11 - (suma % 11);
    switch (suma) {
      case 11:
        return ("0" === dv);
      case 10:
        return ("K" === dv.toUpperCase());
      default:
        return (suma.toString() === dv);
    }
  }
}
</script>
<style lang="scss" scoped>
.referral-title{
  font-size: 24px;
  color:#68696B
}
.referral-subtitle{
  font-size: 20px;
}
.btn-referral{
  background-color: #75AD63;
  color:white;
  padding: 10px 25px;
}
</style>
