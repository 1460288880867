<template>
  <div class="py-5">
    <b-container>
      <b-row class="green-box py-5" style="position:relative">
        <b-img class="mx-1" style="width:45px;position:absolute;top:-1px;left:-7px" :src="greenSquare"></b-img>
        <b-col cols="12" v-for="(basis, key) in bases" :key="'basis' + key" class="text-center">
          <p class="mb-1">{{basis.text}}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CampaignBases',
  props: {
    bases: {
      type: Array,
    },
  },
  data() {
    return {
      greenSquare: require('@/assets/images/projects/features/green-square.svg')
    }
  },
}
</script>

<style lang="scss" scoped>
.green-box{
  border:1px solid green
}
</style>
