<template>
  <div>
    <Navbar v-if="header" />
    <Header
      type="Header"
      v-if="header"
      :title="header.title"
      :italicTitle="header.italic_title"
      :subtitle="header.subtitle"
      color="#434041"
      :text_color="header.text_color"
      :isotype="header.isotype_url"
      :showImage="header.show_image"
      :showMobileImage="header.show_mobile_image"
      :showIsotype="header.show_isotype"
      :image="header.image_url"
      :imageMobile="header.mobile_image_url"
    />
    <div v-if="header && opportunities">
      <ApartmentCards
        :apartments="opportunities"
        @openQuote="quoteAction"
        @openReservation="quoteReservation"
      />
    </div>
    <div v-else-if="!opportunities && header">
      <Loading text="Cargando oportunidades..." />
    </div>
    <Quotation
      v-if="selectedOpportunity"
      :opportunity="selectedOpportunity"
      :project="selectedProject"
      :is-reservation="isReservation"
    />
    <Footer v-if="header" />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
// import OfficeCards from "@/components/OfficeCards"
import ApartmentCards from "@/components/ApartmentCards";
import Loading from "@/components/Loading";
import Quotation from "@/components/Quotation";
import ContactModal from "@/components/ContactModal.vue";
export default {
  name: "Opportunities",
  components: {
    Header,
    // OfficeCards,
    ApartmentCards,
    Loading,
    Navbar,
    Footer,
    Quotation,
    ContactModal,
  },
  data() {
    return {
      header: null,
      opportunities: null,
      selectedOpportunity: null,
      selectedProject: null,
      isReservation: false,
    };
  },
  mounted() {
    this.$store.commit("setHeader", "Opportunities");
    this.$store.commit("setOpportunities");
  },
  methods: {
    quoteAction(selectedOpportunity, selectedProject) {
      this.isReservation = false;
      this.selectedOpportunity = selectedOpportunity;
      this.selectedProject = selectedProject;
    },
    quoteReservation(selectedOpportunity, selectedProject) {
      this.isReservation = true;
      this.selectedOpportunity = selectedOpportunity;
      this.selectedProject = selectedProject;
    },
  },
  watch: {
    "$store.state.header": {
      handler: function () {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      },
    },
    "$store.state.opportunities": {
      handler: function () {
        this.opportunities = this.$store.getters.getOpportunities;
        this.loading = false;
      },
    },
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
  },
};
</script>

<style scoped lang="scss"></style>
