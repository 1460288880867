<template>
  <div>
    <div
      class="project-header-img py-5 px-2 px-xl-0"
      :style="[
        image && showImage && windowSize > 992
          ? { backgroundImage: ` url(${image})` }
          : '',
        imageMobile && showMobileImage && windowSize < 992
          ? { backgroundImage: ` url(${imageMobile})` }
          : '',
        { backgroundColor: `${color}` },
      ]"
    >
      <b-container class="h-100">
        <b-row class="header-image-container h-100 main-row" align-v="center">
          <b-col
            cols="12"
            xl="12"
            class="text-left px-4 px-xl-3 py-2"
            :class="{ 'header-border-mobile': $route.name == 'ProjectDetail' }"
            :style="`color:${text_color}`"
          >
            <b-row v-if="!projectHeader">
              <b-col cols="8" xl="8" class="my-auto">
                <b-img
                  v-if="life"
                  :src="require('@/assets/images/life/life.svg')"
                ></b-img>
                <p class="text-justify mb-0" v-if="categories">
                  <span
                    v-for="(category, key) in categories"
                    :key="'category' + key"
                    >{{ category.name
                    }}<span v-if="key != Object.keys(categories).length - 1"
                      >,
                    </span></span
                  >
                </p>
                <h1 v-if="!life && title" class="header-title my-auto">
                  {{ title }}
                </h1>
                <h1
                  v-if="!life && italicTitle"
                  class="header-title-italic my-auto"
                >
                  {{ italicTitle | truncate(56, "...") }}
                </h1>
                <p class="text-justify" v-if="autor">{{ autor }}</p>
                <p
                  v-if="subtitle"
                  class="text-justify py-4 header-subtitle mb-0"
                  :class="life ? 'text-white' : 'text-black'"
                >
                  {{ subtitle | truncate(240, "...") }}
                </p>
              </b-col>
              <b-col
                offset-xl="1"
                xl="3"
                class="header-fortaleza-logo my-auto"
                v-if="isotype && showIsotype && !life"
              >
                <b-img class="ml-auto w-100" :src="isotype"></b-img>
                <!-- <b-img class="ml-auto w-100" v-if="us" :src="require('@/assets/images/about/us.svg')"></b-img>
                <b-img class="ml-auto w-100" v-else :src="require('@/assets/images/header/logo.svg')"></b-img> -->
              </b-col>
            </b-row>
            <b-row v-else class="green-background pr-xl-5 py-3 py-xl-5">
              <b-img
                @click="showSingle(projectNameImage)"
                class="project-name-image cursor-pointer d-none d-md-none d-xl-block"
                :src="projectNameImage"
              ></b-img>
              <b-col xl="5" offset-xl="1" class="my-auto">
                <b-img
                  @click="showSingle(projectNameImage)"
                  class="d-block d-md-block d-xl-none cursor-pointer"
                  style="width: 100px"
                  :src="projectNameImage"
                ></b-img>
                <p class="project-commune pt-3 d-block d-xl-none">
                  <b-img class="mr-2" :src="icons.map"></b-img
                  >{{
                    projectNeighborhood ? projectNeighborhood : projectCommune
                  }}
                </p>
                <h1
                  v-if="title"
                  class="project-header-title my-auto pt-3 pt-xl-5"
                  v-html="title"
                ></h1>
                <h1 v-if="title" class="project-header-italic-title my-auto">
                  {{ italicTitle }}
                </h1>
                <p v-if="subtitle" class="project-header-subtitle mt-3">
                  {{ subtitle }}
                </p>
              </b-col>
              <b-col xl="5">
                <!-- Desktop Main Image -->
                <b-img
                  class="project-main-image d-none d-md-none d-xl-block"
                  :src="projectImages[0].image_url"
                ></b-img>
                <b-img
                  v-if="showBlackSeal"
                  class="d-none d-md-none d-xl-block"
                  style="position: absolute; left: 20px; top: 0; width: 75px"
                  :src="require('@/assets/images/projects/features/seal.png')"
                ></b-img>
                <b-img
                  v-if="showNewProjectSeal"
                  class="d-none d-md-none d-xl-block"
                  style="position: absolute; right: 15px; top: 0; width: 110px"
                  :src="require('@/assets/images/projects/new-project.svg')"
                >
                </b-img>
                <!-- Mobile Images -->
                <b-row class="d-flex d-md-flex d-xl-none w-100">
                  <b-col
                    v-for="(image, key) in projectImages"
                    :key="'image' + key"
                    cols="6"
                    md="6"
                    class="w-100"
                  >
                    <b-img
                      class="w-100 h-100 pt-xl-0"
                      :class="{ 'pt-3': key > 1 }"
                      :src="image.image_url"
                    ></b-img>
                    <b-img
                      v-if="showBlackSeal && key === 0"
                      style="
                        position: absolute;
                        left: 20px;
                        top: 0;
                        width: 110px;
                      "
                      :src="
                        require('@/assets/images/projects/features/seal.png')
                      "
                    ></b-img>
                    <b-img
                      v-else-if="showNewProjectSeal && key === 0"
                      style="
                        position: absolute;
                        right: 15px;
                        top: 0;
                        width: 110px;
                      "
                      :src="require('@/assets/images/projects/new-project.svg')"
                    >
                    </b-img>
                  </b-col>
                </b-row>
                <p class="project-commune pt-3 d-none d-xl-block">
                  <b-img class="mr-2" :src="icons.map"></b-img
                  >{{
                    projectNeighborhood ? projectNeighborhood : projectCommune
                  }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <div
            v-if="projectHeader"
            class="project-images-absolute text-right d-none d-md-none d-xl-block"
          >
            <div
              class="project-images-div mb-3"
              xl="12"
              v-for="(image, key) in projectImages.slice(1, 4)"
              :key="'image' + key"
              :class="key == 0 ? 'mt-0' : 'mt-3'"
            >
              <div class="project-images-green-border"></div>
              <b-img
                class="ml-auto text-right project-images"
                :src="image.image_url"
              ></b-img>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
    <!-- all props & events -->
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "Header",
  components: {
    VueEasyLightbox,
  },
  props: {
    autor: { type: String },
    categories: { type: Array },
    project: { type: Boolean },
    image: { type: String },
    imageMobile: { type: String },
    title: { type: String },
    subtitle: { type: String },
    building_type: { type: String },
    life: { type: Boolean, default: false },
    us: { type: Boolean, default: false },
    fortalezaLogo: { type: Boolean, default: false },
    isotype: { type: String },
    text_color: { type: String },
    color: { type: String },
    projectHeader: { type: Boolean, default: false },
    projectNameImage: { type: String },
    projectImages: { type: Array },
    italicTitle: { type: String },
    projectCommune: { type: String },
    projectNeighborhood: { type: String },
    showImage: { type: Boolean },
    showMobileImage: { type: Boolean },
    showIsotype: { type: Boolean },
    showBlackSeal: { type: Boolean, default: false },
    showNewProjectSeal: { type: Boolean, default: false },
  },
  data() {
    return {
      index: 0, // default: 0
      visible: false,
      imgs: "", // Img Url , string or Array of string
      slide: null,
      icons: {
        map: require("@/assets/images/invest/project-list/map.svg"),
      },
    };
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show();
    },
    checkImagesToShow(index) {
      const images = [];
      this.projectImages.forEach((image) => {
        if (image.image_url) {
          console.log(image.image_url);
          images.push(image.image_url);
        }
      });
      this.index = index;
      return images;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  computed: {
    windowSize() {
      return window.innerWidth;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-title {
  font-weight: 500;
  line-height: 36px;
}
.header-title-italic {
  font-weight: 700;
  line-height: 56px;
  font-style: italic;
}
.header-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  /* or 144% */

  letter-spacing: 0.3px;
}
.project-header-img {
  height: 65vh;
  background-color: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project-header-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* Light / Black */

  color: #373f41;
}
.project-commune {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  text-transform: uppercase;

  color: #54565a;
}
.project-header-italic-title {
  font-family: Montserrat;
  font-size: 28px;
  line-height: 32px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
}
.project-header-subtitle {
  font-family: Neutra Text TF;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.header-fortaleza-logo {
  text-align: right;
}
.green-background {
  background-color: #b6c1b9;
  position: relative;
}
.project-main-image {
  width: 100%;
  height: 300px;
}
.project-images-div {
  position: relative;
}
.project-images-green-border {
  position: absolute;
  background-color: #62ae59;
  width: 5px;
  height: 100%;
  right: 0%;
}
.project-images {
  width: 192px;
  height: 104px;
}
.project-images-absolute {
  position: absolute;
  right: -50px;
}
.project-name-image {
  position: absolute;
  left: -50px;
  width: 100px;
}
.header-image-container {
  min-height: 25vh;
}
// Mobile
@media (max-width: 992px) {
  .header-title {
    font-size: 25px;
    line-height: 36px;
  }
  .header-title-italic {
    font-size: 25px;
    line-height: 36px;
  }
  .header-subtitle {
    font-size: 15px;
    line-height: 20px;
  }
  .project-header-img {
    height: 95vh;
  }
  .header-fortaleza-logo {
    text-align: center;
  }
  .header-border-mobile {
    border: 1px solid black;
  }
  .project-header-img {
    height: 100%;
    padding: 25px 10px !important;
  }
}
// Desktop
@media (min-width: 992px) {
  .main-row {
    position: relative;
  }
  .project-header-img {
    height: 100% !important;
  }
}
</style>
