<template>
  <div class="py-5">
    <b-container ref="cards">
      <b-row v-if="useBedroomsFilter">
        <b-col cols="12" xl="5" class="my-auto">
          <h3 class="projects-cards-title">Proyectos actuales</h3>
          <p class="projects-cards-subtitle mt-4">
            Descubre tu futuro hogar. ¿Dónde te gustaría vivir?
          </p>
          <div>
            <a
              href="https://fortaleza-resources.s3.amazonaws.com/projects/digital_brochures/brochure_global.pdf"
              target="_blank"
              download
              class="btn mx-auto btn-grey-border px-4 py-3 my-2"
              >Brochure Digital</a
            >
          </div>
        </b-col>
        <b-col cols="12" xl="7" class="my-auto">
          <b-container class="projects-cards-filters-box p-4 p-xl-2 py-xl-5">
            <b-row class="justify-content-center">
              <b-col
                cols="6"
                md="auto"
                xl="auto"
                v-for="(option, key) in filterOptions"
                :key="'filter' + key"
              >
                <p
                  class="mouse-click filter mb-0"
                  :class="{ 'active-filter': filterOption === option.value }"
                  @click="filter(option.value), goTo('cards')"
                >
                  {{ option.text }}
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <p v-if="useBedroomsFilter" class="mb-0 mt-5 mt-md-0 mt-xl-0">
        Filtrando por:
        <span class="bolder">{{
          filterOption != "all" ? filterOption + " Dormitorios" : "Todos"
        }}</span>
      </p>
      <b-row v-if="useCommuneFilter">
        <b-col>
          <b-container class="projects-cards-filters-box p-4 p-xl-2 py-xl-5">
            <b-row class="justify-content-center">
              <b-col
                cols="auto"
                md="auto"
                xl="auto"
                v-for="(option, key) in communeOptions"
                :key="'filter' + key"
              >
                <p
                  class="mouse-click filter"
                  :class="{ 'active-filter': communeOption === option.value }"
                  @click="filter(option.value), goTo('cards')"
                >
                  {{ option.text }}
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row
        v-if="filteredProjects && filteredProjects.length > 0"
        class="mt-5 text-left"
      >
        <b-col
          cols="12"
          md="12"
          xl="4"
          v-for="(project, key) in filteredProjects"
          :key="'project' + key"
          :class="key > 2 ? 'mt-5' : 'mt-3'"
        >
          <router-link
            v-if="!accomplishedProjects"
            :to="{
              name: 'ProjectDetail',
              params: { project_id: project.link },
            }"
          >
            <div
              class="project-main-image"
              v-if="project.project_images"
              :style="{
                backgroundImage:
                  'url(' + project.project_images[0].image_url + ')',
              }"
            >
              <div v-if="project.lastUnits" class="last-units">
                <p class="last-units-text text-white mb-0">Últimas unidades</p>
              </div>
              <b-img
                v-if="!project.black_seal && !project.new_project"
                style="position: absolute; left: -1px; top: 0; width: 35px"
                :src="
                  require('@/assets/images/projects/features/green-square.svg')
                "
              ></b-img>
              <b-img
                v-if="project.black_seal"
                style="position: absolute; left: 20px; top: 0; width: 110px"
                :src="require('@/assets/images/projects/features/seal.png')"
              ></b-img>
              <b-img
                v-if="project.new_project"
                style="position: absolute; right: 0px; top: 0; width: 110px"
                :src="require('@/assets/images/projects/new-project.svg')"
              >
              </b-img>
            </div>
          </router-link>
          <div v-else>
            <div
              class="project-main-image"
              v-if="project.project_images"
              :style="{
                backgroundImage:
                  'url(' + project.project_images[0].image_url + ')',
              }"
            >
              <div v-if="project.lastUnits" class="last-units">
                <p class="last-units-text text-white mb-0">Últimas unidades</p>
              </div>
              <b-img
                v-if="!project.black_seal && !project.new_project"
                style="position: absolute; left: 20px; top: 0; width: 110px"
                :src="require('@/assets/images/projects/features/seal.png')"
              ></b-img>
              <b-img
                v-if="project.black_seal"
                style="position: absolute; left: -1px; top: 0; width: 35px"
                :src="
                  require('@/assets/images/projects/features/green-square.svg')
                "
              ></b-img>
              <b-img
                v-if="project.new_project"
                style="position: absolute; right: 0px; top: 0; width: 110px"
                :src="require('@/assets/images/projects/new-project.svg')"
              >
              </b-img>
            </div>
          </div>
          <b-row class="mt-3">
            <b-col cols="12">
              <p class="project-commune mb-2">
                <b-img class="mr-2" :src="icons.map"></b-img
                >{{
                  project.neighborhood ? project.neighborhood : project.commune
                }}
              </p>
            </b-col>
            <b-col cols="12" style="height: 60px">
              <router-link
                v-if="!accomplishedProjects"
                :to="{
                  name: 'ProjectDetail',
                  params: { project_id: project.link },
                }"
              >
                <h4 class="project-title pb-3">
                  {{ project.type }} {{ project.name }}
                </h4>
              </router-link>
              <h4 v-else class="project-title pb-3">
                {{ project.type }} {{ project.name }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              v-if="showProjectDetails && project.project_id === 8"
              style="height: 50px"
            >
              <p v-if="showProjectDetails" class="project-attribute bolder">
                <b-img class="mr-2" :src="icons.actual_state"></b-img
                >{{ project.actual_state }}
              </p>
              <p class="project-attribute">Deptos, casas y sitios</p>
            </b-col>
            <b-col
              cols="12"
              v-if="showProjectDetails && project.project_id != 8"
              style="height: 50px"
            >
              <p v-if="showProjectDetails" class="project-attribute bolder">
                <b-img class="mr-2" :src="icons.actual_state"></b-img
                >{{ project.actual_state }}
              </p>
            </b-col>
            <b-col cols="12" v-if="showProjectDetails" style="height: 50px">
              <p class="project-attribute" v-if="project.project_id != 8">
                <b-img class="mr-2" :src="icons.mts"></b-img>Desde
                {{ project.min_mt2 }}
              </p>
            </b-col>
            <b-col cols="12" v-if="showProjectDetails">
              <b-row>
                <b-col cols="6" style="height: 50px">
                  <p
                    v-if="project.project_bedrooms && project.project_id != 8"
                    class="project-attribute"
                  >
                    <b-img class="mr-2" :src="icons.bedrooms"></b-img>
                    <template
                      v-for="(bedroom, key) in project.project_bedrooms"
                    >
                      <span v-if="bedroom.number > 0" :key="'bedroom' + key"
                        >{{ bedroom.number }}
                        <template v-if="project.project_bedrooms.length == 2">
                          <span
                            v-if="
                              key ==
                              Object.keys(project.project_bedrooms).length - 2
                            "
                          >
                            y
                          </span>
                        </template>
                        <template v-if="project.project_bedrooms.length > 2">
                          <span
                            v-if="
                              key !=
                                Object.keys(project.project_bedrooms).length -
                                  1 &&
                              key !=
                                Object.keys(project.project_bedrooms).length - 2
                            "
                            >,</span
                          >
                          <span
                            v-if="
                              key ==
                              Object.keys(project.project_bedrooms).length - 2
                            "
                          >
                            y
                          </span>
                        </template>
                      </span>
                    </template>
                    <span class="text-secondary">{{
                      project.project_bedrooms.length == 1 ? "Dorm" : "Dorms"
                    }}</span>
                  </p>
                </b-col>
                <b-col
                  cols="6"
                  v-if="project.project_bathrooms"
                  style="height: 50px"
                >
                  <p
                    v-if="project.project_bathrooms && project.project_id != 8"
                    class="project-attribute"
                  >
                    <b-img class="mr-2" :src="icons.bathrooms"></b-img>
                    <template
                      v-for="(bathroom, key) in project.project_bathrooms"
                    >
                      <span :key="'bedroom' + key"
                        >{{ bathroom.number }}
                        <template v-if="project.project_bathrooms.length == 2">
                          <span
                            v-if="
                              key ==
                              Object.keys(project.project_bathrooms).length - 2
                            "
                          >
                            y
                          </span>
                        </template>
                        <template v-if="project.project_bathrooms.length > 2">
                          <span
                            v-if="
                              key !=
                                Object.keys(project.project_bathrooms).length -
                                  1 &&
                              key !=
                                Object.keys(project.project_bathrooms).length -
                                  2
                            "
                            >,</span
                          >
                          <span
                            v-if="
                              key ==
                              Object.keys(project.project_bathrooms).length - 2
                            "
                          >
                            y
                          </span>
                        </template>
                      </span>
                    </template>
                    <span class="text-secondary">{{
                      project.project_bathrooms.length == 0 ? "Baño" : "Baños"
                    }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="quoteButton" cols="4" md="4" xl="4" class="w-100 mt-3">
              <b-button
                v-b-modal="'modal-quote-project' + project.id"
                variant="grey-border"
                class="w-100 py-3 mx-auto"
                >Cotizar</b-button
              >
            </b-col>
            <b-col
              v-if="showProjectDetails"
              cols="8"
              md="8"
              xl="4"
              class="w-100 mt-3"
              :class="quoteButton ? 'col-xl-8 col-md-8' : 'col-xl-12'"
            >
              <router-link
                v-if="project.project_detail"
                :to="{
                  name: 'ProjectDetail',
                  params: { project_id: project.link },
                }"
                ><b-button variant="grey" class="w-100 py-3 mx-auto"
                  >Conocer proyecto</b-button
                ></router-link
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <p class="not-found bolder text-center">
            No se encontró ningún proyecto con esos filtros
          </p>
        </b-col>
      </b-row>
    </b-container>
    <template v-for="(project, key) in projects ? projects : projects">
      <Quote
        v-bind:key="'project-key-quote-' + key"
        :project="project"
        :useCards="false"
        :id="'modal-quote-project' + project.id"
        v-if="quoteButton"
      />
    </template>
  </div>
</template>

<script>
import Quote from "@/components/Details/Quote.vue";
export default {
  name: "ProjectCards",
  props: {
    useBedroomsFilter: {
      type: Boolean,
      default: true,
    },
    useCommuneFilter: {
      type: Boolean,
      default: false,
    },
    communeSelected: {
      type: String,
      default: "Todas",
    },
    stateSelected: {
      type: String,
      default: "all",
    },
    regionSelected: {
      type: String,
      default: "Todas",
    },
    quoteButton: {
      type: Boolean,
      default: false,
    },
    destiny: {
      type: String,
    },
    showProjectDetails: {
      type: Boolean,
      default: true,
    },
    accomplishedProjects: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Quote,
  },
  data() {
    return {
      selectedProject: null,
      communeOption: "all",
      communeOptions: [{ value: "all", text: "Todos" }],
      filterOption: "all",
      filterOptions: [{ value: "all", text: "Todos" }],
      icons: {
        map: require("@/assets/images/invest/project-list/map.svg"),
        mts: require("@/assets/images/invest/project-list/mts-green.svg"),
        actual_state: require("@/assets/images/invest/project-list/deliver-green.svg"),
        bedrooms: require("@/assets/images/invest/project-list/bedrooms-green.svg"),
        bathrooms: require("@/assets/images/invest/project-list/bathrooms-green.svg"),
      },
    };
  },
  methods: {
    goTo(destiny) {
      var element = this.$refs[destiny];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    filter: function (key) {
      if (this.useCommuneFilter) {
        this.communeOption = key;
      } else if (this.useBedroomsFilter) {
        this.filterOption = key;
      }
    },
    loadCommuneFilters() {
      this.$store.getters.getAccomplishedProjects.forEach((project) => {
        if (
          !this.communeOptions.some((filter) => filter.value == project.commune)
        ) {
          this.communeOptions.push({
            text: project.commune,
            value: project.commune,
          });
        }
      });
    },
    loadDormsFilters() {
      // Cargando filtro;
      this.projects.forEach((project) => {
        project.project_bedrooms.forEach((bedroom) => {
          if (
            !this.filterOptions.some(
              (filterOption) => bedroom.number == filterOption.value
            )
          ) {
            this.filterOptions.push({
              value: bedroom.number,
              text: bedroom.number + (bedroom.number != 1 ? " Dorms" : " Dorm"),
            });
          }
        });
      });
      // Ordenando
      this.filterOptions.sort((a, b) => {
        return a.value - b.value;
      });
    },
    openModal(project) {
      this.selectedProject = project;
      this.$bvModal.show("modal-quote");
    },
  },
  mounted() {
    if (this.communeOptions == null) {
      this.loadCommuneFilters();
    } else if (this.useBedroomsFilter) {
      this.loadDormsFilters();
    }
  },
  watch: {
    filteredProjects(newValue) {
      if (newValue) {
        this.loadCommuneFilters();
      }
    },
    projects(newValue) {
      if (newValue) {
        this.loadDormsFilters();
      }
    },
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    },
    filteredProjects() {
      let filtered = [];
      if (!this.$store.getters.getProjects) return "loading";
      if (this.useCommuneFilter) {
        this.$store.getters.getAccomplishedProjects.forEach((project) => {
          if (project.project_id != 11) {
            if (this.communeOption == "all") {
              filtered.push(project);
            } else if (this.communeOption == project.commune) {
              filtered.push(project);
            }
          }
        });
        this.loadCommuneFilters();
        return filtered;
      } else {
        this.$store.getters
          .getProjectsByCommuneAndState(
            this.communeSelected,
            this.stateSelected,
            this.regionSelected
          )
          .forEach((project) => {
            if (this.filterOption == "all") {
              filtered.push(project);
            } else {
              if (
                project.project_bedrooms.some(
                  (bedroom) => this.filterOption == bedroom.number
                )
              ) {
                filtered.push(project);
              }
            }
          });
        return filtered;

        //   switch (this.filterOption){
        //   case 'all':
        //     return this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected);
        //   case '1':
        //     this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected).forEach(element => {
        //     if(element.project_bedrooms.some(bedroom => bedroom.number == 3) && element.project_bathrooms.some(bathroom => bathroom.number == 2)){
        //         filtered.push(element);
        //       }
        //     })
        //     return filtered;
        //   case '2':
        //     this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected).forEach(element => {
        //     if(element.project_bedrooms.some(bedroom => bedroom.number == 2) && element.project_bathrooms.some(bathroom => bathroom.number == 2)){
        //         filtered.push(element);
        //       }
        //     })
        //     return filtered;
        //   case '3':
        //     this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected).forEach(element => {
        //     if(element.project_bedrooms.some(bedroom => bedroom.number == 1) && element.project_bathrooms.some(bathroom => bathroom.number == 1)){
        //         filtered.push(element);
        //       }
        //     })
        //     return filtered;
        //   default:
        //     return this.$store.getters.getProjectsByCommuneAndState(this.communeSelected, this.stateSelected, this.regionSelected);
        //   }
        // }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.projects-cards-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* Light / Black */

  color: #373f41;
}
.projects-cards-subtitle {
  /* Body 1 - regular 16 (22, 0.3px) */

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  letter-spacing: 0.3px;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.projects-cards-filters-box {
  border: 1px solid #54565a;
  padding: 45px 0px;
}
.project-main-image {
  height: 310px;
  background-color: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.not-found {
  font-size: 40px;
  padding: 50px 0px 0px 0px;
  line-height: 100%;
}
.project-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-attribute {
  color: $grey;
}
.project-commune {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.last-units {
  position: absolute;
  right: 0;
  padding: 10px;
  background-color: $grey;
}
.last-units-text {
  font-family: "Gill Sans" !important;
  font-weight: 600;
}
.filter {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
  transition: 0.1s ease-in-out;
  padding: 30px 20px;
}
.active-filter {
  font-weight: 700;
  transition: 0.1s ease-in-out;
  background-color: #8bcb77;
}
@media (max-width: 992px) {
  .filter {
    text-align: center;
  }
}
</style>
