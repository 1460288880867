var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu mt-4 px-2 px-xl-0"},[_c('b-container',{staticClass:"border-box"},[_c('b-row',{staticClass:"py-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"cols":_vm.projectId != 7 ? '6' : '12',"md":"10","xl":""}},[_c('b-row',[[_vm._l((_vm.menu.filter(function (item) { return item.show; })),function(item,key){return _c('b-col',{key:'item' + key,staticClass:"d-none d-md-block d-xl-block my-auto",attrs:{"cols":"12","md":"auto","xl":_vm.projectId != 7 ? 'auto' : '4'}},[(_vm.state == 'Realizado')?_c('div',[(item.showIfDone)?_c('p',{staticClass:"menu-item mb-0 mx-0 mouse-click",on:{"click":function($event){return _vm.goTo(item.link)}}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()]):(_vm.state != 'Realizado')?_c('div',[(item.text != 'Brochure digital')?_c('p',{staticClass:"menu-item mb-0 mx-3 mouse-click",on:{"click":function($event){item.text != 'Contáctanos'
                      ? _vm.goTo(item.link)
                      : _vm.$bvModal.show('modal-contact')}}},[_vm._v(" "+_vm._s(item.text)+" ")]):(
                    item.text == 'Brochure digital' &&
                    _vm.digitalBrochureUrl !=
                      '/digital_brochures/original/missing.png'
                  )?_c('a',{attrs:{"href":_vm.digitalBrochureUrl,"target":"_blank","download":""}},[_c('p',{staticClass:"menu-item mb-0 mx-3 mouse-click"},[_vm._v(" "+_vm._s(item.text)+" ")])]):_vm._e()]):_vm._e()])}),_c('b-col',{staticClass:"w-100 d-block d-md-none d-xl-none my-auto",class:{ 'pb-4': _vm.projectId == 7 },attrs:{"cols":"12","md":"12","xl":"12"}},[_c('b-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('b-form-select',{staticClass:"w-100",attrs:{"options":_vm.menu},model:{value:(_vm.menuSelected),callback:function ($$v) {_vm.menuSelected=$$v},expression:"menuSelected"}})],1)],1)]],2)],1),(_vm.projectId == 7)?_c('b-col',{class:{
          'mt-3': _vm.projectId == 7,
          'my-auto mt-md-3 mt-lg-3 my-xl-auto': true,
        },attrs:{"cols":"6","md":_vm.projectId != 7 ? '12' : '6',"xl":"2"}},[_c('a',{staticClass:"button-arrendar",attrs:{"href":"https://www.assetplan.cl/arriendo/departamento/huechuraba/los-almendros/1851","target":"_blank"}},[_c('b-button',{staticClass:"w-100 py-3",attrs:{"variant":"light-green"}},[_vm._v("Arrendar")])],1)]):_vm._e(),_c('b-col',{class:{
          'mt-3': _vm.projectId == 7,
          'my-auto mt-md-3 mt-lg-3 my-xl-auto': true,
        },attrs:{"cols":"6","md":_vm.projectId != 7 ? '12' : '6',"xl":"2"}},[_c('b-button',{staticClass:"w-100 py-3",attrs:{"variant":"grey"},on:{"click":function($event){return _vm.$bvModal.show('modal-quote')}}},[_vm._v("Cotizar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }