<template>
  <div class="py-5">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" xl="5" class="my-auto">
          <h3 class="about-title">{{title}}</h3>
          <p class="mt-5 about-description" v-html="description"></p>
        </b-col>
        <b-col cols="12" md="6" xl="6" offset-xl="1" class="w-100 py-5 my-auto">
          <b-row>
            <b-col cols="12" md="4" xl="4" v-for="(person, key) in directory" :key="('person' + key)" :class="(key != 0 ? 'mt-4 mt-md-0 mt-xl-0' : '')">
              <b-img class="w-100" :src="person.image"></b-img>
              <h3 class="directory-name">{{person.name}}</h3>
              <hr style="border-bottom: 5px solid #3FAD29;width:50%;margin-left: 0px!important;">
              <p>{{person.title}}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  props: {
    title: String,
    description: String,
    image: String
  },
  data() {
    return {
      directory: [
        {
          name: 'José Ignacio Amenábar',
          title: 'Socio y Presidente del directorio',
          image: require('@/assets/images/about/directory/jose.png')
        },
        {
          name: 'Guillermo Andrews',
          title: 'Socio y Director inmobiliario',
          image: require('@/assets/images/about/directory/guillermo.png')
        },
        {
          name: 'Rodrigo López',
          title: 'Socio y Director constructora',
          image: require('@/assets/images/about/directory/rodrigo.png')
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.directory-name{
  font-size: 1.3rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
  margin-top: 40px;
}
.about-title{
  font-style: normal;
  text-transform: none!important;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-transform: none;
  /* or 100% */

  letter-spacing: 0.1px;

  /* Light / Black */

  color: #373F41;
}
.about-description{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  letter-spacing: 0.3px;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.about-background-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100%;
  width:100%
}
@media (max-width:992px) {
  .about-background-img{
    height:300px
  }
}
</style>
