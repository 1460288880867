<template>
  <b-modal
    :hide-footer="true"
    :hide-header="true"
    :id="id"
    size="xl"
    centered
    title="Solicitud de Cotización"
    header-bg-variant="light"
    header-text-variant="dark"
    :no-enforce-focus="true"
    @hidden="cleanModal()"
  >
    <b-overlay :show="loadingReservation" rounded="lg">
      <div v-if="opportunity">
        <b-container fluid>
          <b-row>
            <b-col class="pr-0">
              <button
                type="button"
                aria-label="Close"
                class="close"
                @click="$bvModal.hide(id)"
              >
                ×
              </button>
            </b-col>
          </b-row>
        </b-container>
        <div>
          <b-container class="form-sm" fluid>
            <b-row>
              <b-col cols="12" md="3">
                <b-row>
                  <b-col cols="6" md="12" v-if="project.project_images[0]">
                    <b-aspect
                      aspect="1:1"
                      :style="{
                        background:
                          'url(' + project.project_images[0].image_url + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }"
                    >
                    </b-aspect>
                  </b-col>
                  <b-col cols="6" md="12">
                    <b-aspect
                      aspect="1:1"
                      :style="{
                        background:
                          'url(https://fortaleza.mobysuite.com/' +
                          selectedApartment.urlImagenUno +
                          ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }"
                    >
                    </b-aspect>
                  </b-col>
                </b-row>
                <div class="h5 bolder mt-4">{{ project.name }}</div>
                <div class="mb-4 actual-state">
                  <b-img
                    class="mr-2"
                    :src="
                      require('@/assets/images/invest/project-list/deliver.svg')
                    "
                  ></b-img>
                  {{ project.actual_state }}
                </div>
                <p class="small text-grey">
                  Comuna: {{ project.commune }} <br />
                  Piso: {{ selectedApartment.piso }}<br />
                  Tipología: {{ selectedApartment.tipo }}<br />
                  <span v-if="selectedApartment.orientancion"
                    >Orientación: {{ selectedApartment.orientacion }}<br
                  /></span>
                  Sup. Total:
                  {{ selectedApartment.superficieTotal | numberFormat }} m<sup
                    >2</sup
                  >
                </p>
              </b-col>
              <b-col cols="12" md="9">
                <div class="h4 bolder">Resumen de la inversión</div>
                <div class="h5 mt-2">Valor UF: ${{ uf | numberFormat }}</div>
                <b-tabs
                  v-model="tabIndex"
                  no-nav-style
                  no-key-nav
                  nav-wrapper-class="d-none"
                >
                  <div class="mt-4">
                    <table class="mb-3 w-100">
                      <tr>
                        <td class="h5 bolder">Unidades</td>
                        <td class="text-right">
                          <b-button-group size="lg" class="mt-2">
                            <b-button
                              :class="{ 'w-50': true, active: tabIndex == 0 }"
                              @click="tabIndex--"
                              >UF</b-button
                            >
                            <b-button
                              :class="{ 'w-50': true, active: tabIndex == 1 }"
                              @click="tabIndex++"
                              >$</b-button
                            >
                          </b-button-group>
                        </td>
                      </tr>
                    </table>

                    <b-tab id="isUf" no-title>
                      <b-table-simple responsive>
                        <b-thead head-variant="dark">
                          <b-tr>
                            <b-th>Unidad</b-th>
                            <b-th class="text-right">Precio (UF)</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{
                              setApartmentLabel(
                                opportunity.grupoBienes.find((asset) =>
                                  assetsList.includes(asset.tipoBien)
                                )
                              )
                            }}</b-td>
                            <b-td class="text-right"
                              >{{
                                opportunity.grupoBienes.find((asset) =>
                                  assetsList.includes(asset.tipoBien)
                                ).valorLista | numberFormat
                              }}
                              UF</b-td
                            >
                          </b-tr>
                          <b-tr
                            v-if="
                              getDiscount(
                                opportunity.grupoBienes.find((asset) =>
                                  assetsList.includes(asset.tipoBien)
                                )
                              ) > 0
                            "
                          >
                            <b-td>DESCUENTO</b-td>
                            <b-td class="text-right"
                              >{{
                                getDiscount(
                                  opportunity.grupoBienes.find((asset) =>
                                    assetsList.includes(asset.tipoBien)
                                  )
                                ) | numberFormat
                              }}
                              UF</b-td
                            >
                          </b-tr>
                          <b-tr
                            v-for="(
                              asset, ckey
                            ) in opportunity.grupoBienes.filter((asset) =>
                              ['ESTACIONAMIENTO', 'BODEGA'].includes(
                                asset.tipoBien
                              )
                            )"
                            :key="'ac' + ckey"
                          >
                            <b-td>{{ setApartmentLabel(asset) }}</b-td>
                            <b-td class="text-right"
                              >{{ asset.valorLista | numberFormat }} UF</b-td
                            >
                          </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-th>Total</b-th>
                            <b-th class="text-right"
                              >{{ setTotal("uf") | numberFormat }} UF</b-th
                            >
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                      <table class="mb-3 w-100">
                        <tr>
                          <td class="h5 bolder">Configuración de inversión</td>
                        </tr>
                      </table>
                      <b-table-simple responsive>
                        <b-tbody>
                          <b-tr>
                            <b-th>Inversión Final</b-th>
                            <b-td class="text-right"
                              >{{ total.invest.uf | numberFormat }} UF</b-td
                            >
                          </b-tr>
                          <b-tr>
                            <b-th>Pie Total (20%)</b-th>
                            <b-td class="text-right"
                              >{{ total.pie.uf | numberFormat }} UF</b-td
                            >
                          </b-tr>
                          <b-tr class="bg-fortaleza text-white">
                            <b-th>Reserva</b-th>
                            <b-td class="text-right"
                              >{{ total.reserve.uf | numberFormat }} UF</b-td
                            >
                          </b-tr>
                          <b-tr>
                            <b-th>Pie a Pagar</b-th>
                            <b-td class="text-right"
                              >{{ total.finalPie.uf | numberFormat }} UF</b-td
                            >
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                    <b-tab id="noUf" no-title>
                      <b-table-simple responsive>
                        <b-thead head-variant="dark">
                          <b-tr>
                            <b-th>Unidad</b-th>
                            <b-th class="text-right">Precio ($)</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td>{{
                              setApartmentLabel(
                                opportunity.grupoBienes.find((asset) =>
                                  assetsList.includes(asset.tipoBien)
                                )
                              )
                            }}</b-td>
                            <b-td class="text-right"
                              >$
                              {{
                                calcValue(
                                  opportunity.grupoBienes.find((asset) =>
                                    assetsList.includes(asset.tipoBien)
                                  ).valorLista
                                ) | numberFormat
                              }}</b-td
                            >
                          </b-tr>
                          <b-tr
                            v-if="
                              getDiscount(
                                opportunity.grupoBienes.find((asset) =>
                                  assetsList.includes(asset.tipoBien)
                                )
                              ) > 0
                            "
                          >
                            <b-td>DESCUENTO</b-td>
                            <b-td class="text-right"
                              >$
                              {{
                                calcValue(
                                  getDiscount(
                                    opportunity.grupoBienes.find((asset) =>
                                      assetsList.includes(asset.tipoBien)
                                    )
                                  )
                                ) | numberFormat
                              }}</b-td
                            >
                          </b-tr>
                          <b-tr
                            v-for="(
                              asset, ckey
                            ) in opportunity.grupoBienes.filter((asset) =>
                              ['ESTACIONAMIENTO', 'BODEGA'].includes(
                                asset.tipoBien
                              )
                            )"
                            :key="'ac' + ckey"
                          >
                            <b-td>{{ setApartmentLabel(asset) }}</b-td>
                            <b-td class="text-right"
                              >$
                              {{
                                calcValue(asset.valorLista) | numberFormat
                              }}</b-td
                            >
                          </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-th>Total</b-th>
                            <b-th class="text-right"
                              >$ {{ setTotal("money") | numberFormat }}</b-th
                            >
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                      <table class="mb-3 w-100">
                        <tr>
                          <td class="h5 bolder">Configuración de inversión</td>
                        </tr>
                      </table>
                      <b-table-simple responsive>
                        <b-tbody>
                          <b-tr>
                            <b-th>Inversión Final</b-th>
                            <b-td class="text-right"
                              >$ {{ total.invest.amount | numberFormat }}</b-td
                            >
                          </b-tr>
                          <b-tr>
                            <b-th>Pie Total (20%)</b-th>
                            <b-td class="text-right"
                              >$ {{ total.pie.amount | numberFormat }}</b-td
                            >
                          </b-tr>
                          <b-tr class="bg-fortaleza text-white">
                            <b-th>Reserva</b-th>
                            <b-td class="text-right"
                              >$ {{ total.reserve.amount | numberFormat }}</b-td
                            >
                          </b-tr>
                          <b-tr>
                            <b-th>Pie a Pagar</b-th>
                            <b-td class="text-right"
                              >$
                              {{ total.finalPie.amount | numberFormat }}</b-td
                            >
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-tab>
                  </div>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row v-if="showResponse">
              <b-container fluid>
                <b-row class="bg-dark text-white p-5 mt-4">
                  <b-col cols="12" md="6">
                    <div class="text-uppercase">
                      <span class="h3"><b>Gracias por cotizar</b></span
                      ><br />
                      <span class="h5"
                        >Un ejecutivo te contactará en breve</span
                      >
                    </div>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-button
                      squared
                      variant="green"
                      size="lg"
                      block
                      :href="quoteResponse.document"
                      target="_blank"
                      >Descargar cotización</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </b-row>
            <template v-else>
              <b-row class="mt-4 mb-3">
                <b-col>
                  <div class="h5 bolder">Realizar tu cotización</div>
                  <p class="small text-muted">
                    En caso de cotizar, complete los siguientes campos.
                  </p>
                </b-col>
              </b-row>
              <b-row class="reservation">
                <b-col cols="12">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Nombre
                        </div>
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.name.$model"
                          :state="
                            $v.quote.name.$dirty ? !$v.quote.name.$error : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          El nombre debe contener al menos
                          {{ $v.quote.name.$params.minLength.min }} caracteres.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Apellido
                        </div>
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.lastname.$model"
                          :state="
                            $v.quote.lastname.$dirty
                              ? !$v.quote.lastname.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          El apellido debe contener al menos
                          {{ $v.quote.lastname.$params.minLength.min }}
                          caracteres.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="my-2">
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Rut
                        </div>
                        <b-form-input
                          v-model.trim="$v.quote.rut.$model"
                          @keyup="formatRut"
                          :state="
                            $v.quote.rut.$dirty ? !$v.quote.rut.$error : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Rut Inválido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Email
                        </div>
                        <b-form-input
                          size="sm"
                          v-model.trim="$v.quote.email.$model"
                          :state="
                            $v.quote.email.$dirty
                              ? !$v.quote.email.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Email inválido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Celular
                        </div>
                        <vue-tel-input
                          @input="checkPhone"
                          wrapperClasses="form-control cellphone-input"
                        ></vue-tel-input>
                        <b-form-input
                          class="d-none"
                          v-model="$v.quote.cellphone.$model"
                          :state="
                            $v.quote.cellphone.$dirty
                              ? !$v.quote.cellphone.$error
                              : null
                          "
                        ></b-form-input>
                        <b-form-invalid-feedback>
                          Teléfono invalido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Rango etario
                        </div>
                        <b-select
                          v-model="quote.age_range"
                          :options="ageRangeList"
                        ></b-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Comuna
                        </div>
                        <b-select
                          v-model="quote.commune"
                          :options="communeList"
                        ></b-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <div
                          class="text-left px-1 small text-dark"
                          style="
                            position: absolute;
                            top: -10px;
                            left: 35px;
                            background-color: white !important;
                          "
                        >
                          Busca Para
                        </div>
                        <b-select
                          v-model="quote.destiny"
                          :options="destinyList"
                        ></b-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col cols="12" md="6" offset-md="6" class="text-center">
                      <b-button
                        block
                        variant="grey"
                        @click="send"
                        size="lg"
                        class="py-2"
                        >{{ isReservation ? "RESERVAR" : "COTIZAR" }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
          </b-container>
          <form
            id="webpay-form"
            ref="form"
            action="https://webpay3g.transbank.cl:443/webpayserver/initTransaction"
            accept-charset="UTF-8"
            method="post"
          >
            <input
              id="webpay-token"
              type="hidden"
              name="token_ws"
              v-model="transbank.token"
            />
          </form>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "quotation",
  props: {
    project: { default: null },
    opportunity: { default: null },
    id: { default: "modal-quotation" },
    dataLayer: { default: null },
    isReservation: { default: false },
  },
  data() {
    return {
      assetsList: ["DEPARTAMENTO", "CASA", "OFICINA", "LOCAL", "LOTE"],
      tabIndex: 0,
      sumUf: 0,
      sumPrice: 0,
      calcUf: true,
      transbank: { token: null },
      selectedRoom: null,
      selectedBathroom: null,
      roms: null,
      bathrooms: null,
      filteredApartments: null,
      selectedCellar: null,
      selectedParking: null,
      selectedComplementary: null,
      apartmentTypes: null,
      selectedType: null,
      loading: false,
      chilean: true,
      isLoading: false,
      loadingReservation: false,
      ageRangeList: [],
      communeList: [],
      destinyList: [],
      showResponse: false,
      quote: {
        customer_name: "fortaleza",
        project_name: null,
        project_id: null,
        name: null,
        lastname: null,
        email: null,
        cellphone: null,
        rut: null,
        age_range: null,
        commune: null,
        destiny: null,
        address: null,
        apartment: null,
        city: null,
        assets: [],
        tracker: null,
        discountId: null,
      },
      quoteResponse: {
        code: null,
        document: null,
      },
      settings: {
        dots: false,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        rows: 1,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
      },
      total: {
        invest: {
          uf: null,
          amount: null,
        },
        pie: {
          uf: null,
          amount: null,
        },
        reserve: {
          uf: null,
          amount: null,
        },
        finalPie: {
          uf: null,
          amount: null,
        },
      },
    };
  },
  validations() {
    if (this.chilean) {
      return {
        quote: {
          rut: { required, RutValid: isRutValid },
          name: { required, minLength: minLength(2) },
          lastname: { required, minLength: minLength(2) },
          email: { required, email },
          cellphone: { required, minLength: minLength(9) },
        },
      };
    } else {
      return {
        quote: {
          rut: { required: false },
          name: { required, minLength: minLength(2) },
          lastname: { required, minLength: minLength(2) },
          email: { required, email },
          cellphone: { required, minLength: minLength(9) },
        },
      };
    }
  },
  beforeMount() {
    this.sumUf = 0.0;
    this.sumPrice = 0.0;
    this.ageRangeList = [
      "1-25",
      "26-35",
      "36-45",
      "46-55",
      "56-65",
      "66 o más",
    ];
    this.communeList = [
      "Arica",
      "Camarones",
      "Putre",
      "General Lagos",
      "Iquique",
      "Alto Hospicio",
      "Pozo Almonte",
      "Camiña",
      "Colchane",
      "Huara",
      "Pica",
      "Antofagasta",
      "Mejillones",
      "Sierra Gorda",
      "Taltal",
      "Calama",
      "Ollagüe",
      "San Pedro de Atacama",
      "Tocopilla",
      "María Elena",
      "Copiapó",
      "Caldera",
      "Tierra Amarilla",
      "Chañaral",
      "Diego de Almagro",
      "Vallenar",
      "Alto del Carmen",
      "Freirina",
      "Huasco",
      "La Serena",
      "Coquimbo",
      "Andacollo",
      "La Higuera",
      "Paiguano",
      "Vicuña",
      "Illapel",
      "Canela",
      "Los Vilos",
      "Salamanca",
      "Ovalle",
      "Combarbalá",
      "Monte Patria",
      "Punitaqui",
      "Río Hurtado",
      "V Región",
      "Casablanca",
      "Concón",
      "Juan Fernández",
      "Puchuncaví",
      "Quintero",
      "Viña del Mar",
      "Isla de Pascua",
      "Los Andes",
      "Calle Larga",
      "Rinconada",
      "San Esteban",
      "La Ligua",
      "Cabildo",
      "Papudo",
      "Petorca",
      "Zapallar",
      "Quillota",
      "Calera",
      "Hijuelas",
      "La Cruz",
      "Nogales",
      "San Antonio",
      "Algarrobo",
      "Cartagena",
      "El Quisco",
      "El Tabo",
      "Santo Domingo",
      "San Felipe",
      "Catemu",
      "Llaillay",
      "Panquehue",
      "Putaendo",
      "Santa María",
      "Quilpué",
      "Limache",
      "Olmué",
      "Villa Alemana",
      "Rancagua",
      "Codegua",
      "Coinco",
      "Coltauco",
      "Doñihue",
      "Graneros",
      "Las Cabras",
      "Machalí",
      "Malloa",
      "Mostazal",
      "Olivar",
      "Peumo",
      "Pichidegua",
      "Quinta de Tilcoco",
      "Rengo",
      "Requínoa",
      "San Vicente",
      "Pichilemu",
      "La Estrella",
      "Litueche",
      "Marchihue",
      "Navidad",
      "Paredones",
      "San Fernando",
      "Chépica",
      "Chimbarongo",
      "Lolol",
      "Nancagua",
      "Palmilla",
      "Peralillo",
      "Placilla",
      "Pumanque",
      "Santa Cruz",
      "Talca",
      "Constitución",
      "Curepto",
      "Empedrado",
      "Maule",
      "Pelarco",
      "Pencahue",
      "Río Claro",
      "San Clemente",
      "San Rafael",
      "Cauquenes",
      "Chanco",
      "Pelluhue",
      "Curicó",
      "Hualañé",
      "Licantén",
      "Molina",
      "Rauco",
      "Romeral",
      "Sagrada Familia",
      "Teno",
      "Vichuquén",
      "Linares",
      "Colbún",
      "Longaví",
      "Parral",
      "Retiro",
      "San Javier",
      "Villa Alegre",
      "Yerbas Buenas",
      "Cobquecura",
      "Coelemu",
      "Ninhue",
      "Portezuelo",
      "Quirihue",
      "Ránquil",
      "Treguaco",
      "Bulnes",
      "Chillán Viejo",
      "Chillán",
      "El Carmen",
      "Pemuco",
      "Pinto",
      "Quillón",
      "San Ignacio",
      "Yungay",
      "Coihueco",
      "Ñiquén",
      "San Carlos",
      "San Fabián",
      "San Nicolás",
      "Concepción",
      "Coronel",
      "Chiguayante",
      "Florida",
      "Hualqui",
      "Lota",
      "Penco",
      "San Pedro de la Paz",
      "Santa Juana",
      "Talcahuano",
      "Tomé",
      "Hualpén",
      "Lebu",
      "Arauco",
      "Cañete",
      "Contulmo",
      "Curanilahue",
      "Los Álamos",
      "Tirúa",
      "Los Ángeles",
      "Antuco",
      "Cabrero",
      "Laja",
      "Mulchén",
      "Nacimiento",
      "Negrete",
      "Quilaco",
      "Quilleco",
      "San Rosendo",
      "Santa Bárbara",
      "Tucapel",
      "Yumbel",
      "Alto Biobío",
      "Temuco",
      "Carahue",
      "Cunco",
      "Curarrehue",
      "Freire",
      "Galvarino",
      "Gorbea",
      "Lautaro",
      "Loncoche",
      "Melipeuco",
      "Nueva Imperial",
      "Padre las Casas",
      "Perquenco",
      "Pitrufquén",
      "Pucón",
      "Saavedra",
      "Teodoro Schmidt",
      "Toltén",
      "Vilcún",
      "Villarrica",
      "Cholchol",
      "Angol",
      "Collipulli",
      "Curacautín",
      "Ercilla",
      "Lonquimay",
      "Los Sauces",
      "Lumaco",
      "Purén",
      "Renaico",
      "Traiguén",
      "Victoria",
      "Valdivia",
      "Corral",
      "Lanco",
      "Los Lagos",
      "Máfil",
      "Mariquina",
      "Paillaco",
      "Panguipulli",
      "La Unión",
      "Futrono",
      "Lago Ranco",
      "Río Bueno",
      "Puerto Montt",
      "Calbuco",
      "Cochamó",
      "Fresia",
      "Frutillar",
      "Los Muermos",
      "Llanquihue",
      "Maullín",
      "Puerto Varas",
      "Castro",
      "Ancud",
      "Chonchi",
      "Curaco de Vélez",
      "Dalcahue",
      "Puqueldón",
      "Queilén",
      "Quellón",
      "Quemchi",
      "Quinchao",
      "Osorno",
      "Puerto Octay",
      "Purranque",
      "Puyehue",
      "Río Negro",
      "San Juan de la Costa",
      "San Pablo",
      "Chaitén",
      "Futaleufú",
      "Hualaihué",
      "Palena",
      "Coihaique",
      "Lago Verde",
      "Aisén",
      "Cisnes",
      "Guaitecas",
      "Cochrane",
      "O’Higgins",
      "Tortel",
      "Chile Chico",
      "Río Ibáñez",
      "Punta Arenas",
      "Laguna Blanca",
      "Río Verde",
      "San Gregorio",
      "Cabo de Hornos (Ex Navarino)",
      "Antártica",
      "Porvenir",
      "Primavera",
      "Timaukel",
      "Natales",
      "Torres del Paine",
      "Cerrillos",
      "Cerro Navia",
      "Conchalí",
      "El Bosque",
      "Estación Central",
      "Huechuraba",
      "Independencia",
      "La Cisterna",
      "La Florida",
      "La Granja",
      "La Pintana",
      "La Reina",
      "Las Condes",
      "Lo Barnechea",
      "Lo Espejo",
      "Lo Prado",
      "Macul",
      "Maipú",
      "Ñuñoa",
      "Pedro Aguirre Cerda",
      "Peñalolén",
      "Providencia",
      "Pudahuel",
      "Quilicura",
      "Quinta Normal",
      "Recoleta",
      "Renca",
      "Santiago",
      "San Joaquín",
      "San Miguel",
      "San Ramón",
      "Vitacura",
      "Puente Alto",
      "Pirque",
      "San José de Maipo",
      "Colina",
      "Lampa",
      "Tiltil",
      "San Bernardo",
      "Buin",
      "Calera de Tango",
      "Paine",
      "Melipilla",
      "Alhué",
      "Curacaví",
      "María Pinto",
      "San Pedro",
      "Talagante",
      "El Monte",
      "Isla de Maipo",
      "Padre Hurtado",
      "Peñaflor",
    ];
    this.destinyList = ["Primera vivienda", "Segunda vivienda", "Inversión"];
    this.loadingReservation = true;
    this.loadingReservation = false;
    if (this.opportunity) {
      this.quote.discountId = this.opportunity.id;
    }
    this.quote.project_name = this.project.project_name;
    this.quote.project_id = this.project.project_id;
  },
  methods: {
    setTotal(type) {
      let self = this;
      let uf = 0;
      this.opportunity.grupoBienes.forEach(
        (asset) => (uf += self.setPrice(asset))
      );
      this.total.invest.uf = uf;
      this.total.invest.amount = this.calcValue(uf);
      this.total.pie.uf = uf * 0.2;
      if (this.project.project_id == 11) {
        this.total.reserve.amount = this.total.reserve.uf;
        this.total.reserve.uf = this.total.reserve.amount / this.uf;
      } else {
        this.total.reserve.amount = this.calcValue(this.total.reserve.uf);
      }
      this.total.pie.amount = this.calcValue(uf * 0.2);
      this.total.finalPie.uf = this.total.pie.uf - this.total.reserve.uf;
      this.total.finalPie.amount =
        this.total.pie.amount - this.total.reserve.amount;
      if (type == "uf") {
        return uf;
      } else {
        return this.calcValue(uf);
      }
    },
    setPrice(asset) {
      if (
        ["DEPARTAMENTO", "CASA", "SITIO", "LOCAL", "OFICINA", "LOTE"].includes(
          asset.tipoBien
        )
      ) {
        return this.calculateDiscount(asset);
      } else {
        return asset.valorLista;
      }
    },
    calculateDiscount(asset) {
      let discount = asset.valorLista;
      if (this.opportunity.tipoMonto == "PORCENTAJE") {
        discount = asset.valorLista * (1 - this.opportunity.montoDescuento);
      } else if (this.opportunity.tipoMonto == "CONSTANTE") {
        discount = asset.valorLista - this.opportunity.montoDescuento;
      }
      return discount;
    },
    getDiscount(asset) {
      let discount = asset.valorLista;
      if (this.opportunity.tipoMonto == "PORCENTAJE") {
        discount =
          asset.valorLista -
          asset.valorLista * (1 - this.opportunity.montoDescuento);
      } else if (this.opportunity.tipoMonto == "CONSTANTE") {
        discount = this.opportunity.montoDescuento;
      }
      return discount;
    },
    calcValue(price) {
      let calc = Math.round((price * this.uf * 100) / 100);
      return calc;
    },
    setTypeLabel(type) {
      return `Tipo ${type}`;
    },
    setApartmentLabel(apartment) {
      return `${apartment.tipoBien} ${apartment.numeroDeBien}`;
    },
    setAssetLabel(asset) {
      return `Nº ${asset.number}`;
    },
    digito(rut, dv) {
      let suma = 0;
      let multiplicador = 2;
      while (rut !== 0) {
        if (multiplicador === 8) {
          multiplicador = 2;
        }
        suma += (rut % 10) * multiplicador;
        rut = Math.trunc(rut / 10);
        multiplicador++;
      }
      suma = 11 - (suma % 11);
      switch (suma) {
        case 11:
          return "0" === dv;
        case 10:
          return "K" === dv.toUpperCase();
        default:
          return suma.toString() === dv;
      }
    },
    formatRut() {
      if (this.chilean) {
        if (this.quote.rut.length > 0) {
          this.quote.rut = this.quote.rut.replace(/[^\dKk]/g, "");
          let dato = this.quote.rut;
          let largo = dato.length;
          if (largo === 1) {
            this.dv = dato;
            this.quote.rut = "";
            this.digito(this.quote.rut, this.dv);
            this.formato();
          } else if (largo > 1) {
            this.dv = dato.substring(largo - 1);
            this.quote.rut = dato.substring(largo - 1, 0);
            this.digito(this.quote.rut, this.dv);
            this.formato();
          }
        }
      } else {
        return true;
      }
    },
    formato() {
      let rut = this.quote.rut
        ? new Intl.NumberFormat("es-CL").format(parseInt(this.quote.rut)) + "-"
        : "";
      this.quote.rut = rut + this.dv;
    },
    send() {
      let self = this;
      this.quote.assets = this.opportunity.grupoBienes.map((asset) => asset.id);
      this.quote.tracker = this.$cookies.get("mb-tracker");
      this.quote.project_name = this.project.project_name;
      this.quote.project_id = this.project.project_id;
      this.$v.quote.$touch();
      if (!this.$v.quote.$invalid) {
        this.loadingReservation = true;
        if (this.isReservation) {
          this.axios
            .post("reservations", {
              customer_id: "fortaleza",
              id: this.project.project_name,
              reservation: this.quote,
            })
            .then((result) => {
              self.loadingReservation = false;
              self.transbank.token = result.data.token;
              if (result.data.token) {
                document.getElementById("webpay-token").value =
                  result.data.token;
                document.getElementById("webpay-form").submit();
              }
            })
            .catch(() => {
              self.loadingReservation = false;
            });
        } else {
          this.axios
            .post("quotes", {
              customer_id: "fortaleza",
              id: this.project.project_name,
              quote: this.quote,
            })
            .then((result) => {
              self.quoteResponse = result.data;
              self.showResponse = true;
              self.loadingReservation = false;
            })
            .catch(() => {
              self.loadingReservation = false;
            });
        }
      }
    },
    checkPhone(number, data) {
      if (number.length > 0) {
        if (data.isValid) {
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.remove("is-invalid");
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.add("is-valid");
          this.quote.cellphone = data.number.e164;
        } else {
          this.quote.cellphone = null;
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.remove("is-valid");
          document
            .getElementsByClassName("cellphone-input")[0]
            .classList.add("is-invalid");
        }
      } else {
        this.quote.cellphone = null;
        document
          .getElementsByClassName("cellphone-input")[0]
          .classList.remove("is-valid");
        document
          .getElementsByClassName("cellphone-input")[0]
          .classList.remove("is-invalid");
      }
    },
    cleanModal() {
      this.showResponse = false;
      this.quoteResponse = {
        code: null,
        document: null,
      };
      this.quote.assets = [this.selectedApartment];
      this.$v.quote.$reset();
    },
    calculateReservation() {
      this.total.reserve.uf = this.opportunity.reserve_amount;
    },
  },
  computed: {
    uf() {
      return this.$store.getters.getUf;
    },
    selectedApartment() {
      return this.opportunity.grupoBienes[0];
    },
    popoverConfig() {
      return {
        html: true,
        title: () => {
          return "CONDICIONES DE RESERVA ONLINE";
        },
        content: () => {
          return "<table><tr><td>Dentro del plazo de 10 días hábiles me comprometo a suscribir la correspondiente promesa de compraventa. El no cumplimiento, será considerado DESISTIMIENTO. En este caso la Inmobiliaria no reembolsará el monto por concepto de reserva y podrá disponer de la propiedad para la venta. </td></tr><tr><td>El pie de la vivienda debe estar cancelado al momento de firmar la escritura.</td></tr><tr><td> <strong>Gastos operacionales:</strong></td></tr><tr><td> Se cancelarán a la institución que otorga el crédito y el monto será determinado por la institución crediticia. </td></tr><tr><td>En caso de pago contado, se cancelan a la Inmobiliaria. Los gastos operacionales NO están incluidos en el Precio.</td></tr>";
        },
      };
    },
  },
  watch: {
    project: {
      handler(newValue) {
        if (!newValue) {
          return;
        }
        this.calculateReservation();
      },
      immediate: true,
    },
  },
};
export function isRutValid(value) {
  if (value == null) return false;
  if (value.length === 0) return false;
  var input = value.replace(/[^\dKk]/g, "");
  let dato = input;
  let largo = dato.length;
  if (largo === 1) {
    let dv = dato;
    let rut = "";
    return digito(rut, dv);
  } else if (largo > 1) {
    let dv = dato.substring(largo - 1);
    let rut = dato.substring(largo - 1, 0);
    return digito(rut, dv);
  } else {
    return false;
  }

  function digito(rut, dv) {
    let suma = 0;
    let multiplicador = 2;
    while (rut !== 0) {
      if (multiplicador === 8) {
        multiplicador = 2;
      }
      suma += (rut % 10) * multiplicador;
      rut = Math.trunc(rut / 10);
      multiplicador++;
    }
    suma = 11 - (suma % 11);
    switch (suma) {
      case 11:
        return "0" === dv;
      case 10:
        return "K" === dv.toUpperCase();
      default:
        return suma.toString() === dv;
    }
  }
}
</script>
<style scoped lang="scss">
.bg-fortaleza {
  background-color: rgb(126, 180, 92);
}
.actual-state {
  font-weight: bold !important;
}
.btn-green {
  background-color: #43a739;
  color: white;
  border-radius: 0px;
  padding: 10px 50px;
  text-transform: uppercase;
  font-weight: 900;
}
.custom-select {
  border: 1px solid #ced4da !important;
}
</style>
