<template>
  <div>
    <Navbar v-if="header" />
    <Header type="Header" v-if="header" :title="header.title" :italicTitle="header.italic_title" :subtitle="header.subtitle" color="#434041" :text_color="header.text_color" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" :image="header.image_url" :imageMobile="header.mobile_image_url"/>
    <ProjectSearch v-if="header" theme="light" :projects="projects" @filters="filterAction" @goTo="goToAction" :showOnlyCommunesWithProjects="true"/>
    <ProjectCards ref="projectCards" v-if="header" :communeSelected="communeSelected" :stateSelected="stateSelected" :regionSelected="regionSelected" :quoteButton="true" :destiny="goTo" />
    <Footer v-if="header" />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from '@/components/Menu.vue'
import Header from '@/components/Header.vue'
import ProjectSearch from '@/components/ProjectSearch.vue'
import ProjectCards from '@/components/ProjectCards.vue'
import Footer from '@/components/Footer.vue'
import ContactModal from '@/components/ContactModal.vue'
export default {
  name: 'ProjectList',
  components: {
    Navbar,
    Header,
    ProjectSearch,
    ProjectCards,
    Footer,
    ContactModal
  },
  data() {
    return {
      communeSelected: 'Todas',
      stateSelected: 'all',
      regionSelected: 'Todas',
      header: null,
      goTo: null
    }
  },
  methods: {
    filterAction(commune, state, region) {
      this.communeSelected = commune;
      this.stateSelected = state;
      this.regionSelected = region;
    },
    goToAction(destiny){
      var element = this.$refs['projectCards'].$refs[destiny];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'ProjectList');
    window.scrollTo(0,0);
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects;
    }
  },
}
</script>

<style>

</style>
