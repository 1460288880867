<template>
  <div>
    <div class="project-header-img py-0 py-xl-5 px-2 px-xl-0" :style="image ? { backgroundImage: 'url(' + image + ')' } : { backgroundColor: color }">
      <b-container class="h-100">
        <b-row class="h-100 main-row" align-v="center">
          <b-col cols="12" xl="12" class="text-left px-4 px-xl-3 py-2" :class="{'header-border-mobile' : $route.name == 'ProjectDetail'}" :style="`color:${text_color}`">
            <b-row>
              <b-col xl="4" class="my-auto">
                <b-img class="campaign-logo" :src="campaignsLogo"></b-img>
              </b-col>
              <b-col xl="5" class="my-auto px-5 d-none d-md-none d-xl-block">
                <div class="campaign-description-box my-auto">
                  <b-img style="position:absolute;left:15%;top:-5%;width:25px" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>
                  <p class="mb-0">Porque valoramos los pequeños y grandes espacios, los cuales nos permiten abrir nuevas posibilidades es que abrimos una ventana de beneficios para ti.</p>
                </div>
              </b-col>
              <b-col xl="3" class="header-fortaleza-logo my-auto pt-5 pt-md-5 pt-xl-0" v-if="logo && !life">
                <b-img class="ml-auto w-100" :src="require('@/assets/images/header/logo.svg')"></b-img>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
	</div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    fortalezaLogo: {type: Boolean, default: false},
    logo: {type: Boolean},
    text_color: {type: String},
    color: {type: String},
  },
  data() {
    return {
      slide: null,
      icons:{
        map: require('@/assets/images/invest/project-list/map.svg'),
      },
      campaignsLogo: require('@/assets/images/campaigns/ventana.svg'),
    }
  },
  computed: {
    windowSize(){
      return window.innerWidth;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.campaign-logo{
  width: 100%
}
.campaign-description-box{
  border:1px solid #6BBF59;
  padding: 35px;
}
.header-title{
  font-weight:500;
  line-height:36px;
}
.header-title-italic{
  font-weight:700;
  line-height:56px;
  font-style:italic;
}
.header-subtitle{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  /* or 144% */

  letter-spacing: 0.3px;
}
.project-header-img{
  height:65vh;
  background-color:none!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
}
.project-header-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* Light / Black */

  color: #373F41;
}
.project-commune{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  text-transform: uppercase;

  color: #54565A;
}
.project-header-italic-title{
  font-family: Montserrat;
  font-size: 28px;
  line-height: 32px;
  /* or 114% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight:700;
  font-style: italic;;
}
.project-header-subtitle{
  font-family: Neutra Text TF;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  display: flex;
  align-items: center;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.header-fortaleza-logo{
  text-align: right;
}
.green-background{
  background-color:#B6C1B9;
  position:relative;
}
.project-main-image{
  width:100%;
  height:300px
}
.project-images-div{
  position:relative;
}
.project-images-green-border{
  position:absolute;
  background-color: #62AE59;
  width:5px;
  height:100%;
  right:0%
}
.project-images{
  width:192px;
  height:104px
}
.project-images-absolute{
  position:absolute;
  right:-50px
}
.project-name-image{
  position:absolute;
  left:-50px;
  width:100px;
}
// Mobile
@media (max-width:992px){
  .header-title{
    font-size:25px;
    line-height:36px
  }
  .header-title-italic{
    font-size:25px;
    line-height:36px
  }
  .header-subtitle{
    font-size:15px;
    line-height:20px
  }
  .project-header-img{
    height:95vh;
  }
  .header-fortaleza-logo{
    text-align: center;
  }
  .header-border-mobile{
    border:1px solid black;
  }
  .project-header-img{
    height:100%;
    padding:25px 10px!important;
  }
}
// Desktop
@media (min-width:992px){
  .main-row{
    position:relative
  }
  .project-header-img{
    height:100%!important
  }
}
</style>
