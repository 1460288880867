<template>
  <div class="grey-background py-5">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" xl="6">
          <h4 class="benefits-title text-left">Principales beneficios de invertir con nosotros</h4>
          <b-row class="text-left mt-4">
            <b-col cols="12" md="6" xl="6" v-for="(benefit, key) in benefits" :key="'benefit' + key" class="mt-3">
              <b-img :src="benefit.icon"></b-img>
              <p class="benefit-title mt-3 bolder">{{benefit.title}}</p>
              <p class="benefit-subtitle mt-3">{{benefit.description}}</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" xl="6">
          <b-carousel
            id="benefit-carousel"
            :interval="4000"
            controls
            indicators
          >
            <div class="carousel-item" v-for="(image, key) in slider" :key="'image' + key" :style="{ backgroundImage: 'url(' + image.url + ')' }">
            </div>
          </b-carousel>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Benefits',
  props: {
    benefits: {
      type: Array
    },
    slider: {
      type: Array
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.benefits-title{
  font-family: Gill Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 40px;
  letter-spacing: 0.1px;
}
.benefit-title{
  color:$grey
}
.benefit-subtitle{
  color:$grey
}
.carousel-item{
  padding:250px 0px;
  background-color:none!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
}
// Mobile
@media (max-width:992px){
  .carousel-item{
    padding:75px 35px;
    height:40vh
  }
}
</style>
