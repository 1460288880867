<template>
  <div ref="masterplan" class="light-grey-background py-5">
    <b-container>
      <h3 class="masterplan-title text-left mt-3">Masterplan</h3>
      <b-row class="mt-5">
        <b-col xl="6" class="my-auto">
          <p class="masterplan-description">{{description}}</p>
        </b-col>
        <b-col xl="6" class="my-auto pt-4 pt-md-0 pt-xl-0">
          <b-img @click="showSingle(image)" v-if="image && !iframe" class="w-100 cursor-pointer" :src="image"></b-img>
          <iframe v-if="iframe" class="w-100" style="height:500px;display: none;" onload="this.style.display='block';" scrolling="no" :src="iframe"></iframe>
        </b-col>
      </b-row>
    </b-container>
    <!-- all props & events -->
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  name: 'Masterplan',
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      index: 0,   // default: 0
      visible: false,
      imgs: '',  // Img Url , string or Array of string: value
    }
  },
  props: {
    description: {
      type: String
    },
    image: {
      type: String
    },
    iframe: {
      type: String
    }
  },
  methods: {
    showSingle(img) {
      this.imgs = img;
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss">
.masterplan-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;
}
.masterplan-description{
  font-family: 'Neutra Text TF';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  /* or 138% */
  ;



  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
</style>
