<template>
  <div class="pb-5 green-background">
    <ViewTitle class="py-5" :title="title" :subtitle="subtitle" />
    <b-container>
      <div class="accordion mt-2" role="tablist" v-for="(project, key) in projects" :key="'accordion' + key">
        <b-card no-body class="mb-4">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + key" variant="info" class="text-left p-2 project-name"><b-img class="accordion-img" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>{{project.name}}</b-button>
          </b-card-header>
          <b-collapse :id="`accordion-${key}`" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text class="p-4">
                <b-row>
                  <b-col cols="12" md="6" xl="6" class="my-auto">
                    <div class="sales-room-background-img mb-5 mb-md-0 mb-xl-0" :style="{ backgroundImage: 'url(' + project.project_images[0].image_url + ')' }"></div>
                  </b-col>
                  <b-col cols="12" md="6" xl="6" class="my-auto">
                    <h3 class="accordion-title mb-5">Sala de ventas {{project.name}}</h3>
                    <b-row v-for="(item, key) in project.project_detail.sales_room.sales_room_infos" :key="'item' + key">
                      <b-col cols="1">
                        <b-img :src="item.icon.image_url"></b-img>
                      </b-col>
                      <b-col cols="10">
                        <a :href="item.link"><p class="sales-rooms-text" :class="{'bolder' : key == 0}">{{item.text}}</p></a>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <router-link :to="{ name: 'ProjectDetail', params: { project_id: project.link }}"><p class="know-more mt-4">Conoce más<b-img class="know-more-arrow" :src="require('@/assets/images/customer-service/sales-rooms/arrow.svg')"></b-img></p></router-link>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue';
export default {
  name: 'SalesRooms',
  components: {
    ViewTitle,
  },
  props: {
    title: String,
    subtitle: String,
    projects: {
      type: Array,
    },
  },
}
</script>

<style scoped lang="scss">
.accordion{
  text-align: left;
}
.green-background{
  background-color: #D9DBD7CC;
}
.accordion-img{
  width:25px;
  height:25px;
  margin-right:10px
}
.btn-info{
  background-color: inherit!important;
  border: 1px solid #434041;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.1px;
  border-radius:0px;
  color: #444444;
}
.card-header{
  background-color: inherit!important;
  border-bottom:0px
}
.card{
  border:0px;
  background-color: inherit;
}
.show, .collapse, .showing, .card-body{
  padding: 0.25rem !important;
}
.project-name{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  color: #444444;
}
.card-body{
  border: 1px solid #434041;
  margin-top: 15px;
  border-radius:0px;
}
.sales-room-background-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:450px;
  width:100%
}
@media (max-width:992px){
  .sales-room-background-img{
    height:250px;
  }
}
.accordion-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 167% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* Light / Black */

  color: #373F41;
}
.sales-room-text{
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 34px;
  /* or 212% */


  color: #575756;
}
.know-more{
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.2px;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.know-more-arrow{
  margin-left:5px;
  transition:0.3s ease
}
.know-more:hover .know-more-arrow{
  margin-left:10px
}
</style>
