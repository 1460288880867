<template>
  <div class="dark-grey-background py-5" v-if="title && title_italic && subtitle && items.length > 0">
    <b-container>
      <b-row>
        <b-col cols="12" md="6" xl="6" class="my-auto">
          <h2 class="life-quality-title" v-if="title">{{title}}</h2>
          <h2 class="life-quality-title-italic" v-if="title_italic">{{title_italic}}</h2>
          <p class="life-quality-text mt-5" v-if="subtitle">{{subtitle}}</p>
          <b-row class="mt-2" v-if="items">
            <b-col cols="6" md="4" xl="4" v-for="(attribute, key) in items" :key="'attribute' + key" class="text-center mt-4">
              <b-img :src="attribute.icon.image_url"></b-img>
              <p class="mt-4">{{attribute.title}}</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6" xl="6" class="my-auto pt-5 pt-xl-0">
          <h2 class="life-quality-title">Construcción segura</h2>
          <h2 class="life-quality-title-italic">Es calidad de vida.</h2>
          <p class="life-quality-text mt-5">{{description_construction}}</p>
          <b-img class="w-100" :src="construction_img"></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'BigMoments',
  props: {
    title: {
      type: String
    },
    title_italic: {
      type: String
    },
    subtitle: {
      type: String
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      description_big_moments: 'El proyecto Parque Los Almendros cuenta con diferentes espacios que buscan privilegiar la vida con familia y amigos.',
      description_construction: 'Sentirte seguro en el lugar que vives es fundamental. Por esa razón, hemos implementado un sistema de aislación sísmica que funciona a través de anclajes de amortiguación instalada en las bases del edificio.',
      construction_img: require('@/assets/images/projects/big-moments/construccion-segura.png'),
      attributes: [
        {
          title: 'Ventanas con termopanel',
          image_url: require('@/assets/images/projects/big-moments/placeholder.svg'),
        },
        {
          title: 'Ventanas con termopanel',
          image_url: require('@/assets/images/projects/big-moments/placeholder.svg'),
        },
        {
          title: 'Ventanas con termopanel',
          image_url: require('@/assets/images/projects/big-moments/placeholder.svg'),
        },
        {
          title: 'Ventanas con termopanel',
          image_url: require('@/assets/images/projects/big-moments/placeholder.svg'),
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .dark-grey-background{
    background-color: #D9DBD7;
  }
  .life-quality-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 40px;
    /* or 129% */

    letter-spacing: 0.1px;
    text-transform: uppercase;

    /* Light / Black */

    color: #373F41;
  }
  .life-quality-title-italic{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 40px;
    /* or 129% */

    letter-spacing: 0.1px;
    text-transform: uppercase;

    /* Light / Black */

    color: #373F41;
    font-style: italic;
    font-weight: 700;
  }
  .life-quality-text{
    font-family: 'Neutra Text TF';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */


    /* I Fortaleza - Pantone Cool Gray 11C */

    color: #575756;
  }
  .life-quality-attributes li{
    margin-top: 10px;
    list-style-type: circle;
  }
</style>
