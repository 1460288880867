<template>
  <div class="pb-5">
    <b-container v-if="news">
      <b-row class="my-5 text-left" v-if="news.length > 0 && query == null">
        <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3 d-flex align-items-stretch" v-for="(article, key) in news" :key="'news' + key">
          <div class="card">
            <img :src="article.image_url" class="card-img-top news-main-image" alt="Card Image">
            <b-img style="position:absolute;left:-1px;top:0;width:35px" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>
            <div class="card-body d-flex flex-column">
              <b-row class="mt-3">
                <b-col cols="12">
                  <p v-if="article.categories.length > 0" class="mb-0 card-title"><span v-for="(category, key) in article.categories" :key="'category' + key">{{category.name}}<span v-if="key != Object.keys(article.categories).length - 1">, </span></span></p>
                  <p v-else class="mb-0 card-title">General</p>
                </b-col>
                <b-col cols="8" md="12" xl="12" class="news-title-col" :style="{minHeight: titleHeight + 'px'}" ref="newsTitleCol">
                  <h4 class="news-title" v-html="article.title"></h4>
                </b-col>
                <b-col cols="12">
                  <p class="news-date mt-3"><span class="bolder">{{article.created_at | moment("dddd MMMM Do, YYYY")}}</span></p>
                </b-col>
                <b-col cols="12" :style="{minHeight: subtitleHeight + 'px'}" ref="newsSubtitleCol">
                  <p class="news-attribute mt-3" :inner-html.prop="removeTags(article.text_body_1) | truncate(180, '...')"></p>
                </b-col>
              </b-row>
              <router-link :to="{ name: 'Article', params: { article_id: article.slug }}" class="btn btn-grey-border-mt-auto mt-auto align-self-start">Conoce más</router-link>
            </div>
          </div>
        </div>
      </b-row>
      <template v-else-if="query != null">
        <b-row class="justify-content-center" ref="cards">
          <b-col xl="8">
            <p class="pt-5">Resultado de búsqueda: {{query}}</p>
          </b-col>
        </b-row>
        <b-row v-for="(article, key) in news" :key="'news' + key" class="justify-content-center mt-5">
          <b-col cols="12" md="12" xl="8">
            <b-row>
              <b-col cols="12" md="5" xl="5">
                <div class="news-filter-main-image" :style="{ backgroundImage: 'url(' + article.image_url + ')' }">
                  <b-img style="position:absolute;left:-1px;top:0;width:35px" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>
                </div>
              </b-col>
              <b-col cols="12" md="7" xl="7" class="my-auto">
                <b-row class="mt-3">
                  <b-col cols="12">
                    <p> <span v-for="(category, key) in article.categories" :key="'category' + key">{{category.name}}<span v-if="key != Object.keys(article.categories).length - 1">, </span></span></p>
                  </b-col>
                  <b-col cols="8" md="6" xl="6">
                    <h4 class="news-title">{{article.title}}</h4>
                  </b-col>
                  <b-col cols="12">
                    <p class="news-date mt-3"><span class="bolder">{{article.created_at | moment("dddd MMMM Do, YYYY")}}</span></p>
                  </b-col>
                  <b-col cols="12">
                    <p class="news-attribute" :inner-html.prop="article.text_body_1 | truncate(125, '...')"></p>
                  </b-col>
                  <b-col cols="12" class="w-100 mt-3">
                    <router-link :to="{ name: 'Article', params: { article_id: article.slug }}"><b-button variant="grey-border" class="py-3 px-4 d-flex">Conoce más</b-button></router-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
      <b-row v-else>
        <b-col cols="12">
          <p class="not-found bolder text-center mb-0 py-5">No se encontró ningún artículo</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name:'NewsCards',
  data() {
    return {
      titleHeight: 0,
      subtitleHeight: 0
    }
  },
  props: {
    query: {
      type: String,
    },
  },
  computed: {
    news() {
      let filtered = [];
      if(this.query != null){
        filtered = this.$store.getters.getNews.filter(article => article.title.toLowerCase().includes(this.query.toLowerCase()))
        console.log(filtered);
      }else{
        filtered = this.$store.getters.getNews;
      }
      return filtered;
    },
  },
  methods: {
    removeTags(html){
      // Crea un nuevo elemento div
      var temporalDivElement = document.createElement("div");
      // Establecer el contenido HTML con el dado
      temporalDivElement.innerHTML = html;
      // Recuperar la propiedad de texto del elemento (compatibilidad con varios navegadores)
      return temporalDivElement.textContent || temporalDivElement.innerText || "";
    },
    getTitleHeight(){
      const heights = [];
      this.$refs.newsTitleCol.forEach((col) => {
        heights.push(col.clientHeight);
      })
      this.titleHeight = Math.max(...heights);
    },
    getSubtitleHeight(){
      const heights = [];
      this.$refs.newsSubtitleCol.forEach((col) => {
        heights.push(col.clientHeight);
      })
      this.subtitleHeight = Math.max(...heights);
      console.log(this.subtitleHeight);
    }
  },
  mounted(){
    this.getTitleHeight();
    this.getSubtitleHeight();
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.news-main-image{
    height:310px;
    object-fit: cover;
    position:relative
}
.news-filter-main-image{
  height:310px;
  background-color:none!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  position:relative
}
.not-found{
  font-size:40px;
  line-height:100%
}
.news-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.news-attribute{
  color:$grey;
  font-family: 'Montserrat'!important;
}
.last-units{
  position:absolute;
  right:0;
  padding:10px;
  background-color:$grey
}
.last-units-text{
  font-family: 'Montserrat'!important;
  font-weight: 600;
}
.filter{
  font-family: 'Montserrat'!important;
  transition:0.1s ease-in-out;
}
.active-filter{
  font-weight:700;
  transition:0.1s ease-in-out;
}
.news-date{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #4C823A;
}

@media(max-width:992px){
  .filter{
    text-align:center
  }
}
</style>
