<template>
  <div :class="['py-5', greyBackground ? 'light-grey-background' : null]" ref="mainFeatures">
    <b-container>
      <b-row>
        <b-col :xl="actualType === 'houses' || actualType === 'grounds' ? '4' : 'auto'" class="description-background p-0" :style="[changeInfo && actualType === 'houses' ? { backgroundImage: 'url(' + houseImage + ')' } : null, changeInfo && actualType === 'grounds' ? { backgroundImage: 'url(' + siteImage + ')' } : null]">
          <b-container fluid class="h-100 px-4">
            <b-row v-if="description && actualType === 'apartments'" class="h-100">
              <b-col class="my-auto p-5" style="background: rgba(99, 173, 88, 0.15);">
                <h3 class="features-title mb-4">Características principales</h3>
                <template v-for="(feature, key) in mainFeatures">
                  <div v-if="actualType === 'apartments'" :key="'mainFeature' + key" class="feature-item pb-3 pb-md-0 pb-xl-0">
                    <p class="feature-description-text mb-0 mb-md-0 md-lg-2 mb-xl-2">{{feature.description}} <b-img v-for="(icon, key) in feature.detail_main_features_icons" :key="'icon' + key" :src="icon.icon.image_url" class="px-1"></b-img></p>
                  </div>
                </template>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
        <b-col xl class="my-auto px-5 pt-5 pt-xl-0">
          <div class="py-4">
            <p v-if="actualType === 'apartments'" class="description-text mb-0" v-html="description"></p>
            <div v-if="actualType === 'houses' && detailHouseMainFeatures">
              <p v-for="(feature, key) in detailHouseMainFeatures" :key="`house-main-feature-${key}`">{{ feature.description }}<b-img v-for="(icon, key) in feature.detail_house_main_feature_icons" :key="'icon' + key" :src="icon.icon.image_url" class="px-1"></b-img></p>
              <p v-html="detailHouseDescription"></p>
            </div>
            <div v-if="actualType === 'grounds' && detailSiteMainFeatures">
              <p v-for="(feature, key) in detailSiteMainFeatures" :key="`site-main-feature-${key}`">{{ feature.description }}<b-img v-for="(icon, key) in feature.detail_site_main_feature_icons" :key="'icon' + key" :src="icon.icon.image_url" class="px-1"></b-img></p>
              <p v-html="detailSiteDescription"></p>
            </div>
            <b-row class="mt-4">
              <b-col xl="6" v-if="terminations.length > 0 && actualType === 'apartments'">
                <div>
                  <h3 class="features-title mb-4">Detalles y terminaciones</h3>
                </div>
                <p v-for="(termination, index) in terminations" :key="'termination-' + index" class="mb-1"><span class="equipment-item ml-4"></span>{{termination.text}}</p>
              </b-col>
              <b-col xl="6" v-if="equipments.length > 0 && actualType === 'apartments'" :class="{'equipment-border-left' : terminations.length > 0, 'mt-4 mt-md-4 mt-lg-0 mt-xl-0' : true}">
                <div :class="{'equipments-div' : terminations.length > 0 }">
                  <h3 class="features-title mb-4">Equipamiento</h3>
                  <p v-for="(equipment, index) in equipments" :key="'equipment-' + index" class="mb-1"><span class="equipment-item ml-4"></span>{{ equipment.text }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="actualType === 'apartments'">
      <b-row>
        <b-col cols="6" md="6" xl="2" v-for="(feature, key) in features" :key="'feature' + key" class="px-0">
          <div class="w-100 text-center pt-5">
            <div :class="{'border-right' : key != Object.keys(features).length - 1}">
              <b-img class="mx-auto feature-icon" v-for="(icon, key) in feature.detail_features_icons" :src="icon.icon.image_url" :key="'featureIcon' + key"></b-img>
              <p class="mt-4" v-html="feature.title"></p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="actualType === 'houses'">
      <b-row>
        <b-col cols="6" md="6" xl="2" v-for="(feature, key) in features" :key="'feature' + key" class="px-0">
          <div class="w-100 text-center pt-5" v-if="feature.title != 'Estacionamiento <br>Bicicletas'">
            <div :class="{'border-right' : key != Object.keys(features).length - 1}">
              <b-img class="mx-auto feature-icon" v-for="(icon, key) in feature.detail_features_icons" :src="icon.icon.image_url" :key="'featureIcon' + key"></b-img>
              <p class="mt-4" v-html="feature.title"></p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="hr" class="pt-5">
      <hr class="mt-5 mb-0">
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MainFeatures',
  props: {
    mainFeatures: {
      type: Array,
    },
    terminations: {
      type: Array,
    },
    equipments: {
      type: Array,
    },
    features: {
      type: Array
    },
    description: {
      type: String
    },
    maxMt2: {
      type: String
    },
    greyBackground: {
      type: Boolean,
      default: false
    },
    hr: {
      type: Boolean,
      default: false
    },
    changeInfo: {
      type: Boolean,
      default: false
    },
    actualType: {
      type: String,
      default: 'apartments'
    },
    detailHouseDescription: {
      type: String,
      default: null
    },
    detailHouseMainFeatures: {
      type: Array,
      default: null
    },
    houseImage:{
      type: String,
      default: null
    },
    detailSiteDescription: {
      type: String,
      default: null
    },
    detailSiteMainFeatures: {
      type: Array,
      default: null
    },
    siteImage:{
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.description-background{
  background-size: cover;
  min-height:300px
}
.description-text{
  font-family: Neutra Text TF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */


  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}

.equipment-item::before {
  content: "■"!important;  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: rgba(99, 173, 88, 1); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.border-right{
  border-right:1px solid black
}

.features-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* Light / Black */

  color: #373F41;
}
.feature-item{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.2px;
  text-transform: uppercase;

  /* I Fortaleza - Pantone Cool Gray 11C */

  color: #575756;
}
.feature-icon{
  height:4rem
}
.feature-description-text{
  font-size: .9375rem;
}
.equipment-border-left{
  border-left: 1px solid rgb(218, 221, 225);
}

.equipments-div{
  width:50%;
  margin: 0 auto;
}

/deep/ ul li::before {
  content: "■"!important;  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: rgba(99, 173, 88, 1); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media (max-width:992px){
  .feature-description-text{
    font-size: .8125rem;
  }
  .equipments-div{
    width:100%;
  }
  .equipment-border-left{
    border-left: 0px;
  }
}
</style>
