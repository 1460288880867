<template>
  <div>
    <b-container>
      <b-row class="justify-content-center">
        <b-col xl="12" cols="12">
            <h2 class="title">{{title}}</h2>
            <p class="subtitle">{{subtitle}}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name:'ViewTitle',
  props: {
    title: { type: String },
    subtitle: { type: String }
  }
}
</script>

<style>

</style>
