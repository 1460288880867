<template>
  <div class="light-grey-background">
    <Navbar />
    <Header type="Header" color="#434041" :showImage="true" :showMobileImage="true" :image="require('@/assets/images/referrals/header.png')" :imageMobile="require('@/assets/images/referrals/header-mobile.png')"/>
    <ReferralForm />
    <CampaignBases :bases="bases"/>
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Menu"
import Header from '@/components/Header.vue'
import ReferralForm from "@/components/Referral/ReferralForm"
import CampaignBases from "@/components/Campaigns/CampaignBases"
import Footer from "@/components/Footer"
export default {
  components: {
    Navbar,
    Header,
    ReferralForm,
    CampaignBases,
    Footer
  },
  data() {
    return {
      bases: [
        {text: 'Premio se hace efectivo una vez que el referido firme la promesa de compra venta.'},
        {text: 'Bases protocolizadas bajo el Nº 3355, el 28 de enero de 2022 en la Notaría de Santiago de Don Juan Ricardo San Martín Urrejola.'},
      ]
    }
  },
  computed: {
    windowSize(){
      return window.innerWidth;
    },
  },
}
</script>
<style lang="">

</style>
