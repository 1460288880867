<template>
  <b-container v-if="tour360" class="py-5">
    <div class="py-4" ref="tour360">
      <h3 class="pilots-title text-left my-3">Vista 360°</h3>
      <iframe
        :src="tour360"
        width="100%"
        height="600px"
        style="border: none; border-radius: 10px"
      />
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    tour360: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.pilots-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;
}
</style>
