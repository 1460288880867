<template>
  <div>
    <Navbar v-if="header"/>
    <Header v-if="header" :us="true" :isotype="header.isotype_url" :showImage="header.show_image" :showMobileImage="header.show_mobile_image" :showIsotype="header.show_isotype" :title="header.title" :italicTitle="header.italic_title" :text_color="header.text_color" :image="header.image_url" :imageMobile="header.mobile_image_url" color="#434041"/>
    <About v-if="header" :title="about.title" :description="about.description" :image="aboutImage"/>
    <Propose v-if="header" :title="trajectory.title" :description="trajectory.description" />
    <Trajectory v-if="header" :subtitle="trajectory.subtitle" :data="trajectory.data" />
    <ProjectsSlider v-if="header" :projects="projects" />
    <Footer v-if="header" />
    <ContactModal v-if="header && projects" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu"
import Footer from "@/components/Footer"
import Header from '@/components/Header.vue'
import About from '@/components/About/About.vue'
import Propose from '@/components/About/Propose.vue'
import Trajectory from '@/components/About/Trajectory.vue'
import ProjectsSlider from '@/components/ProjectsSlider.vue'
import ContactModal from '@/components/ContactModal.vue'
export default {

  components: {
    Header,
    About,
    Propose,
    Trajectory,
    ProjectsSlider,
    Navbar,
    Footer,
    ContactModal
  },
  data() {
    return {
      header: null,
      aboutImage: require('@/assets/images/about/promise.png'),
      about:{
        title: 'En Inmobiliaria Fortaleza llevamos más de 35 años construyendo no solo departamentos, oficinas, bodegas, locales comerciales y estacionamientos, sino confianza.',
        description: 'Nacimos el año 1985 cuando un grupo de profesionales constituido por ingenieros y constructores civiles de la Universidad Católica de Chile, deciden formar la Empresa Constructora Los Corrales Ltda. y posteriormente la  Empresa Constructora Analco S.A, pasando luego a llamarse Fortaleza. A lo largo de estos años y gracias a los diferentes proyectos que hemos llevado a cabo, nos hemos convertido en sinónimo de calidad de vida, con especial énfasis en el desarrollo de proyectos inmobiliarios donde la satisfacción del cliente, calidad de nuestros productos y el diseño de espacios pensado en el bienestar de las personas que han depositado sus sueños y expectativas en nosotros, es nuestro principal objetivo.'
      }
    }
  },
  mounted () {
    this.$store.commit('setHeader', 'About');
    window.scrollTo(0,0);
  },
  computed: {
    trajectory() {
      return this.$store.getters.getTrajectory;
    },
    projects(){
      return this.$store.getters.getProjects;
    }
  },
  watch: {
    '$store.state.header': {
      handler: function()  {
        this.header = this.$store.getters.getHeader;
        this.loading = false;
      }
    }
  },
}
</script>

<style>

</style>
