<template>
  <div class="py-5 lighter-grey-background">
    <ViewTitle class="text-left pt-5" v-if="projects" title="Nuevos Proyectos"/>
    <b-container class="projects-carousel">
      <VueSlickCarousel v-if="projects" ref="projectsCarousel" v-bind="windowSize >= 1024 ? settings : settingsMobile">
        <b-row class="mt-5 text-left row-carousel" v-for="(project, key) in projects.filter(project => project.actual_state != 'Realizado')" :key="'project' + key" :class="key > 2 ? 'mt-5' : 'mt-3'">
          <b-col cols="12" md="12" xl="12">
            <div class="project-main-image" :style="{ backgroundImage: 'url(' + project.project_images[0].image_url + ')' }">
              <div v-if="project.lastUnits" class="last-units">
                <p class="last-units-text text-white mb-0">Últimas unidades</p>
              </div>
              <b-img class="green-square" :src="require('@/assets/images/projects/features/green-square.svg')"></b-img>
            </div>
            <b-row class="mt-3">
              <b-col cols="12">
                <b-row>
                  <b-col cols="1" md="1">
                    <b-img :src="icons.map"></b-img>
                  </b-col>
                  <b-col cols="11" md="10">
                    <p class="project-commune">{{project.commune}}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <h4 class="project-title pb-4">{{project.type}} {{project.name}}</h4>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="1" md="1">
                    <b-img :src="icons.mts"></b-img>
                  </b-col>
                  <b-col cols="11" md="10">
                    <p class="project-attribute">Desde {{project.min_mt2}}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="1" md="1">
                    <b-img :src="icons.actual_state"></b-img>
                  </b-col>
                  <b-col cols="11" md="10">
                    <p class="project-attribute">{{project.actual_state}}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6" md="6" xl="6">
                    <p class="project-attribute"><b-img class="mr-2 d-inline-block" :src="icons.bedrooms"></b-img>
                    <template v-for="(bedroom, key) in project.project_bedrooms">
                      <span :key="'bedroom' + key">{{bedroom.number}}
                        <template v-if="project.project_bedrooms.length == 2">
                          <span v-if="key == Object.keys(project.project_bedrooms).length - 2"> y </span>
                        </template>
                        <template v-if="project.project_bedrooms.length > 2">
                          <span v-if="key != Object.keys(project.project_bedrooms).length - 1 && key != Object.keys(project.project_bedrooms).length - 2">,</span>
                          <span v-if="key == Object.keys(project.project_bedrooms).length - 2"> y </span>
                        </template>
                      </span>
                    </template>
                    <span class="text-secondary">{{project.project_bedrooms.length == 1 ? 'Dorm' : 'Dorms'}}</span>
                  </p>
                  </b-col>
                  <b-col cols="6">
                    <p class="project-attribute"><b-img class="mr-2 d-inline-block" :src="icons.bathrooms"></b-img>
                      <template v-for="(bathroom, key) in project.project_bathrooms">
                        <span :key="'bedroom' + key">{{bathroom.number}}
                          <template v-if="project.project_bathrooms.length == 2">
                            <span v-if="key == Object.keys(project.project_bathrooms).length - 2"> y </span>
                          </template>
                          <template v-if="project.project_bathrooms.length > 2">
                            <span v-if="key != Object.keys(project.project_bathrooms).length - 1 && key != Object.keys(project.project_bathrooms).length - 2">,</span>
                            <span v-if="key == Object.keys(project.project_bathrooms).length - 2"> y </span>
                          </template>
                        </span>
                      </template>
                      <span class="text-secondary">{{project.project_bathrooms.length == 0 ? 'Baño' : 'Baños'}}</span>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="quoteButton" cols="12" md="6" xl="6" class="w-100 mt-3">
                <router-link :to="{ name: 'ProjectDetail', params: { project_id: project.link }}"><b-button variant="grey-border" class="w-100 py-3">Cotiza aquí</b-button></router-link>
              </b-col>
              <b-col cols="12" class="w-100 mt-3" :class="quoteButton ? 'col-xl-6 col-md-6' : 'col-xl-12'">
                <router-link :to="{ name: 'ProjectDetail', params: { project_id: project.link }}"><b-button variant="grey" class="w-100 py-3">Conocer proyecto</b-button></router-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </VueSlickCarousel>
      <div class="p-2 d-none d-xl-block" v-if="projects && projects.length > 2">
        <button @click="$refs.projectsCarousel.prev()" class="arrow left"> <b-img :src="require('@/assets/images/about/arrow.svg')"></b-img> </button>
        <button @click="$refs.projectsCarousel.next()" class="arrow right"> <b-img class="arrow-right" :src="require('@/assets/images/about/arrow.svg')"></b-img> </button>
      </div>
    </b-container>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import ViewTitle from '@/components/ViewTitle.vue';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
export default {
  name: 'ProjectSlider',
  components: {
    VueSlickCarousel,
    ViewTitle
  },
  props: {
    projects: {
      type: Array,
    },
    quoteButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons:{
        map: require('@/assets/images/invest/project-list/map.svg'),
        mts: require('@/assets/images/invest/project-list/mts-green.svg'),
        actual_state: require('@/assets/images/invest/project-list/deliver-green.svg'),
        bedrooms: require('@/assets/images/invest/project-list/bedrooms-green.svg'),
        bathrooms: require('@/assets/images/invest/project-list/bathrooms-green.svg')
      },
      settings: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": true,
        "speed": 500,
        "rows": 1,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "touchThreshold": 5
      },
      settingsMobile: {
        "dots": false,
        "arrows": false,
        "focusOnSelect": true,
        "infinite": true,
        "speed": 500,
        "rows": 1,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "touchThreshold": 5
      }
    }
  },
  computed: {
    windowSize(){
      return window.innerWidth;
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/colors";
.green-square{
  position:absolute;left:-1px;top:0;width:35px!important
}
.lighter-grey-background{
  background: #F8F8F8
}
.project-main-image{
  height:310px;
  background-color:none!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  position:relative
}
.not-found{
  font-size:40px;
  padding:50px 0px 0px 0px;
  line-height:100%
}
.project-commune{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  /* I Fortaleza textos */

  color: #434041;
}
.project-attribute{
  color:$grey
}
.last-units{
  position:absolute;
  right:0;
  padding:10px;
  background-color:$grey
}
.last-units-text{
  font-family: 'Gill Sans'!important;
  font-weight: 600;
}
.filter{
  font-family: 'Gill Sans'!important;
  transition:0.1s ease-in-out;
}
.active-filter{
  font-weight:700;
  transition:0.1s ease-in-out;
}
@media(max-width:992px){
  .filter{
    text-align:center
  }
  .slick-slide{
    display: block!important
  }
  .row-carousel{
    width:auto!important
  }
}
.slick-slide{
  display: flex!important
}
.btn-oportunity-dark{
	border-color: #FFFFFF !important;
	color: #FFFFFF !important;
}

.btn-oportunity-light{
	border-color: black !important;
	color: black !important;
}


.arrow.left{
	position: absolute;
	top: 50%;
	left: -3%;
  border: 0px;
  background:inherit
}
.arrow.right{
	position: absolute;
	top: 50%;
	right: 0;
  border:0px;
  background:inherit
}
.projects-carousel{
  position:relative
}
.arrow-right{
  transform: rotate(180deg);
}
</style>
